import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {

  _url: string;
  title: string;

  get url()
  {
    return this._url + "?autoplay=1&rel=0";
  }

  set url(url: string)
  {
    this._url = url;
  }

  constructor() { }

  ngOnInit() {
  }

}
