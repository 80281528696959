import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  capitalizeFirstLetter,
  countryList,
  states,
  truncateString,
} from "../utilities/utilities";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { OrderGetInfoSuccessAction } from "../actions/order";
import { ApiService } from "../services/api/api-service";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
import * as zipcodes from "zipcodes";
@Component({
  selector: "app-client-post-order",
  templateUrl: "./client-post-order.component.html",
  styleUrls: ["./client-post-order.component.scss"],
  animations: [
    trigger("toggle", [
      state("true", style({ opacity: 1, color: "red" })),
      state("void", style({ opacity: 0, color: "blue" })),
      transition(":enter", animate("700ms ease-in-out")),
      transition(":leave", animate("700ms ease-in-out")),
    ]),
  ],
})
export class ClientPostOrderComponent implements OnInit {
  timer(timer: any) {
    throw new Error("Method not implemented.");
  }
  activeForm = "dog";
  formStates = ["dog", "clinic", "submit"];
  clientOrderForm: FormGroup;
  countryList = countryList;
  states = states;
  whereDidYouHeadOptions = [
    "Facebook",
    "Friend/Family",
    "Google Ad",
    "Hero Website",
    "Online Search",
    "Pet Owner",
    "Professional Conference",
    "Social Media",
    "Vet Colleague",
    "Other",
  ];
  orderId;
  checkoutId;
  orderName;
  product;
  checked;
  orderInfo;
  isSubmitting = false;
  showVetSearch = true;
  filteredVets = [];
  vetInput = null;
  selectedVet = null;
  showVetTable = false;
  timeout: any;
  vetZipcode: any;
  dogDetails: any;
  disableDogForm = false;
  disableVetForm = false;
  braces  = ["Knee Brace", "Ankle Brace", "Wrist Brace", "Casting Kit","Refurbish Brace"]

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.createOrderForm();
    this.performCheck();
  }

  /**
   * get processed order details
   * @param {Boolean} submit [complete order step form submission ]
   */
  checkIfOrderProcessed = () => {
    this.apiService
      .getOrderInfo(this.orderId, this.checkoutId)
      .map((response) => {
        this.isSubmitting = false;
        response.id = this.orderName;
        response.productName = this.product;
        this.orderInfo = response;

        this.store.dispatch(new OrderGetInfoSuccessAction(response));
        this.setFormValueFromAPI(response);

        // if (response.is_dog_vet_filled && response.status == "complete") {
        if (response.status == "complete" ) {
          this.router.navigate(["/thank-you-order"]).then();
        }
      })
      .subscribe(() => console.log("Done"));
  };

  setFormValueFromAPI(value) {
    //dog form
    if (value.dog != undefined) {
      this.clientOrderForm.controls.dog_name.setValue(value.dog.name);
      this.clientOrderForm.controls.breed.setValue(value.dog.breed);
      this.clientOrderForm.controls.age.setValue(value.dog.age);
      this.clientOrderForm.controls.weight.setValue(value.dog.weight);
      this.dogDetails = value.dog;
    }
    //clinic form
    if (value.vet != undefined) {
      this.selectedVet = value.vet;
      this.filteredVets = [];
      const formattedVet: any = value.vet;
      // formattedVet.doctor_name = value.vet.doctor_name;
      // formattedVet.city = value.vet.city || "";
      // formattedVet.zipcode = value.vet.zipcode || "";
      // formattedVet.country = value.vet.country || "";
      // formattedVet.state = value.vet.state || "";
      // formattedVet.address = value.vet.address || "";
      this.filteredVets.push(formattedVet);
    }

    //submit form
    this.clientOrderForm.controls.order_notes.setValue(value.order_notes);
    this.clientOrderForm.controls.ship_to_vet.setValue(value.ship_to_vet);
    if (value.how_hear_about) {
      this.clientOrderForm.controls.source_of_information.setValue(
        value.how_hear_about
      );
    }
    this.updateFormStep(value);
  }

  /**
   * Update active form if dog id or vet id is available from API
   * @param value Object
   */
  updateFormStep(value){
    if (value.vet != undefined) {
      if (value.vet.id  !== null && this.activeForm != "clinic") {
        this.activeForm = "submit";
        this.disableVetForm = true;
        this.disableDogFormFields(value.dog,false);
      } else if (this.activeForm != "submit") {
        this.disableDogFormFields(value.dog,true);
      }
    } else {
      this.disableDogFormFields(value.dog,true);
    }
  }

  /**
   * Disable dog form if dog id
   * @param dog Object
   * @param activateForm Boolean
   */
  disableDogFormFields(dog,activateForm){
    if (dog != undefined) {
      if (dog.id !== null) {
        this.disableDogForm = true;
        if (activateForm) {
          this.activeForm = "clinic";
        }
      }
    }
  }

  performCheck = () => {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.orderId = params["order"];
      this.checkoutId = params["checkout"];
      this.orderName = params["orderName"];
      this.product = params["product"];
      //check if product is valid
      if(this.braces.indexOf(this.product) == -1){
        this.router.navigate(["/thank-you-order"], { state: { invalidproduct:true } }).then();
      } else {
        this.clientOrderForm.patchValue({ checkout_id: this.checkoutId });
        this.clientOrderForm.patchValue({ order_id: this.orderId });
        if (!this.checked) {
          this.checked = true;
          this.checkIfOrderProcessed();
        }
      }

    });
  };

  isCurrentFormValid = (): boolean => {
    const dynamicFormAttributes = {
      dog: ["dog_name", "breed", "weight", "age"],
      clinic: [
        "clinic_name",
        "zipcode",
        "country",
        "doctor_name",
        "email",
        "address",
        "city",
        "state",
        "phone",
      ],
      submit: [],
    };
    return dynamicFormAttributes[this.activeForm].every(
      (singleField) => this.clientOrderForm.controls[singleField].valid
    );
  };

  /**
   * Set payload request by form step
   * @param {String} currentStep [Step form value]
   * @returns  {Object} formatted payload
   */
  getFormattedPayload = (currentStep) => {
    const clientOrderFormValue = this.clientOrderForm.value;
    const formattedPayload: any = {};
    formattedPayload.order_id = this.orderId;
    formattedPayload.checkout_id = this.checkoutId;
    // formattedPayload.opt = clientOrderFormValue.opt;
    // formattedPayload.order_notes = clientOrderFormValue.order_notes;
    // formattedPayload.order_type = "";
    // formattedPayload.ship_to_vet = clientOrderFormValue.ship_to_vet;
    // formattedPayload.source_of_information =
    //   clientOrderFormValue.source_of_information;
    if (currentStep == "dog") {
      formattedPayload.dog = {
        age: clientOrderFormValue.age,
        breed: clientOrderFormValue.breed,
        weight: clientOrderFormValue.weight,
        name: clientOrderFormValue.dog_name,
      };
      //add dog id in vat payload
      if (this.dogDetails) {
        if (this.dogDetails.name == formattedPayload.dog.name) {
          formattedPayload.dog.id = this.dogDetails.id;
        }
      }
      formattedPayload.order_complete = false;
    } else if (currentStep == "clinic") {
      formattedPayload.vet = {
        address: clientOrderFormValue.address,
        city: clientOrderFormValue.city,
        country: clientOrderFormValue.country,
        doctor_name: clientOrderFormValue.doctor_name,
        email: clientOrderFormValue.email,
        name: clientOrderFormValue.clinic_name,
        phone: clientOrderFormValue.phone,
        state: clientOrderFormValue.state,
        zipcode: clientOrderFormValue.zipcode,
      };
      // add vat id in vat payload
      if (!clientOrderFormValue.clinic_name) {
        if (this.selectedVet) {
          formattedPayload.vet.id = this.selectedVet.id;
        }
      } else {
        if (this.selectedVet) {
          formattedPayload.vet.id = this.selectedVet.id;
        } else {
          formattedPayload.vet.id = "";
        }
      }
      formattedPayload.order_complete = false;
    } else {
      formattedPayload.order = {
        opt: clientOrderFormValue.opt,
        order_notes: clientOrderFormValue.order_notes,
        order_type: "",
        ship_to_vet: clientOrderFormValue.ship_to_vet,
        source_of_information: clientOrderFormValue.source_of_information,
      };
      formattedPayload.order_complete = true;

    }
    return formattedPayload;
  };

  /**
   *  Save order form
   *
   */
  postOrder = (orderPayload): void => {
    this.apiService.submitOrder(orderPayload).subscribe(() => {
      this.checkIfOrderProcessed();
    });
  };

  setVetForm = (): void => {
    this.clientOrderForm.patchValue({
      clinic_name: this.selectedVet.name,
      country: this.selectedVet.country,
      doctor_name: this.selectedVet.doctor_name,
      email: this.selectedVet.email,
      address: this.selectedVet.address,
      city: this.selectedVet.city,
      zipcode: this.selectedVet.zipcode,
      state: this.selectedVet.state,
      phone: this.selectedVet.phone,
    });
    this.updateActiveForm("next");
  };

  updateActiveForm = (state) => {
    // complete step form submission
    let orderPayload: any;
    let currentStep = this.activeForm;
    orderPayload = this.getFormattedPayload(currentStep);
    if (state === "next" && this.activeForm == "submit") {
      this.isSubmitting = true;
      this.postOrder(orderPayload);
      return;
    }
    const currentlyActiveFormIndex = this.formStates.indexOf(this.activeForm);
    if (state === "previous") {
      this.activeForm = this.formStates[currentlyActiveFormIndex - 1];
    }
    if (!this.isCurrentFormValid()) {
      this.clientOrderForm.markAsDirty();
      return false;
    }
    if (state === "next") {
      //set search field value empty after form submit
      if (this.activeForm == "clinic") {
        this.vetInput = null;
        this.vetZipcode = null;
      }
      this.activeForm = this.formStates[currentlyActiveFormIndex + 1];
      this.clientOrderForm.markAsPristine();
      this.postOrder(orderPayload);
    }
  };

  createOrderForm = () => {
    this.clientOrderForm = new FormGroup({
      dog_name: new FormControl(null, [Validators.required]),
      breed: new FormControl(null, [Validators.required]),
      weight: new FormControl(null, [Validators.required]),
      age: new FormControl(null, [Validators.required]),

      clinic_name: new FormControl(null, [Validators.required]),
      zipcode: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      doctor_name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      address: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [
        Validators.required,
        Validators.pattern("[- +()0-9]+"),
      ]),
      checkout_id: new FormControl(null, [Validators.required]),
      order_id: new FormControl(null, [Validators.required]),

      order_notes: new FormControl(),
      source_of_information: new FormControl(),
      ship_to_vet: new FormControl(),
      have_read_and_agreed_to_tos: new FormControl(true),
      opt: new FormControl(true),
    });
  };

  getDynamicError = (currentField): string => {
    if (
      this.clientOrderForm.controls[currentField].valid ||
      this.clientOrderForm.pristine
    ) {
      return "";
    }

    if (this.clientOrderForm.controls[currentField].errors) {
      const formattedFieldName = capitalizeFirstLetter(
        currentField.replace("_", " ")
      );
      if (this.clientOrderForm.controls[currentField].errors.required) {
        return `${formattedFieldName} is required.`;
      }

      if (this.clientOrderForm.controls[currentField].errors.email) {
        return `Please input a valid ${formattedFieldName}.`;
      }

      if (this.clientOrderForm.controls[currentField].errors.pattern) {
        return `Please input a valid ${formattedFieldName}.`;
      }
    }
  };

  toggleClinicSearch = (): boolean =>
    (this.showVetSearch = !this.showVetSearch);

  /**
   * Search vet by name
   * @param String {name} [vetname]
   * @returns Array [vet list]
   */
  searchVetbyName = (name) => {
    if (this.timeout) clearTimeout(this.timeout);
    // search vat
    this.timeout = setTimeout(() => {
      // this.selectedVet = null;
      const formattedUpdatedSearchText = name.toLowerCase().replaceAll(" ", "");
      if (name.length >= 4) {
        this.apiService.searchVets(name).subscribe((vetDataResult) => {
          this.vetZipcode = null;
          if (vetDataResult.length !=0) {
            this.filteredVets = vetDataResult
              .filter((vet) => vet.name && vet.zipcode)
              .map((vet) => {
                const formattedVet: any = vet;
                formattedVet.doctor_name = vet.doctor_name;
                formattedVet.city = vet.city || "";
                formattedVet.zipcode = vet.zipcode || "";
                formattedVet.country = vet.country || "";
                formattedVet.state = vet.state || "";
                formattedVet.address = vet.address || "";
                formattedVet.formattedName = vet.name
                  ? vet.name.replaceAll(" ", "").toLowerCase()
                  : "";
                formattedVet.formattedDoctorName = vet.doctor_name
                  ? vet.doctor_name.replaceAll(" ", "").toLowerCase()
                  : "";
                return formattedVet;
              });
            
          }
        });
      } else {
        this.filteredVets = [];
      }
    }, 100);
  };

  /**
   * Search vet near by zipcode
   * @param String {zipcode} [vet zipcode]
   * @returns Array [vet list]
   */

  searchVetbyZipcode = (zipcode) => {
    //search vet by zipcode only if search vet by name is empty
    if (this.vetInput == null || this.vetInput == "") {
      let location = zipcodes.lookup(zipcode);

      if (this.timeout) clearTimeout(this.timeout);

      // search vat
      this.timeout = setTimeout(() => {
        if (location) {
          this.apiService
            .getNearByVets(location.latitude, location.longitude)
            .subscribe((vetDataResult) => {
              this.vetInput = null;
              if (vetDataResult.length != 0) {
                this.filteredVets = vetDataResult.data
                  .filter((vet) => vet.name && vet.zipcode)
                  .map((vet) => {
                    const formattedVet: any = vet;
                    formattedVet.doctor_name = vet.doctor_name;
                    formattedVet.city = vet.city || "";
                    formattedVet.zipcode = vet.zipcode || "";
                    formattedVet.country = vet.country || "";
                    formattedVet.state = vet.state || "";
                    formattedVet.address = vet.address || "";
                    formattedVet.formattedName = vet.name
                      ? vet.name.replaceAll(" ", "").toLowerCase()
                      : "";
                    formattedVet.formattedDoctorName = vet.doctor_name
                      ? vet.doctor_name.replaceAll(" ", "").toLowerCase()
                      : "";
                    return formattedVet;
                  });
              }
            });
        } else {
          this.filteredVets = [];
        }
      }, 100);
    }
  };

  shouldShowNavigationButtons = (): boolean =>
    !(this.showVetSearch && this.activeForm === "clinic");

  setSelectedVet = (clickedVet) => {
    this.selectedVet = clickedVet;
  };

  truncateString = (toTruncateString) => truncateString(toTruncateString, 20);
}