import { Component, HostListener, Input, OnDestroy } from '@angular/core';
import { SearchResponse } from '../../../../services/api/model/SearchResponse';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { Feed } from '../../../../services/api/model/FeedResponse';
import { QuestionBase, QuestionControlService } from 'app/services/api/model/Question-Control-Service';
import { FormGroup, Validators } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { ActionTypes, OrderSaveLabInfoAction } from 'app/actions/order';
import { FeedsFetchOrderAction } from '../../../../actions/feeds';
import { InteractionService } from '../../../../services/interaction.service';
import { HasSubscriptions } from '../../../../directives/HasSubscriptions';
import { MdDialog } from "@angular/material";

@Component({
  selector: 'app-feed-popup-medical',
  templateUrl: './feed-popup-medical.component.html',
  styleUrls: ['./feed-popup-medical.component.scss'],
  providers: [QuestionControlService]
})
export class FeedPopupMedicalComponent extends HasSubscriptions implements OnDestroy {
  @Input() context: SearchResponse;
  feed: Feed;
  editStateBool: boolean;
  values: QuestionBase<any>[] = [];
  form: FormGroup;
  success: boolean;
  failure: boolean;
  failureSubscription: any;
  successSubscription: any;
  sideValueUpdated = false;
  updatedPopupPage:string;//popup page which was saved automatically

  constructor(
    private qcs: QuestionControlService,
    private store: Store<AppState>,
    private actions: Actions,
    private interactionService: InteractionService,
    private dialog: MdDialog,
  ) {
    super();
    this.failureSubscription = this.actions.ofType(ActionTypes.SaveLabInfoFailure)
      .subscribe((failure) => this.handleSaveFeedFailure());
    this.successSubscription = this.actions.ofType(ActionTypes.SaveLabInfoSuccess)
      .subscribe((result) => this.handleSaveFeedSuccess());

    this.store.select(x => x.mainState.feeds.currentFeed).subscribe((feed: Feed) => {
      this.updateFeed(feed);
    });
    this.interactionService.sharedEditedTab.pipe(this.getTakeUntil()).subscribe((data: any) => {
      if (data.section === 'lab') {
        if (data.currentAction === 'edit') {
          this.editState();
        }

        if (data.currentAction === 'edit_performed') {
          this.submitForm();
        }
      }
    });
  }

  initializeForm() {
    this.values = [];
    if (this.feed.id != undefined || this.feed != undefined) {
      this.values.push(new QuestionBase({
        value: this.feed.side, label: 'Side', type: 'select',
        validators: [Validators.required], options: ['Left', 'Right', 'Bilateral']
      }));
      this.values.push(new QuestionBase({
        value: this.feed.joint_size, label: 'Joint Size', type: 'select',
        validators: [Validators.required], options: ['Small', 'Medium', 'Large']
      }));
      this.values.push(new QuestionBase({
        value: this.feed.neoprene_size,
        label: 'Neoprene Size',
        type: 'string',
        validators: [Validators.required]
      }));
      this.values.push(new QuestionBase({
        value: this.feed.neoprene_thigh_size,
        label: 'Neoprene Thigh Size',
        type: 'select',
        validators: [Validators.required],
        options: ['A', 'B', 'H']
      }));
      this.values.push(new QuestionBase({
        value: this.feed.neo_band_size,
        label: 'Neo Band Size',
        type: 'string',
        validators: [Validators.required]
      }));
      this.values.push(new QuestionBase({
        value: this.feed.cuff_size,
        label: 'Cuff Size',
        type: 'string',
        validators: [Validators.required]
      }));
      this.values.push(new QuestionBase({
        value: this.feed.hock_circumference,
        label: 'Hock Circumference', type: 'number',
        validators: [Validators.required]
      }));
      this.values.push(new QuestionBase({
        value: this.feed.complete_tear, label: 'complete tear', type: 'select', options: ['yes', 'no', 'na']
      }));
      this.values.push(new QuestionBase({ value: this.getFormattedDate(this.feed.date_received), label: 'date received', type: 'date' }));
      this.values.push(new QuestionBase({ value: this.getFormattedDate(this.feed.due_date), label: 'due date', type: 'date' }));
      this.values.push(new QuestionBase({
        value: this.feed.cast_notes, label: 'cast notes', type: 'textarea', class: 'col-lg-12 col-md-12 col-sm-12 col-xs-12'
      }));
      this.values.push(new QuestionBase({
        value: this.feed.plastic_thickness, label: 'Plastic Thickness', type: 'string'
      }));
      this.values.push(new QuestionBase({
        value: this.feed.plastic_size, label: 'Plastic Size', type: 'string'
      }));
      this.values.push(new QuestionBase({
        value: this.feed.plastic_cut, label: 'Plastic Cut', type: 'select', options: ['yes', 'no',]
      }));
      this.values.push(new QuestionBase({
        value: this.feed.plastic_pulled, label: 'Plastic Pulled', type: 'select', options: ['yes', 'no']
      }));
      this.form = this.qcs.toFormGroup(this.values);
    }
  }

  getFormattedDate = (dateData = ''): string => {
    if (dateData) {
      return new Date(dateData).toISOString().substring(0, 10);
    }
    return '';
  }

  handleSaveFeedSuccess(): any {
    this.failure = false;
    this.success = true;
    if (this.feed) {
      this.store.dispatch(new FeedsFetchOrderAction(this.feed.id));
    }
    if (this.sideValueUpdated) {
      this.sideValueUpdated = false;
      this.interactionService.setShouldUpdateWipListing(true);
    }
    this.interactionService.setIsDataUpdating(false);
  }

  handleSaveFeedFailure(): any {
    this.success = false;
    this.failure = true;
    this.interactionService.setIsDataUpdating(false);
  }

  updateFeed(feed: Feed) {
    this.feed = feed || <any>{};
    this.initializeForm();
  }

  editState() {
    this.editStateBool = !this.editStateBool;
  }

  checkboxChange(key: string, value: boolean) {
    this.form.get(key).setValue(!value);
    this.values[key] = !value;
  }

  submitForm() {
    this.interactionService.setIsDataUpdating(true);
    if (this.feed && this.form.value) {
      this.sideValueUpdated = this.feed.side !== this.form.value.side;
    }
    this.editStateBool = false;
    if (this.feed) {
      for (const key of Object.keys(this.form.controls)) {
        this.feed[key] = this.form.get(key).value;
      }
      this.store.dispatch(new OrderSaveLabInfoAction(this.feed.id, this.form.value));
      this.initializeForm();
    }
  }

  ngOnDestroy() {
    //show confirmation dialog if form input field value are changed
    if (this.form.dirty) {
      this.submitForm();
    } else {
      this.interactionService.setEditedTab(false);
    }
    super.ngOnDestroy();
  }

  

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (this.form.dirty) {
      this.submitForm();
      return false;
    }
  }

}
