import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "./reducers/index";

declare var ga, hj, fbq, uetq, window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public router: Router, private _store: Store<AppState>) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {

        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
        hj('stateChange', event.urlAfterRedirects);
        fbq('track', "PageView");
        uetq.push("pageLoad");
        window._onload();
      }
    });

    this._store.select(x => x.mainState.app.mediaService).subscribe((service) => {
      if (service)
        service.initialize();
    });
  }
}
