import { map } from "rxjs/operators";
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../reducers";
import { ActivityResponse } from "../../../../services/api/model/Activity";
import { DatePipe } from "@angular/common";
import { Actions } from "@ngrx/effects";
import {
  ActionTypes,
  FeedsFetchActivitiesAction,
  FeedsFetchActivityCategoryAction,
  FeedsSaveActivityAction,
} from "app/actions/feeds";
import { AppModule } from "../../../../app.module";
import { HasSubscriptions } from "../../../../directives/HasSubscriptions";
import {
  findArrayIntersection,
  truncateString,
} from "../../../../utilities/utilities";
import { ApiService } from "../../../../services/api/api-service";
import { sortBy } from "lodash";
import { MdDialog } from "@angular/material";
import { SaveConfirmation } from "app/components/save-confirmation/save-confirmation.component";

@Component({
  selector: "app-feed-popup-tasks",
  templateUrl: "./feed-popup-tasks.component.html",
  styleUrls: ["./feed-popup-tasks.component.scss"],
})
export class FeedPopupTasksComponent
  extends HasSubscriptions
  implements OnInit, OnDestroy
{
  @Input() orderId: number;
  activities: Observable<ActivityResponse[]>;
  filteredActivities: Observable<ActivityResponse[]>;
  dropdown = false;
  shouldShowTagDropdown = false;
  adding = false;
  today: Date = new Date();
  fields: any[] = [];
  activityForm = this.fb.group({
    note: ["", Validators.required],
    activity_type: ["", Validators.required],
    parameters: this.fb.group({}),
    tags: [""],
    time: [""],
  });
  selectedActivityType;
  selectedActivityId: number;
  selectedActivities = [];
  selectedTags = [];
  selectedTag: number;
  clickedActivity: number = null;
  activityTypeSelect = [];
  isActivityView = true;

  activityTypes: any[] = [{
    'typeName': 'Call',
    'fields': [
        {
            'type': 'string',
            'fieldName': 'to whom',
            'isRequired': true,
        },
        {
            'type': 'number',
            'fieldName': 'duration (in minutes)'
        },
        {
            'type': 'tel',
            'fieldName': 'phone number'
        },
        {
            'type': 'radio',
            'fieldName': 'call type',
            'labels': [
                'incoming',
                'outgoing'
            ]
        }
    ]
},
    {
        'typeName': 'Email',
        'fields': [
            {
                'type': 'text',
                'fieldName': 'to',
                'isRequired': true,
            },
            {
                'type': 'radio',
                'fieldName': 'email type',
                'labels': [
                    'incoming',
                    'outgoing'
                ]
            }
        ]
    },
    {
        'typeName': 'Adjustment',
        'fields': [
            {
                'type': 'checkbox',
                'fieldName': 'is completed',
                'labels': [
                    'is completed',
                ],
            },
            {
                'type': 'timestamp',
                'fieldName': 'completion time',
            }
        ]
    },
    {
        'typeName': 'Shipping',
        'fields': [{
            'type': 'number',
            'fieldName': 'tracking number'
        }, {
            'type': 'dropdown',
            'fieldName': 'type',
            'options': [
                'sent kit',
                'received cast',
                'send brace',
                'received brace'
            ]
        }]
    },
    {
        'typeName': 'Note',
        'fields': []
    },
    {
        'typeName': 'Refurbish',
        'fields': [
            {
                'type': 'checkbox',
                'fieldName': 'is completed',
                'labels': [
                    'is completed',
                ],
            },
            {
                'type': 'timestamp',
                'fieldName': 'completion time',
            }
        ]
    }
];
  failureSubscription: any;
  successSubscription: any;
  succes = false;
  failure = false;
  domain = AppModule.domain;
  selectedActivity = null;
  tags = [];
  splicedTags = [];
  showAllTags = false;
  authInfo: any;
  currentCase: any;
  caseMerged  = false;
  leads = [];
  currentCaseId: any;
  showLeads = false;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private actions: Actions,
    private apiService: ApiService,
    private dialog: MdDialog
  ) {
    super();
  }

  async ngOnInit() {
    this.activities = this.store.select((x) => x.mainState.feeds.activities);
    this.filteredActivities = this.activities;
    this.failureSubscription = this.actions
      .ofType(ActionTypes.SaveActivityFailure)
      .pipe(this.getTakeUntil())
      .subscribe((failure) => this.handleAddTaskFailure());
    this.successSubscription = this.actions
      .ofType(ActionTypes.SaveActivitySuccess)
      .pipe(this.getTakeUntil())
      .subscribe((success) => this.handleAddTaskSuccess());
    this.setActivityTypes();
    this.setActivityFilter(["Adjustment", "Refurbish"]);

    this.store.dispatch(new FeedsFetchActivityCategoryAction());
    this.actions
      .ofType(ActionTypes.FetchActivityCategorySuccess)
      .pipe(this.getTakeUntil())
      .subscribe((data: any) => this.setSystemTags(data.payload));
    document.addEventListener("click", this.handleClickEvent);
    this.store
      .pipe(select((state: any) => state.mainState.auth))
      .subscribe((data) => {
        this.authInfo = data;
      });
    const storeSubscriber = this.store.select((x) => {
      return x.mainState.feeds.currentOrder;
    });
    storeSubscriber.subscribe((data) => {
      this.currentCaseId = data && data.case ? data.case.id : null;
    });
    this.store.pipe(select((state: any) => state.mainState.feeds.currentOrder)).subscribe((data) => {
      this.currentCase = data;
   });
    await this.apiService.getLeadByCase(this.currentCaseId).subscribe((leadData) => {
      if (leadData) {
        this.leads = leadData.data.filter((data) => {
          if (this.isJSON(data.detail)) {
            let leadDetails = JSON.parse(data.detail);
            if (leadDetails != undefined) {
              data.detail = leadDetails;
            }
          }
          return data.case_id == this.currentCaseId;
        });
      }
    });
  }

  handleClickEvent = (event) => {
    const tagListingElement = document.getElementById("tag-listing");
    const eventTarget = event.target;
    if (tagListingElement) {
      const isClickedInsideTagListing = tagListingElement.contains(eventTarget);
      const isTagButtonClicked =
        (eventTarget && eventTarget.id === "tag-button") ||
        eventTarget.id === "tag-label";
      if (
        !isClickedInsideTagListing &&
        !isTagButtonClicked &&
        this.shouldShowTagDropdown
      ) {
        this.shouldShowTagDropdown = false;
      }
    }

    const activityTypeListingElement =
      document.getElementById("activity-listing");
    if (activityTypeListingElement) {
      const isClickedInsideActivityListing =
        activityTypeListingElement.contains(eventTarget);
      const activityButtonClicked =
        (eventTarget && eventTarget.id === "activity-element") ||
        eventTarget.id === "activity-label";
      if (
        !isClickedInsideActivityListing &&
        !activityButtonClicked &&
        this.dropdown
      ) {
        this.dropdown = false;
      }
    }
  };

  handleAddTaskSuccess() {
    this.store.dispatch(new FeedsFetchActivityCategoryAction());
    this.store.dispatch(new FeedsFetchActivitiesAction(this.orderId));
    this.failure = false;
    this.succes = true;
    this.filteredActivities = this.activities;
    this.resetForm();
    this.setActivityFilter(["Adjustment", "Refurbish"]);
  }

  setActivityTypes = (): void => {
    this.activityTypeSelect = this.activityTypes.map((singleActivityType) => {
      return { name: singleActivityType.typeName };
    });
  };
  setSystemTags = (systemTags) => {
    this.tags = sortBy(
      systemTags.filter((singleTag) => singleTag.name !== ""),
      (tag) => tag.name
    );
    this.splicedTags = this.tags.splice(0, 10);
  };

  setActivityType = (activityTypes) =>
    (this.activityTypeSelect = activityTypes);

  handleAddTaskFailure() {
    this.succes = false;
    this.failure = true;
  }

  toggleDrop() {
    this.dropdown = !this.dropdown;
  }

  toggleTagsDropdown = () => {
    this.shouldShowTagDropdown = !this.shouldShowTagDropdown;
  };

  resetSuccessAndFailure = () => {
    this.succes = false;
    this.failure = false;
  };

  addButtonClicked = () => {
    this.selectedActivity = null;
    this.resetForm();
    this.toggleAdding();
  };

  toggleAdding() {
    this.resetSuccessAndFailure();
    this.adding = !this.adding;
    setTimeout(() => {
      if (
        this.activityForm.value.activity_type &&
        <HTMLInputElement>document.getElementById("type")
      ) {
        (<HTMLInputElement>document.getElementById("type")).value =
          this.activityForm.value.activity_type;
      }
    }, 1000);
  }

  getFields() {
    this.fields.forEach((e) => {
      this.parameters.removeControl(e.fieldName);
    });
    this.fields =
      this.activityTypes[
        this.activityTypes
          .map((p) => p.typeName)
          .indexOf(this.activityForm.value.activity_type)
      ].fields;
    this.fields.forEach((element) => {
      if (element.isRequired) {
        this.parameters.addControl(
          element.fieldName,
          new FormControl("", Validators.required)
        );
      } else {
        this.parameters.addControl(element.fieldName, new FormControl(""));
      }
    });
  }

  get parameters(): FormGroup {
    return <FormGroup>this.activityForm.get("parameters");
  }

  updateEntityDynamically = () => {
    if (
      this.selectedActivity.event_type.name === "Adjustment" ||
      this.selectedActivity.event_type.name === "Refurbish"
    ) {
      const updatedActivityPayload = this.formToData();
      updatedActivityPayload.parameters = this.formToData().parameters.map(
        (singleParameter) => {
          singleParameter.id = this.selectedActivity[singleParameter.name].id;
          return singleParameter;
        }
      );
      this.apiService
        .updateActivity(this.selectedActivity.id, updatedActivityPayload)
        .pipe(this.getTakeUntil())
        .subscribe(() => {
          this.store.dispatch(new FeedsFetchActivitiesAction(this.orderId));
          this.resetForm();
        });
    }
    this.selectedActivity = null;
  };

  onSubmit() {
    if (this.selectedActivity) {
      this.updateEntityDynamically();
    } else {
      this.store.dispatch(new FeedsSaveActivityAction(this.formToData()));
      if (this.succes) {
        this.resetForm();
      }
    }
    this.adding = false;
  }

  resetForm() {
    this.activityForm.controls["note"].reset("");
    this.activityForm.controls["parameters"].reset();
    this.activityForm.controls["tags"].reset("");
    this.activityForm.controls["time"].reset(
      this.datePipe.transform(new Date(), "yyyy-MM-ddTHH:mm")
    );
  }

  formToData(): any {
    const data = {
      note: this.activityForm.value.note,
      activity_type: this.activityForm.value.activity_type,
      parameters: [],
      tags: [],
      object: {
        id: "",
        value: "",
      },
      time: "",
      order_id: this.orderId,
    };
    this.fields.forEach((element) => {
      data.parameters.push({
        name: element.fieldName,
        data_type: this.parameters.get(element.fieldName).value
          ? this.parameters.get(element.fieldName).value.gender
          : "",
        value: this.getValueDynamically(element),
      });
    });
    if (this.activityForm.value.tags != "") {
      this.activityForm.value.tags.split(",").forEach((element) => {
        data.tags.push(element.trim());
      });
    }
    data.time = this.activityForm.value.time;
    return data;
  }

  getValueDynamically = (element) => {
    if (element.fieldName === "is completed") {
      return this.parameters.get(element.fieldName).value === true
        ? "Yes"
        : "No";
    }

    if (element.fieldName === "completion time") {
      if (this.parameters.get("is completed").value) {
        return this.parameters.get(element.fieldName).value;
      }
      return "";
    }
    return this.parameters.get(element.fieldName).value;
  };

  singleTagSelected = (tagId): void => {
    if (this.selectedTag === tagId) {
      this.selectedTag = null;
      this.filteredActivities = this.activities;
      return;
    } else {
      this.selectedTag = tagId;
    }

    this.filteredActivities = this.activities.pipe(
      map((singleActivity: any) => {
        return singleActivity.filter((singleActivity1: any) => {
          return (
            singleActivity1.tags &&
            singleActivity1.tags[0] &&
            singleActivity1.tags[0].id === this.selectedTag
          );
        });
      })
    );
    return;
  };

  setActivityFilter = (activityTypes): void => {
    if (typeof activityTypes === "string") {
      if (this.selectedActivities.includes(activityTypes)) {
        this.selectedActivities.splice(
          this.selectedActivities.indexOf(activityTypes),
          1
        );
      } else {
        this.selectedActivities.push(activityTypes);
      }
    } else {
      this.selectedActivities = activityTypes;
    }

    if (this.selectedActivities.length) {
      this.filteredActivities = this.activities.pipe(
        map((singleActivity: any) => {
          return singleActivity.filter((singleActivity1: any) => {
            return (
              singleActivity1.event_type &&
              singleActivity1.event_type.name &&
              this.selectedActivities.includes(singleActivity1.event_type.name)
            );
          });
        })
      );
      if (this.selectedActivities.indexOf("leads") !== -1){
          this.showLeads = true;
      } else {
          this.showLeads = false;
      }
    } else {
      this.filteredActivities = this.activities;
      if (this.leads.length > 0) {
        this.showLeads = true;
      }else {
        this.showLeads = false;
     }
    }

   
  };

  tagPillClicked = (tag): void => {
    if (this.selectedTags.includes(tag.id)) {
      this.selectedTags.splice(this.selectedTags.indexOf(tag.id), 1);
    } else {
      this.selectedTags.push(tag.id);
    }

    if (this.selectedTags.length) {
      this.filteredActivities = this.activities.pipe(
        map((singleActivity: any) => {
          return singleActivity.filter((singleActivity1: any) => {
            return (
              singleActivity1.tags &&
              singleActivity1.tags[0] &&
              findArrayIntersection(
                this.selectedTags,
                singleActivity1.tags.map((singleTag) => singleTag.id)
              ).length > 0
            );
          });
        })
      );
    } else {
      this.filteredActivities = this.activities;
    }
  };

  imageClicked = (event, imageLink): void => {
    event.preventDefault();
    window.open(this.domain + imageLink, "_blank");
  };

  getImageName = (imageLink): string => {
    if (imageLink) {
      const imageUrlArray = imageLink.split("/");
      return imageUrlArray[imageUrlArray.length - 1];
    }

    return "";
  };

  activityClicked = (clickedActivityId) => {
    if (clickedActivityId !== this.clickedActivity) {
      this.clickedActivity = clickedActivityId;
    } else {
      this.clickedActivity = null;
    }
  };

  editClicked = (activity) => {
    this.toggleAdding();
    this.activityForm.patchValue({
      activity_type: activity.event_type.name,
      note: activity.name,
      tags: activity.tags
        .reduce((accumulated, single) => accumulated + " " + single.name, "")
        .trim(),
      time: activity.time ? activity.time.split(".")[0] : this.today,
    });
    this.getFields();
    if (
      activity.event_type.name === "Adjustment" ||
      activity.event_type.name === "Refurbish"
    ) {
      this.selectedActivity = activity;
      const isCompleted = activity.details.find(
        (singleActivity) =>
          singleActivity.event_parameter.name === "is completed"
      );
      const completionTime = activity.details.find(
        (singleActivity) =>
          singleActivity.event_parameter.name === "completion time"
      );
      setTimeout(() => {
        this.parameters.patchValue({
          "is completed": isCompleted
            ? isCompleted.value.toLowerCase() === "yes"
            : false,
          "completion time": completionTime ? completionTime.value : null,
        });
      }, 800);
      this.selectedActivity["is completed"] = isCompleted;
      this.selectedActivity["completion time"] = completionTime;
    }
  };

  truncateString = (toTruncateString) => truncateString(toTruncateString, 20);

  toggleAllTagsView = () => {
    this.showAllTags = !this.showAllTags;
  };

  ngOnDestroy() {
    //show confirmation dialog if form input field value are changed
    if (this.activityForm.dirty) {
      this.onSubmit();
    }
    super.ngOnDestroy();
    document.removeEventListener("click", this.handleClickEvent);
  }

 

  getFirstName(value) {
    if (value) {
      let name = value.split(" ");
      let first_name = name[0];
      return first_name ? first_name : "";
    } else {
      return "";
    }
  }

  /**
   * Check if string is json parsable
   * @param str String
   * @returns true||false
   */
  isJSON = (str: string) => {
    if (typeof str === "string") {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {}
    }
    return false;
  };

  openEmailLink(link) {
    if (link) {
      window.open(link, "_target");
    }
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeunloadHandler(event) {
    if (this.activityForm.dirty) {
      this.onSubmit();
      return false;
    }
  }

    showBackgroundcolorByCase(activity){
      if ( this.currentCase.case.order_id == activity.order_id) {
          if (activity.user) {
            return  this.authInfo.id == activity.user.id ? '#4baf4c' : '#1f2e33';
          } else {
            return '#1f2e33';
          }
      } else{
          return '#99DDE7';
      }
  }
}
