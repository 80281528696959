import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';

declare var wplSlide;

@Component({
  selector: 'app-wrist-brace',
  templateUrl: './wrist-brace.component.html',
  styleUrls: ['./wrist-brace.component.scss']
})
export class WristBraceComponent implements OnInit {

  pageInfo: any;
  constructor()
  {
    this.pageInfo = {
      one: {
        title: 'Front Leg Problems?',
        text: 'Known as a carpus brace in a dog.  Carpus refers to many bones of the wrist.  With so many bones, age and weight tend to cause laxity in the ligaments.  You see a variety of different problems because of this.',
        photo: 'Baby-and-dog-wrist-brace.jpg'
      },
      two: {
        title: 'What common diagnoses does dog wrist braces help?',
        blocks: [
          {
            title: 'Carpal Hyperextension',
            text: 'When you see the paw flexing excessively'
          },
          {
            title: 'Carpal Arthrodesis',
            text: 'Larger dogs need added support after surgery'
          },
          {
            title: 'Varus/Valgus Deformities',
            text: 'When the paw shifts side to side'
          },
        ]
      },
      three: {
        title: 'Carpal Biomechanics',
        text: `3-Point Force System — Using the ground force we only need two forces in opposite directions to make the leg stand straight. The other two are lifting behind the carpus and pushing in front of the radius/ulna. See where the plastic is in our brace. The plastic applies the forces instead of straps that can fail with dirt and muck. Hero braces take those worries away.
        <br>
        Ground Reaction Force — Ground force and body weight counteract to harness gravity to help with the correction. That's why we include the paw, for better results.`,
        photo: '1492513394.jpg'
      }
    }
  }

  ngAfterViewInit()
  {
    new wplSlide($('.wplSlide')[0], {
      autoRestart: true,
      auto: 5000,
    });
  }

  ngOnInit() {

  }
}
