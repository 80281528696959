import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppState } from '../../reducers/index';
import { Store } from '@ngrx/store';
import { FeedsSetFilterAction, FeedsSetSearchFilterAction } from '../../actions/feeds';
import { Observable } from 'rxjs';
import { AppModule } from '../../app.module';
import { FormGroup, Validators } from '@angular/forms';
import { FeedsClearSearchAction, FeedsSearchAction } from '../../actions/feeds';
import { QuestionControlService, QuestionBase } from 'app/services/api/model/Question-Control-Service';
@Component({
  selector: 'app-cases-new',
  templateUrl: './cases-new.component.html',
  styleUrls: ['./cases-new.component.scss'],
  providers: [QuestionControlService]
})
export class CasesNewComponent implements OnInit {

  authInfo: any;
  selectedFilter: string;
  navFilter = 'all';
  mode: Observable<string>;
  searchFilter: Observable<string>;
  domain = AppModule.domain;
  fetching: boolean;
  scrollThreshHoldPercent = 10;
  @ViewChild('scrollDiv') scrollDiv: ElementRef;
  showDropDown: boolean;
  values: QuestionBase<any>[] = [];
  form: FormGroup;

  constructor(private store: Store<AppState>,private qcs: QuestionControlService) {
  }

  ngOnInit() {
    this.mode = this.store.select(x => x.mainState.feeds.mode);
    this.searchFilter = this.store.select(x => x.mainState.feeds.searchFilter);
    this.authInfo = this.store.select(x => x.mainState.auth);
    this.setNav('all');
    this.initializeForm();
  }
  

  filter(filter: string) {
    if (this.selectedFilter == filter) {
      this.selectedFilter = null;
      this.store.dispatch(new FeedsSetFilterAction(null, this.navFilter));
      return;
    }

    this.selectedFilter = filter;
    this.store.dispatch(new FeedsSetFilterAction(filter, this.navFilter));
  }

  filterSearch(filter: string) {
    this.store.dispatch(new FeedsSetSearchFilterAction(filter));
  }

  setNav(nav: string) {
    this.navFilter = nav;
    this.filter(this.selectedFilter);
    if(nav == 'doctors'){
      this.form.controls.object_type.setValue('Vet');
    }else{
      if (this.form) {
        this.form.controls.object_type.setValue('');
      }
    }
  }

  initializeForm() {
    this.values = [];
    this.values.push(new QuestionBase({key: 'q', value: '', type: 'main', validators: [Validators.required]}));
    this.values.push(new QuestionBase({
      key: 'object_type',
      value: '',
      label: 'type',
      type: 'checkboxSelect',
      options: ['Dog', 'Vet', 'Order', 'Case']
    }));
    // this.values.push(new QuestionBase({key: 'start_date', value: '', label: 'start date', type: 'dateSelect'}));
    // this.values.push(new QuestionBase({key: 'end_date', value: '', label: 'end date', type: 'dateSelect'}));
    // this.values.push(new QuestionBase({key: 'order', value: '', label: 'order', type: 'checkboxSelect', options: ['asc', 'desc']}));
    this.form = this.qcs.toFormGroup(this.values);
  }

  getWidth(element: HTMLElement) {
    return element.offsetWidth;
  }

  clearSearch() {
    this.store.dispatch(new FeedsClearSearchAction(null));
    this.form.reset();
    this.showDropDown = false;
    //set object type value vet if navbar tab is doctor
    if(this.navFilter == 'doctors'){
      this.form.controls.object_type.setValue('Vet');
    }
  }
 

  showOptions() {
    this.showDropDown = true;
  }

  hideOptions() {
    this.showDropDown = false;
  }

  doSearch() {
    this.store.dispatch(new FeedsSearchAction(this.form.value));
    this.showDropDown = false;
  }
}
