import { Component, Inject, OnInit } from '@angular/core';
import { MD_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-vet-info',
  templateUrl: './vet-info.component.html',
  styleUrls: ['./vet-info.component.scss']
})
export class VetInfoComponent {
  constructor(@Inject(MD_DIALOG_DATA) public vet) {}
}
