import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { ActionTypes, AuthLoginAction, AuthRequestPasswordResetAction } from 'app/actions/auth';
import { Actions } from '@ngrx/effects';
import { Router } from '@angular/router';
import { LoginResult } from '../../services/api/model/LoginResponse';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  loginModel: {
    username: string,
    password: string
  } = {
    username: '',
    password: ''
  };
  resetModel: any = {
    email: ''
  };
  failureSubscription: any;
  successSubscription: any;
  failure: boolean = false;

  requestFailureSubscription: any;
  requestSuccessSubscription: any;
  mode: string = 'login';

  userToken: string = null;
  privileges: any;

  constructor(private store: Store<AppState>, private actions: Actions, private router: Router) {
    this.failureSubscription = this.actions.ofType(ActionTypes.LoginFailure)
      .subscribe((failure) => this.handleLoginFailure());
    this.successSubscription = this.actions.ofType(ActionTypes.LoginSuccess)
      .subscribe((result) => this.handleLoginSuccess(<any>result));

    this.requestFailureSubscription = this.actions.ofType(ActionTypes.RequestPasswordResetFailure)
      .subscribe((failure) => this.handleResetRequestFailure());
    this.requestSuccessSubscription = this.actions.ofType(ActionTypes.RequestPasswordResetSuccess)
      .subscribe((result) => this.handleResetRequestSuccess());
  }

  ngOnInit() {
    this.store.select(x => x.mainState.auth).subscribe(x => {
      this.userToken = x.userToken;
      this.privileges = x.privilege;
    });
    if (this.userToken != null && this.userToken != '') {
      if (this.privileges.is_case_manager || this.privileges.is_order_manager || this.privileges.is_admin) {
        this.router.navigate(['/case-manager']);
      } else {
        this.router.navigate(['/cases']);
      }
    }
  }

  ngOnDestroy() {
    this.failureSubscription.unsubscribe();
    this.successSubscription.unsubscribe();

    this.requestFailureSubscription.unsubscribe();
    this.requestSuccessSubscription.unsubscribe();
  }

  login() {
    this.store.dispatch(new AuthLoginAction(Object.assign({}, this.loginModel)));
  }

  handleLoginFailure() {
    this.failure = true;
  }

  handleLoginSuccess(result: LoginResult) {
    if (result.payload.privilege.is_case_manager || result.payload.privilege.is_order_manager || result.payload.privilege.is_admin) {
      this.router.navigate(['/case-manager']);
    } else {
      this.router.navigate(['/cases']);
    }
  }

  resetPassword() {
    this.mode = 'reset';
  }

  goBack() {
    this.mode = 'login';
  }

  reset() {
    this.store.dispatch(new AuthRequestPasswordResetAction(this.resetModel));
  }

  private handleResetRequestFailure() {

  }

  private handleResetRequestSuccess() {

  }
}
