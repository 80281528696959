import {Pipe, PipeTransform} from "@angular/core";
import {utilities} from "app/utilities/utilities";

@Pipe({ name: 'dataToggle' })
export class DataTogglePipe implements PipeTransform {
  transform(text: string)
  {
    if (!text) {
      return false;
    }

    return utilities.dataToggle(text);
  }
}
