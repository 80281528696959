import { Component, EventEmitter, OnDestroy, OnInit, Output,HostListener } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {Owner} from '../../../../services/api/model/Owner';
import {QuestionBase, QuestionControlService} from 'app/services/api/model/Question-Control-Service';
import {FormGroup} from '@angular/forms';
import {ActionTypes, OrderSaveOwnerInfoAction} from 'app/actions/order';
import {Actions} from '@ngrx/effects';
import {getReadableErrorMessage} from '../../../../utilities/utilities';
import {ApiService} from '../../../../services/api/api-service';
import {FeedsFetchOrderAction} from '../../../../actions/feeds';
import {InteractionService} from '../../../../services/interaction.service';
import { HasSubscriptions } from '../../../../directives/HasSubscriptions';
import { MdDialog } from "@angular/material";

@Component({
  selector: 'app-feed-popup-user',
  templateUrl: './feed-popup-user.component.html',
  styleUrls: ['./feed-popup-user.component.scss'],
  providers: [QuestionControlService]
})
export class FeedPopupUserComponent extends HasSubscriptions implements OnInit, OnDestroy {
  owner: Owner;
  editStateBool: boolean;
  values: QuestionBase<any>[] = [];
  form: FormGroup;
  success: boolean;
  failure: boolean;
  failureSubscription: any;
  successSubscription: any;
  errorMessage = '';
  currentCase = null;
  showUserCreatedMessage = false;
  ownerLastNameUpdated = false;
  @Output() ownerUpdated: EventEmitter<object> = new EventEmitter<object>();

  constructor(
    private qcs: QuestionControlService,
    private store: Store<AppState>,
    private actions: Actions,
    private apiService: ApiService,
    private interactionService: InteractionService,
    private dialog: MdDialog,
  ) {
    super();
    this.failureSubscription = this.actions.ofType(ActionTypes.SaveOwnerInfoFailure)
      .subscribe((failure) => this.handleSaveFeedFailure(failure));
    this.successSubscription = this.actions.ofType(ActionTypes.SaveOwnerInfoSuccess)
      .subscribe((result) => this.handleSaveFeedSuccess());

    this.store.select(x => x.mainState.feeds.currentOwner).subscribe((owner: Owner) => {
      this.updateOwner(owner);
    });
  }

  ngOnInit() {
    this.failureSubscription = this.actions.ofType(ActionTypes.SaveOwnerInfoFailure)
      .subscribe((failure) => this.handleSaveFeedFailure(failure));
    this.successSubscription = this.actions.ofType(ActionTypes.SaveOwnerInfoSuccess)
      .subscribe((result) => this.handleSaveFeedSuccess());

    this.store.select(x => {
      this.currentCase = x.mainState.feeds.currentOrder;
      return x.mainState.feeds.currentOwner;
    }).subscribe((owner: Owner) => {
      this.updateOwner(owner);
    });
    this.interactionService.sharedEditedTab.pipe(this.getTakeUntil()).subscribe((data: any) => {
      if (data.section === 'owner') {
        if (data.currentAction === 'edit') {
          this.editState();
        }

        if (data.currentAction === 'edit_performed') {
          this.submitForm();
        }
      }
    });
  }

  initializeForm() {
    this.values = [];
    if (this.owner.id != undefined || this.owner != undefined) {
      const ownerAddress = (this.owner && this.owner.address) ? this.owner.address : {};
      this.values.push(new QuestionBase({value: this.owner.first_name, label: 'First', key: 'first_name'}));
      this.values.push(new QuestionBase({value: this.owner.last_name, label: 'Last', key: 'last_name'}));
      this.values.push(new QuestionBase({value: this.owner.email, label: 'Email', key: 'email', type: 'email'}));
      this.values.push(new QuestionBase({value: this.owner.phone, label: 'Phone', key: 'phone', type: 'tel'}));
      this.values.push(new QuestionBase({value: ownerAddress.address1, label: 'Address 1', key: 'address1'}));
      this.values.push(new QuestionBase({value: ownerAddress.address2, label: 'Address 2', key: 'address2'}));
      this.values.push(new QuestionBase({value: ownerAddress.city, label: 'City', key: 'city'}));
      this.values.push(new QuestionBase({value: ownerAddress.zip, label: 'Zip', key: 'zip'}));
      this.values.push(new QuestionBase({value: ownerAddress.province, label: 'Province', key: 'province'}));
      this.values.push(new QuestionBase({value: ownerAddress.country, label: 'Country', key: 'country'}));
      this.form = this.qcs.toFormGroup(this.values);
    }
  }

  handleSaveFeedSuccess(): any {
    this.failure = false;
    this.success = true;
    if (this.ownerLastNameUpdated) {
      this.interactionService.setShouldUpdateWipListing(true);
      this.ownerUpdated.emit();
      this.ownerLastNameUpdated = false;
    }
    this.store.dispatch(new FeedsFetchOrderAction(this.currentCase.case.id));
  }

  handleSaveFeedFailure(failureAction): any {
    this.errorMessage = getReadableErrorMessage(failureAction);
    this.success = false;
    this.failure = true;
  }

  updateOwner(owner: Owner) {
    this.owner = owner || <any>{};
    this.initializeForm();
  }

  editState() {
    this.editStateBool = !this.editStateBool;
  }

  submitForm() {
    const payload = this.form.value;
    payload.address = {};
    this.errorMessage = '';
    this.failure = this.editStateBool = false;
    this.ownerLastNameUpdated = this.owner && (this.owner.last_name !== this.form.value.last_name);
    if (this.owner) {
      for (const key of Object.keys(this.form.controls)) {
        this.owner[key] = this.form.get(key).value;
        if (key === 'address1' || key === 'address2' || key === 'city' || key === 'zip' || key === 'province' || key === 'country') {
          payload.address[key] = this.form.get(key).value;
          if (this.owner.address) {
            this.owner.address[key] = this.form.get(key).value;
          }
        }
      }
      this.initializeForm();
      if (this.form.valid) {
        if (this.owner.id) {
          this.store.dispatch(new OrderSaveOwnerInfoAction(this.owner.id, payload));
        } else {
          payload.order_id = this.currentCase.order.id;
          this.apiService.createOwner(payload).subscribe(
            (response: any) => {
              if (response.status < 399) {
                this.showUserCreatedMessage = true;
                setTimeout(() => this.showUserCreatedMessage = false, 2500);
                this.interactionService.setOwnerAddedInfo(true);
                this.store.dispatch(new FeedsFetchOrderAction(this.currentCase.case.id));
              } else {
                this.failure = true;
                this.errorMessage = getReadableErrorMessage(response);
              }
            });
        }
      }
    }
  }

  ngOnDestroy() {
    //show confirmation dialog if form input field value are changed
    if (this.form.dirty) {
      this.submitForm();
    } else {
      this.interactionService.setEditedTab(false);
    }
    super.ngOnDestroy();
  }

 

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.interactionService.setEditedTab(false);
    if (this.form.dirty) {
      this.submitForm();
      return false;
    }
  }
 
}
