import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MD_DIALOG_DATA, MdDialog, MdDialogRef } from '@angular/material';
import { SearchResponse } from '../../services/api/model/SearchResponse';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
    FeedsFetchDogAction,
    FeedsFetchFeedAction,
    FeedsFetchOrderAction,
    FeedsFetchOwnerAction,
    FeedsFetchVetAction,
    FeedsFetchActivitiesAction,
    FeedsFetchVetSuccessAction,
    FeedsFetchDogSuccessAction,
    FeedsFetchActivityCategoryAction,
    ActionTypes,
} from '../../actions/feeds';
import { skip, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Order } from '../../services/api/model/Order';
import { getReadableErrorMessage, truncateString } from '../../utilities/utilities';
import { HasSubscriptions } from '../../directives/HasSubscriptions';
import { InteractionService } from '../../services/interaction.service';
import { ApiService } from '../../services/api/api-service';
import { Actions } from '@ngrx/effects';
import { AddTagComponent } from '../../add-tag/add-tag.component';
import { sortBy } from 'lodash';
import { DeleteConfirmation } from '../delete-confirmation/delete-confirmation.component';
import { CaseMergePopupComponent } from 'app/case-merge-popup/case-merge-popup.component';
import { ShippingFormComponent } from '../../shipping-form/shipping-form.component';

@Component({
    selector: 'app-feed-popup',
    templateUrl: './feed-popup.component.html',
    styleUrls: ['./feed-popup.component.scss']
})
export class FeedPopupComponent extends HasSubscriptions implements OnInit, OnDestroy {

    section: string = 'lab';
    sections: string[] = ['owner', 'vet', 'dog', 'lab', 'order', 'feed'];
    orderId: number = 0;
    orderEntityId: number = 0;
    order: Observable<Order>;
    caseOrder;
    caseStatus;
    caseData;
    caseRelatedTags = [];
    formattedDogName = '';
    noDriveAttached = false;
    systemTags = [];
    addTagComponentMdDialogRef: MdDialogRef<AddTagComponent> = null;
    caseStatusOptions = [
        { name: 'Ordered' },
        { name: 'Casting Kit Sent' },
        { name: 'Shipping Label Sent' },
        { name: 'Cast Rejected' },
        { name: 'In Process' },
        { name: 'Brace Shipped' },
        { name: 'Waiting Adjustment' },
        { name: 'Adjustment Shipped' },
        { name: 'Waiting Refurbish' },
        { name: 'Refurbish Shipped' },
        { name: 'Order Cancelled' },
    ];
    selectedCaseOption = '';
    errorMessage = '';
    successMessage = '';
    tags = [];
    shouldShowTagList = false;
    storeSelectSubscriber;
    actionSubscriber;
    fetchOrderSubscriber;
    interactionSubscriber;
    private orderEntity = null;
    public currentTabAction = 'edit_performed';
    public shouldDisablePrintButton = false;
    public showAllCaseTags = false;
    public removingTag = false;
    caseMerged=false;
    private shippingModalRef = null;

    constructor(
        @Inject(MD_DIALOG_DATA) public data: SearchResponse,
        public dialogRef: MdDialogRef<FeedPopupComponent>,
        private store: Store<AppState>,
        private interactionService: InteractionService,
        private apiService: ApiService,
        private actions: Actions,
        private dialog: MdDialog,
    ) {
        super();
        this.orderId = data.id;
        this.order = this.store.select(x => {
            this.caseStatus = (x.mainState.feeds.currentOrder && x.mainState.feeds.currentOrder.case)
                ? x.mainState.feeds.currentOrder.case.status : '';
            this.caseData = x.mainState.feeds.currentOrder.case;
            this.caseOrder = x.mainState.feeds.currentOrder.order;
            this.caseRelatedTags = x.mainState.feeds.currentOrder.tags;
            return x.mainState.feeds.currentOrder;
        });
     
        
    }

    async ngOnInit() {
        this.store.dispatch(new FeedsFetchOrderAction(this.orderId));
        const order = <any>await this.order.pipe(skip(1), take(1)).toPromise();
        this.order.subscribe(res=>{
            if (res.case.merged_case_id != null) {
                this.caseMerged = true;
            }
        })
        this.orderEntity = order;
        if (order.dog) {
            this.store.dispatch(new FeedsFetchDogAction(order.dog.id));
        } else {
            this.store.dispatch(new FeedsFetchDogSuccessAction({}));
        }
        this.setDogFormattedName(order);
        if (order.vet) {
            this.store.dispatch(new FeedsFetchVetAction(order.vet.id));
        } else {
            this.store.dispatch(new FeedsFetchVetSuccessAction({}));
        }
        this.store.dispatch(new FeedsFetchFeedAction(order.case.id));
        this.store.dispatch(new FeedsFetchOwnerAction(order.case.id));
        this.orderEntityId = order.order.id;
        this.store.dispatch(new FeedsFetchActivitiesAction(order.order.id));

        this.storeSelectSubscriber = this.store.select(x => x.mainState.feeds).subscribe((orderDetail: any) => {
            this.setDogFormattedName(orderDetail.currentOrder);
        });
        if (order && order.case) {
            this.selectedCaseOption = order.case.status;
        }
        this.store.dispatch(new FeedsFetchActivityCategoryAction());
        this.actionSubscriber = this.actions.ofType(ActionTypes.FetchActivityCategorySuccess)
            .subscribe((data: any) => {
                this.systemTags = data.payload;
                this.setTags();
            });
        this.fetchOrderSubscriber = this.actions.ofType(ActionTypes.FetchOrderSuccess).subscribe((orderData: any) => {
            this.orderEntity = orderData.payload.data;
            this.setTags();
        });
        this.interactionSubscriber = this.interactionService.sharedTagAdded.subscribe((tagData: any) => {
            if (tagData && (tagData.action === 'added' || tagData.action === 'cancelled')) {
                if (this.addTagComponentMdDialogRef) {
                    this.addTagComponentMdDialogRef.close();
                    //assing tag to specific order
                    if (tagData.action === 'added') {
                      this.tagClicked(tagData.tag, 'Tag Added and linked to the case.');
                    }
                }

            }
        });
        this.interactionService.sharedIsDataUpdating.pipe(this.getTakeUntil()).subscribe(isDataUpdating => {
            if (typeof isDataUpdating === 'boolean') {
                if (isDataUpdating === true) {
                    this.shouldDisablePrintButton = isDataUpdating;
                } else {
                    setTimeout(() => this.shouldDisablePrintButton = isDataUpdating, 1000);
                }
            }
        });

        this.interactionService.sharedShippingActionPerformed.pipe(this.getTakeUntil())
          .subscribe((shippingActionPerformResponse) => this.shippingFormActionPerformed(shippingActionPerformResponse));

        document.addEventListener('click', this.handleClickEvent);
       
    }

    handleClickEvent = (event: any) => {
        const eventTarget = event.target;
        const tagListElement = document.getElementsByClassName('tag-list')[0];
        const outsideOfModalClicked = eventTarget && eventTarget.classList[0] === 'cdk-overlay-backdrop';
        const addCaseTagElementClicked = eventTarget && eventTarget.classList.contains('add-case-tag-element');
        if (
            tagListElement &&
            !outsideOfModalClicked &&
            this.shouldShowTagList &&
            !tagListElement.contains(eventTarget) &&
            !addCaseTagElementClicked
        ) {
            this.shouldShowTagList = false;
        }
    }

    setTags = () => {
        const tagsWithValidName = this.systemTags.filter(singleTag => singleTag.name !== '');
        const uniqueFromTheCaseTags = tagsWithValidName
            .filter(singleTag => !this.caseRelatedTags.find(singleCaseTag => singleCaseTag.name === singleTag.name));
        this.tags = sortBy(uniqueFromTheCaseTags, (tag) => tag.name);
    }

    setDogFormattedName = (caseOrder: any = {}) => {
        const ownerLastName = caseOrder.owner ? caseOrder.owner.last_name : '';
        const dogName = caseOrder.dog ? caseOrder.dog.name : '';
        const formattedName = `${dogName} ${ownerLastName}`;
        this.formattedDogName = truncateString(formattedName, 25);
    }

    changeSection(section: string) {
        this.currentTabAction = 'edit_performed';
        this.section = section;
    }

    close() {
        this.dialogRef.close();
    }

    navLeft() {
        const currentIndex = this.sections.indexOf(this.section);
        this.section = this.sections[currentIndex - 1];
    }

    navRight() {
        const currentIndex = this.sections.indexOf(this.section);
        this.section = this.sections[currentIndex + 1];
    }

    popUpDataUpdatedHandler = (): void => {
        this.interactionService.setShouldUpdateCaseFeed(true);
        this.store.dispatch(new FeedsFetchOrderAction(this.orderId));
    };

    editButtonClicked = () => {

        this.currentTabAction = this.currentTabAction === 'edit_performed' ? 'edit' : 'edit_performed';
        this.interactionService.setEditedTab({ section: this.section, currentAction: this.currentTabAction });
    }

    deleteCaseClicked = (caseData) => {
        this.dialog.open(DeleteConfirmation, {
            data: {
                caseData,
            }
        });
    }

    printContent(toBePrintedElement): void {
        const styles = `
            .two-column-grid, .one-column-grid {
              display: grid;
              grid-template-columns: auto auto;
              margin: 1rem;
              border-radius: 0;
              border: 0.5px solid black;
            } 
            .one-column-grid {
              grid-template-columns: auto;
            }
            .item {
              border: 0.5px solid black;
              padding: 10px;
              font-size: 20px;
              text-align: center;
              text-transform: capitalize;
            }
            .special-item {
              padding-top: 15px;
              font-weight: bolder;
              font-size: 30px;
              text-transform: capitalize;
            }     
      `;
        const printContent = document.getElementById(toBePrintedElement);
        const WindowPrt = window.open('', '', 'left=0,top=0,width=1500,height=900,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write(`<style>${styles}</style>`);
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.close();
        WindowPrt.setTimeout(() => {
            WindowPrt.focus();
            WindowPrt.print();
            WindowPrt.close();
        }, 800);
    }

    photoClicked = () => {
        if (this.caseData.drive_link) {
            window.open(this.caseData.drive_link, '_blank');
        } else {
            this.noDriveAttached = true;
            setTimeout(() => this.noDriveAttached = false, 1000);
        }
    }

    caseOptionChanged = () => {
        if (this.caseOrder) {
            this.apiService.saveFeed(this.caseData.id, { status: this.selectedCaseOption })
                .subscribe(apiResponse => {
                    if (apiResponse.status === 1) {
                        this.store.dispatch(new FeedsFetchOrderAction(this.orderId));
                        this.interactionService.setShouldUpdateWipListing(true);
                        if (this.caseOrder) {
                            this.store.dispatch(new FeedsFetchActivitiesAction(this.caseOrder.id));
                        }
                    } else {
                        this.errorMessage = getReadableErrorMessage(apiResponse);
                        this.resetActionMessages();
                    }
                });
        }
        if (
          this.selectedCaseOption === 'Casting Kit Sent' || this.selectedCaseOption === 'Shipping Label Sent' ||
          this.selectedCaseOption === 'Brace Shipped' || this.selectedCaseOption === 'Waiting Adjustment' ||
          this.selectedCaseOption === 'Adjustment Shipped' || this.selectedCaseOption === 'Waiting Refurbish'
          || this.selectedCaseOption === 'Refurbish Shipped'
        ) {
            const formattedData = this.orderEntity;
            formattedData.selected_case_option = this.selectedCaseOption;
            this.shippingModalRef = this.dialog.open(ShippingFormComponent, {data: formattedData, disableClose: true});
        }
    }

    resetActionMessages = () => {
        setTimeout(() => {
            this.errorMessage = '';
            this.successMessage = '';
        }, 4000);
    }

    tagButtonClicked = () => {
        this.shouldShowTagList = !this.shouldShowTagList;
    }

    tagClicked = (clickedElement = null, successMessage = null, associateTag = true) => {
        const caseRelatedIds = this.caseRelatedTags.map(singleCaseRelatedTag => singleCaseRelatedTag.id);
        if (!this.caseData) {
            this.errorMessage = 'Invalid case Data';
        }
        if (clickedElement) {
            let tags;
            if (associateTag) {
                tags = [...caseRelatedIds, clickedElement.id];
            } else {
                this.removingTag = true;
                tags = [clickedElement.id];
            }

            const tagAssignPayload = {
                type_id: this.caseData.id,
                type: 'cases',
                tags
            };
            if (associateTag) {
                this.apiService.assignTags(tagAssignPayload).pipe(this.getTakeUntil())
                  .subscribe(tagAssignResponse => {
                      setTimeout(() => this.removingTag = false, 1000);
                      if (tagAssignResponse.status === 1) {
                          this.store.dispatch(new FeedsFetchOrderAction(this.orderId));
                          this.successMessage = successMessage ? successMessage : 'Successfully linked case to the tag.';
                          this.resetActionMessages();
                      }
                  });
            } else {
                this.apiService.unAssignTags(tagAssignPayload).pipe(this.getTakeUntil())
                  .subscribe(tagAssignResponse => {
                      setTimeout(() => this.removingTag = false, 1000);
                      if (tagAssignResponse.status === 1) {
                          this.store.dispatch(new FeedsFetchOrderAction(this.orderId));
                          this.successMessage = successMessage ? successMessage : 'Successfully linked case to the tag.';
                          this.resetActionMessages();
                      }
                  });
            }
        } else {
            this.addTagComponentMdDialogRef = this.dialog.open(AddTagComponent);
        }
    }

    toggleAllCaseTagsView = () => {
        this.showAllCaseTags = !this.showAllCaseTags;
    }
    openShippingModal = () => {
        this.order.pipe(this.getTakeUntil()).subscribe(orderData => {
            this.dialog.open(ShippingFormComponent, {data: orderData});
        });
    }

    shippingFormCancelledOrSubmitted = () => {
        console.log('here again');
    }
    shippingFormActionPerformed = (shippingEvent) => {
        if (shippingEvent && this.shippingModalRef && (shippingEvent.action === 'saved' || shippingEvent.action === 'cancelled')) {
            this.shippingModalRef.close();

            if (shippingEvent.action === 'saved') {
                const updatedMethod = shippingEvent.data.ship_to_vet ? 'vet' : 'owner';
                const shippingMethodUpdated = updatedMethod !== this.orderEntity.order.shipping_method;
                if (shippingMethodUpdated) {
                    this.apiService.updateOrder(this.orderEntity.order.id, {shipping_method: updatedMethod})
                      .subscribe(() => this.store.dispatch(new FeedsFetchOrderAction(this.orderId)));
                }
            }
        }
    }


    ngOnDestroy() {
        this.interactionService.setEditedTab(false);
        super.ngOnDestroy();
        if (this.storeSelectSubscriber) {
            this.storeSelectSubscriber.unsubscribe();
        }
        if (this.actionSubscriber) {
            this.actionSubscriber.unsubscribe();
        }
        if (this.fetchOrderSubscriber) {
            this.fetchOrderSubscriber.unsubscribe();
        }
        if (this.interactionSubscriber) {
            this.interactionSubscriber.unsubscribe();
        }
        document.removeEventListener('click', this.handleClickEvent);
    }

    mergeCaseSelected = (currentCase) => {
        this.dialog.open(CaseMergePopupComponent, {
            data: {
            currentCase,
            }
        });
    }
}
