
import {mergeMap, map} from 'rxjs/operators';
import { Observable } from "rxjs";
import { ActionTypes, AppSendContactAction, AppSendContactFailureAction, AppSendContactSuccessAction } from "../actions/app";
import { ApiService } from "../services/api/api-service";
import { Actions, Effect } from "@ngrx/effects";
import { Injectable } from "@angular/core";

@Injectable()
export class AppService {
  @Effect()
  Login: Observable<{}> = this.actions$
    .ofType(ActionTypes.SendContact).pipe(
    map((action: AppSendContactAction) => action.payload),
    mergeMap((model: any) =>
      this._api.contactSite(model).map((response: any) => {
        if (!response.success)
          return new AppSendContactFailureAction(response.error);
        return new AppSendContactSuccessAction(response.token);
      })),);

  constructor(private actions$: Actions, private _api: ApiService) { }
}
