import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[infinite-scroll]'
})
export class InfiniteScrollDirective {

  @Input() threshHold: number;

  @Output() onScroll: EventEmitter<any> = new EventEmitter<any>();

  timeout: any;

  @HostListener('scroll', ['$event'])
  scroll(event: any)
  {
    // Calculate the percentage of the position that we've scrolled down the list
    var position = ((event.target.scrollTop + event.target.clientHeight) / (event.target.scrollHeight)) * 100;

    // If our position percentage is less than the scroll threshhold percentage then just return
    if (position < this.threshHold)
      return;

    if (this.timeout)
      clearTimeout(this.timeout)

    // Fire emitter
    this.timeout = setTimeout(() => this.onScroll.emit(null), 100);
  }

  constructor() { }

}
