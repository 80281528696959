import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';

@Injectable()
export class QuestionControlService {
  constructor() {
  }

  toFormGroup(questions: QuestionBase<any>[]) {
    let group: any = {};
    questions.forEach(question => {
      group[question.key] = new FormControl(question.value || '', question.validators)
    });
    return new FormGroup(group);
  }
}

export class QuestionBase<T> {
  value: T;
  key: string;
  label: string;
  type: string;
  class: {};
  readOnly = false;
  options: string[];
  validators: ValidatorFn[];

  constructor(options: {
    value?: T,
    key?: string,
    label?: string,
    type?: string,
    options?: string[],
    class?: {},
    readOnly ?: boolean,
    validators?: ValidatorFn[],
  } = {}) {
    this.value = options.value;
    this.label = options.label || '';
    this.key = options.key || this.label.replace(/ /g, '_').replace(/['.!?\\-]/g, '').toLowerCase();
    this.type = options.type || 'text';
    this.options = options.options;
    this.class = options.class;
    this.readOnly = options.readOnly;
    this.validators = options.validators;
  }
}
