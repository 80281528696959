import { Component, OnInit } from '@angular/core';


import * as $ from 'jquery';

declare var wplSlide;

@Component({
  selector: 'app-ankle-brace',
  templateUrl: './ankle-brace.component.html',
  styleUrls: ['./ankle-brace.component.scss']
})
export class AnkleBraceComponent implements OnInit {

  pageInfo: any;
  constructor()
  {
    this.pageInfo = {
      one: {
        title: 'Dog Ankle Braces',
        text: 'Known a a hock or tarsus brace in dogs.  Imagine standing on your tiptoes for your whole life. That is what dogs do.  Eventually there is going to be something break down.',
        photo: 'Black-Lab-Ankle-Brace.jpg'
      },
      two: {
        title: 'What common diagnoses do dog ankle braces help?',
        blocks: [
          {
            title: 'Achilles Ruptures',
            text: 'The tendon tears away from the calcaneous'
          },
          {
            title: 'Degenerative Myelopathy (DM)',
            text: 'ALS for dogs.  Hind legs get weak first'
          },
          {
            title: 'Neurological Issues',
            text: 'Cause the hind limbs to crouch and/or knuckle'
          },
        ]
      },
      three: {
        title: 'How does the brace work?',
        text: `When your veterinarian does a cranial drawer test they try to shift the bones to see if the ligament is damaged. (Red Arrows) The Hero Brace is the only brace to provide plastic in the opposite postitions to counter that motion. (Green Arrows) The mechanics are a bit more complicated involving a "force couple," but these are the necessary forces to support your dog's leg.`,
        photo: '1492513394.jpg'
      }
    }
  }

  ngAfterViewInit()
  {
    new wplSlide($('.wplSlide')[0], {
      autoRestart: true,
      auto: 5000,
    });
  }

  ngOnInit() {

  }
}
