import { Component, OnInit } from '@angular/core';
import { Subject } from "rxjs";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  navs: string[] = ['The Journey', 'The Team', 'Programs We Support', 'Contact Us'];

  destroyed$ = new Subject<boolean>();

  constructor() {
  }

  ngOnInit() {
  }
}
