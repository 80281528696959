import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppState } from "../../../reducers/index";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { FeedsFetchAction } from "../../../actions/feeds";
import { truncateString } from "../../../utilities/utilities";
import { FeedComponent } from "app/containers/cases/feed/feed.component";
import { MdDialog, MdDialogRef, MD_DIALOG_DATA } from "@angular/material";
import { WebsocketService } from "../../../services/websocket.service";

// Perfect Scrollbar declaration
declare var Ps;

@Component({
  selector: "app-feeds",
  templateUrl: "./feeds.component.html",
  styleUrls: ["./feeds.component.css"],
})
export class FeedsComponent implements OnInit {
  scrollThreshholdPercent: number = 80;
  caseFeeds: Observable<any>;
  page: number = 1;
  fetching: boolean;

  @ViewChild("scrollDiv") scrollDiv: ElementRef;

  constructor(
    private store: Store<AppState>,
    private dialog: MdDialog,
    private websocketService: WebsocketService
  ) {
    this.caseFeeds = this.store.select((x) => x.mainState.feeds.feeds);
    this.store
      .select((x) => x.mainState.feeds.fetching)
      .subscribe((fetching) => (this.fetching = fetching));
    this.websocketService.getData('caseupdated').subscribe((response) => {
      // console.log('response',response);
      if (response) {
        this.caseFeeds.subscribe((caseFeeds) => {
          if (caseFeeds.length) {
            let caseKey = caseFeeds.findIndex((x) => x.id == response.id);
            caseFeeds[caseKey].name = response.name;
            caseFeeds[caseKey].diagnosis = response.diagnosis;
            caseFeeds[caseKey].status = response.status;
          }
        })
      }
    })
  }

  ngOnInit() {
    this.store.dispatch(
      new FeedsFetchAction({ page: this.page++, pageSize: 50, init: true })
    );
  }

  onScrollDown() {
    this.store.dispatch(
      new FeedsFetchAction({ page: this.page++, pageSize: 10 })
    );
  }

  ngOnDestroy() {
    // Destroy Perfect Scrollbar so it unsubscribes from the window scroll event
    Ps.destroy(this.scrollDiv.nativeElement);
  }

  ngAfterViewInit() {
    // Initialize Perfect Scrollbar
    Ps.initialize(this.scrollDiv.nativeElement);
  }

  handleScroll() {
    // If we're currently fetching results then just return
    if (this.fetching) {
      return;
    }

    // We hit the threshhold, fetch some more cases
    this.onScrollDown();
  }

  truncateString = (argumentString) => truncateString(argumentString);

  checkInformationComplete(data) {
    if (data.diagnosis && data.side && data.name) {
      return "#1eac09";
    } else {
      return "#c10505";
    }
  }

  editCase(caseData) {
    let dialogRef = this.dialog.open(FeedComponent, {
      data: {
        case_id: caseData.id,
        case: caseData,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      // received data from dialog-component
      if (res == "close") {
        this.caseFeeds = this.store.select((x) => x.mainState.feeds.feeds);
      }
    });
  }
}
