import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { InteractionService } from '../services/interaction.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api/api-service';
import { HasSubscriptions } from '../directives/HasSubscriptions';
import { MD_DIALOG_DATA } from '@angular/material';
import { getReadableErrorMessage } from '../utilities/utilities';


@Component({
  selector: 'app-lead-form',
  templateUrl: './lead-form.component.html',
  styleUrls: ['./lead-form.component.scss']
})

export class LeadFormComponent extends HasSubscriptions implements OnInit {
  leadFormGroup: FormGroup;
  public isEditView = false;
  public isCreateView = false;
  public isDetailView = false;
  public currentLead = null;
  public leadDetails = null;
  public successMessage = '';
  public errorMessage = '';
  public shouldDisableButtons = false;
  public closeModal: EventEmitter<object> = new EventEmitter<object>();
  constructor(private apiService: ApiService, @Inject(MD_DIALOG_DATA) public data: any, private interactionService: InteractionService) {
    super();
  }

  ngOnInit() {
    this.currentLead = this.data && this.data.lead ? this.data.lead : null;
    if (this.currentLead) {
    //  this.apiService.getLead(this.currentLead.id).pipe(this.getTakeUntil()).subscribe(leadDetail => {
    //     this.currentLead = leadDetail.data;
    //   });
      if(this.isJSON(this.currentLead.detail)){
        let leadDetails = JSON.parse(this.currentLead.detail);
        if (leadDetails != undefined) {
          this.leadDetails = leadDetails;
        }
      }
    } else {
      this.initializeFormGroup();
    }
    this.setViewType();
  }

  getViewType = () => {
    if (this.isEditView) {
      return 'Edit Lead';
    }

    if (this.isDetailView) {
      return 'View Lead';
    }

    if (this.isCreateView) {
      return 'Create Lead';
    }
  }

  initializeFormGroup = () => {
    this.leadFormGroup = new FormGroup({
      title: new FormControl(this.currentLead ? this.currentLead.title : null, [Validators.required]),
      description: new FormControl(this.currentLead ? this.currentLead.description : null, [Validators.required]),
      detail: new FormControl(this.currentLead ? this.currentLead.detail : null),
      case_id: new FormControl((this.currentLead && this.currentLead.case) ? this.currentLead.case.id : null),
    });
  }

  setViewType = () => {
    if (this.data.viewType === 'create') {
      this.isCreateView = true;
    }

    if (this.data.viewType === 'view') {
      this.isDetailView = true;
    }
  }

  clearErrorMessage = () => {
    this.successMessage = this.errorMessage = '';
  }

  submitForm = () => {
    this.shouldDisableButtons = true;
    this.clearErrorMessage();
    if (this.leadFormGroup.valid) {
      const leadPayload = this.leadFormGroup.value;
      if (this.isEditView) {
        leadPayload.id = this.data.lead.id;
        this.apiService.updatedLead(this.leadFormGroup.value).pipe(this.getTakeUntil())
          .subscribe(
            leadResponse => {
              this.shouldDisableButtons = false;
              if (leadResponse.status === 0 || leadResponse.status > 200) {
                this.errorMessage = getReadableErrorMessage(leadResponse);
                return;
              }
              this.isDetailView = true;
              this.isEditView = false;
              this.currentLead = leadResponse.data;
              this.interactionService.setShouldUpdateLeadList('lead_updated');
              this.successMessage = 'Lead Successfully updated';
            }
          );
      } else {
        this.apiService.createLeads(this.leadFormGroup.value).pipe(this.getTakeUntil())
          .subscribe(
            (leadCreateResponse) => {
              this.shouldDisableButtons = false;
              if (leadCreateResponse.status === 0 || leadCreateResponse.status > 200) {
                this.errorMessage = getReadableErrorMessage(leadCreateResponse);
                return;
              }
            this.interactionService.setShouldUpdateLeadList('lead_created');
            this.successMessage = 'Lead Successfully created';
          },
          );
      }
    } else {
      this.shouldDisableButtons = false;
      this.errorMessage = 'Please input all necessary fields.';
    }
  }

  editClicked = () => {
    this.clearErrorMessage();
    this.isEditView = true;
    this.isDetailView = false;
    this.initializeFormGroup();
  }

  closeLeadModal = () => this.interactionService.setModalClose(true);

  cancelClicked = () => {
    this.isEditView = false;
    this.isDetailView = true;
  }

  /**
   * Lead delete
   */
  deleteClicked(){
    this.apiService.deleteLead(this.currentLead.id).pipe(this.getTakeUntil()).subscribe(
      (response) => {
        if (response) {
          this.interactionService.setModalClose(true);
          this.interactionService.setShouldUpdateLeadList('lead_deleted');
          this.successMessage = 'Lead Successfully deleted';
          
        }
          // this.interactionService.setShouldUpdateCaseFeed(true);
    });
  }

  /**
   * Check if string is json parsable
   * @param str String
   * @returns true||false
   */
  isJSON = (str: string) => {
    if (typeof str === 'string'){
      try {
        JSON.parse(str)
        return true
      } catch(e){
      }
    }
    return false
  }

  openEmailLink(){
    window.open(this.leadDetails.url);
  }
  
}

