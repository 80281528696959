import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {InteractionService} from '../services/interaction.service';
import {ApiService} from '../services/api/api-service';
import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.css']
})
export class AddNoteComponent {
  note: string;
  errorMessage;
  isSuccessful = false;
  @Output() noteAdded: EventEmitter<object> = new EventEmitter<object>();

  constructor(
    public dialogRef: MdDialogRef<AddNoteComponent>,
    private interactionService: InteractionService,
    private apiService: ApiService,
    @Inject(MD_DIALOG_DATA) public data,
  ) {
  }


  submitNote = () => {
    if (this.note) {
      const formattedNotePayload = {
        note: this.note,
        activity_type: 'Note',
        parameters: [],
        tags: [],
        object: {
          id: '',
          value: ''
        },
        credential_id: this.data.credential,
      };
      this.isSuccessful = true;
      this.noteAdded.emit();
      this.apiService.saveActivity(formattedNotePayload).subscribe(activityResponse => {
        if (activityResponse.status === 1) {
          this.interactionService.setNoteAddedInfo(true);
        } else {
          this.errorMessage = 'Something went wrong. Please try again!';
        }
      });
    } else {
      this.errorMessage = 'Note is required';
    }
  }

  close() {
    this.dialogRef.close();
  }
}
