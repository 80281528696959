import { Component, ElementRef, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../../../reducers/index';
import { Store } from '@ngrx/store';
import { FeedsFetchVetsAction } from '../../../actions/feeds';
import { AppModule } from '../../../app.module';
import { truncateString } from '../../../utilities/utilities';

// Perfect Scrollbar declaration
declare var Ps;


@Component({
  selector: 'app-vets',
  templateUrl: './vets.component.html',
  styleUrls: ['./vets.component.css']
})
export class VetsComponent implements OnInit {

  scrollThreshholdPercent: number = 80;
  vets: Observable<any>;
  page: number = 1;
  fetching: boolean;
  firstVet;
  domain = AppModule.domain;

  @ViewChild('scrollDiv') scrollDiv: ElementRef;

  constructor(private store: Store<AppState>) {
    this.vets = this.store.select(x => {
      if (x.mainState.feeds.vets) {
        this.firstVet = x.mainState.feeds.vets[0];
      }
      return x.mainState.feeds.vets;
    });
    this.store.select(x => x.mainState.feeds.fetching).subscribe((fetching) => this.fetching = fetching);
  }

  ngOnInit() {
    this.store.dispatch(new FeedsFetchVetsAction({page: this.page++, pageSize: 10, init: true}));
  }

  onScrollDown() {
    this.store.dispatch(new FeedsFetchVetsAction({page: this.page++, pageSize: 10}));
  }

  ngOnDestroy() {
    // Destroy Perfect Scrollbar so it unsubscribes from the window scroll event
    Ps.destroy(this.scrollDiv.nativeElement);
  }

  ngAfterViewInit() {
    // Initialize Perfect Scrollbar
    Ps.initialize(this.scrollDiv.nativeElement);
  }

  handleScroll() {
    // If we're currently fetching results then just return
    if (this.fetching) {
      return;
    }

    // We hit the threshhold, fetch some more cases
    this.onScrollDown();
  }

  truncateData = (toTruncateString) => truncateString(toTruncateString, 28, true);
}
