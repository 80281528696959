import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {AppState} from "../../reducers/index";
import {Store} from "@ngrx/store";
import {
  OrderFinalizeSuccessAction, OrderGetInfoSuccessAction, OrderLoadClinicsAction,
  OrderSubmitAction
} from "../../actions/order";
import {IClinic} from "../../services/api/model/IClinic";
import {Observable} from "rxjs";
import {SliderComponent} from "../../components/slider/slider.component";
import {ActivatedRoute, Params, Router} from "@angular/router";

import * as $ from "jquery";
import {AppModule} from "../../app.module";
import {ApiService} from "../../services/api/api-service";
import {OrderInfo} from "../../services/api/model/Order";



declare var paypal;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  productIdOptions = [
  {
    "id": 1,
    "title": "Stifle Brace - $799"
  },
  {
    "id": 2,
    "title": "Hock Brace - $799"
  },
  {
    "id": 3,
    "title": "Carpal Brace - Jointed - $799"
  },
  {
    "id": 4,
    "title": "Carpal Brace - Non-Jointed - $799"
  },
];
  castingKitOptions = [
  {
    "value": "Yes",
    "title": "Yes"
  },
  {
    "value": "No",
    "title": "No"
  },
];

  shippingToOptions = [
  {
    "value": "owner",
    "title": "Owner"
  },
  {
    "value": "clinic/vet",
    "title": "Clinic/Vet"
  },
];

  shippingMethodOptions = [
  {
    "value": "2-day",
    "title": "2-Day - Free"
  },
  {
    "value": "overnight",
    "title": "Overnight - $79.99"
  },
  {
    "value": "intl",
    "title": "International - Please contact us for shipping options"
  },
];



  countries = [
  {
    "value": "",
    "title": "Choose"
  },
  {
    "value": "US",
    "title": "United States"
  },
  {
    "value": "AX",
    "title": "Aland Islands"
  },
  {
    "value": "AL",
    "title": "Albania"
  },
  {
    "value": "DZ",
    "title": "Algeria"
  },
  {
    "value": "AS",
    "title": "American Samoa"
  },
  {
    "value": "AD",
    "title": "Andorra"
  },
  {
    "value": "AI",
    "title": "Anguilla"
  },
  {
    "value": "AQ",
    "title": "Antarctica"
  },
  {
    "value": "AG",
    "title": "Antigua and Barbuda"
  },
  {
    "value": "AR",
    "title": "Argentina"
  },
  {
    "value": "AM",
    "title": "Armenia"
  },
  {
    "value": "AW",
    "title": "Aruba"
  },
  {
    "value": "AU",
    "title": "Australia"
  },
  {
    "value": "AT",
    "title": "Austria"
  },
  {
    "value": "AZ",
    "title": "Azerbaijan"
  },
  {
    "value": "BS",
    "title": "Bahamas"
  },
  {
    "value": "BH",
    "title": "Bahrain"
  },
  {
    "value": "BD",
    "title": "Bangladesh"
  },
  {
    "value": "BB",
    "title": "Barbados"
  },
  {
    "value": "BE",
    "title": "Belgium"
  },
  {
    "value": "BZ",
    "title": "Belize"
  },
  {
    "value": "BJ",
    "title": "Benin"
  },
  {
    "value": "BM",
    "title": "Bermuda"
  },
  {
    "value": "BT",
    "title": "Bhutan"
  },
  {
    "value": "BA",
    "title": "Bosnia-Herzegovina"
  },
  {
    "value": "BW",
    "title": "Botswana"
  },
  {
    "value": "BV",
    "title": "Bouvet Island"
  },
  {
    "value": "BR",
    "title": "Brazil"
  },
  {
    "value": "IO",
    "title": "British Indian Ocean Territory"
  },
  {
    "value": "BN",
    "title": "Brunei Darussalam"
  },
  {
    "value": "BG",
    "title": "Bulgaria"
  },
  {
    "value": "BF",
    "title": "Burkina Faso"
  },
  {
    "value": "CA",
    "title": "Canada"
  },
  {
    "value": "CV",
    "title": "Cape Verde"
  },
  {
    "value": "KY",
    "title": "Cayman Islands"
  },
  {
    "value": "CF",
    "title": "Central African Republic"
  },
  {
    "value": "CL",
    "title": "Chile"
  },
  {
    "value": "CN",
    "title": "China"
  },
  {
    "value": "CX",
    "title": "Christmas Island"
  },
  {
    "value": "CC",
    "title": "Cocos (Keeling) Islands"
  },
  {
    "value": "CO",
    "title": "Colombia"
  },
  {
    "value": "CK",
    "title": "Cook Islands"
  },
  {
    "value": "CR",
    "title": "Costa Rica"
  },
  {
    "value": "CY",
    "title": "Cyprus"
  },
  {
    "value": "CZ",
    "title": "Czech Republic"
  },
  {
    "value": "DK",
    "title": "Denmark"
  },
  {
    "value": "DJ",
    "title": "Djibouti"
  },
  {
    "value": "DM",
    "title": "Dominica"
  },
  {
    "value": "DO",
    "title": "Dominican Republic"
  },
  {
    "value": "EC",
    "title": "Ecuador"
  },
  {
    "value": "EG",
    "title": "Egypt"
  },
  {
    "value": "SV",
    "title": "El Salvador"
  },
  {
    "value": "EE",
    "title": "Estonia"
  },
  {
    "value": "FK",
    "title": "Falkland Islands (Malvinas)"
  },
  {
    "value": "FO",
    "title": "Faroe Islands"
  },
  {
    "value": "FJ",
    "title": "Fiji"
  },
  {
    "value": "FI",
    "title": "Finland"
  },
  {
    "value": "FR",
    "title": "France"
  },
  {
    "value": "GF",
    "title": "French Guiana"
  },
  {
    "value": "PF",
    "title": "French Polynesia"
  },
  {
    "value": "TF",
    "title": "French Southern Territories"
  },
  {
    "value": "GA",
    "title": "Gabon"
  },
  {
    "value": "GM",
    "title": "Gambia"
  },
  {
    "value": "GE",
    "title": "Georgia"
  },
  {
    "value": "DE",
    "title": "Germany"
  },
  {
    "value": "GH",
    "title": "Ghana"
  },
  {
    "value": "GI",
    "title": "Gibraltar"
  },
  {
    "value": "GR",
    "title": "Greece"
  },
  {
    "value": "GL",
    "title": "Greenland"
  },
  {
    "value": "GD",
    "title": "Grenada"
  },
  {
    "value": "GP",
    "title": "Guadeloupe"
  },
  {
    "value": "GU",
    "title": "Guam"
  },
  {
    "value": "CG",
    "title": "Guernsey"
  },
  {
    "value": "GY",
    "title": "Guyana"
  },
  {
    "value": "HM",
    "title": "Heard Island and Mcdonald Islands"
  },
  {
    "value": "VA",
    "title": "Holy see (Vatican City State)"
  },
  {
    "value": "HN",
    "title": "Honduras"
  },
  {
    "value": "HK",
    "title": "Hong Kong"
  },
  {
    "value": "HU",
    "title": "Hungary"
  },
  {
    "value": "IS",
    "title": "Iceland"
  },
  {
    "value": "IN",
    "title": "India"
  },
  {
    "value": "ID",
    "title": "Indonesia"
  },
  {
    "value": "IE",
    "title": "Ireland"
  },
  {
    "value": "IM",
    "title": "Isle of Man"
  },
  {
    "value": "IL",
    "title": "Israel"
  },
  {
    "value": "IT",
    "title": "Italy"
  },
  {
    "value": "JM",
    "title": "Jamaica"
  },
  {
    "value": "JP",
    "title": "Japan"
  },
  {
    "value": "JE",
    "title": "Jersey"
  },
  {
    "value": "JO",
    "title": "Jordan"
  },
  {
    "value": "KZ",
    "title": "Kazakhstan"
  },
  {
    "value": "KI",
    "title": "Kiribati"
  },
  {
    "value": "KR",
    "title": "Korea, Republic of"
  },
  {
    "value": "KW",
    "title": "Kuwait"
  },
  {
    "value": "KG",
    "title": "Kyrgyzstan"
  },
  {
    "value": "LV",
    "title": "Latvia"
  },
  {
    "value": "LS",
    "title": "Lesotho"
  },
  {
    "value": "LI",
    "title": "Liechtenstein"
  },
  {
    "value": "LT",
    "title": "Lithuania"
  },
  {
    "value": "LU",
    "title": "Luxembourg"
  },
  {
    "value": "MO",
    "title": "Macao"
  },
  {
    "value": "MK",
    "title": "Macedonia"
  },
  {
    "value": "MG",
    "title": "Madagascar"
  },
  {
    "value": "MW",
    "title": "Malawi"
  },
  {
    "value": "MY",
    "title": "Malaysia"
  },
  {
    "value": "MT",
    "title": "Malta"
  },
  {
    "value": "MH",
    "title": "Marshall Islands"
  },
  {
    "value": "MQ",
    "title": "Martinique"
  },
  {
    "value": "MR",
    "title": "Mauritania"
  },
  {
    "value": "MU",
    "title": "Mauritius"
  },
  {
    "value": "YT",
    "title": "Mayotte"
  },
  {
    "value": "MX",
    "title": "Mexico"
  },
  {
    "value": "FM",
    "title": "Micronesia, Federated States of"
  },
  {
    "value": "MD",
    "title": "Moldova, Republic of"
  },
  {
    "value": "MC",
    "title": "Monaco"
  },
  {
    "value": "MN",
    "title": "Mongolia"
  },
  {
    "value": "ME",
    "title": "Montenegro"
  },
  {
    "value": "MS",
    "title": "Montserrat"
  },
  {
    "value": "MA",
    "title": "Morocco"
  },
  {
    "value": "MZ",
    "title": "Mozambique"
  },
  {
    "value": "NA",
    "title": "Namibia"
  },
  {
    "value": "NR",
    "title": "Nauru"
  },
  {
    "value": "NP",
    "title": "Nepal"
  },
  {
    "value": "NL",
    "title": "Netherlands"
  },
  {
    "value": "AN",
    "title": "Netherlands Antilles"
  },
  {
    "value": "NC",
    "title": "New Caledonia"
  },
  {
    "value": "NZ",
    "title": "New Zealand"
  },
  {
    "value": "NI",
    "title": "Nicaragua"
  },
  {
    "value": "NE",
    "title": "Niger"
  },
  {
    "value": "NU",
    "title": "Niue"
  },
  {
    "value": "NF",
    "title": "Norfolk Island"
  },
  {
    "value": "MP",
    "title": "Northern Mariana Islands"
  },
  {
    "value": "NO",
    "title": "Norway"
  },
  {
    "value": "OM",
    "title": "Oman"
  },
  {
    "value": "PW",
    "title": "Palau"
  },
  {
    "value": "PS",
    "title": "Palestine"
  },
  {
    "value": "PA",
    "title": "Panama"
  },
  {
    "value": "PY",
    "title": "Paraguay"
  },
  {
    "value": "PE",
    "title": "Peru"
  },
  {
    "value": "PH",
    "title": "Philippines"
  },
  {
    "value": "PN",
    "title": "Pitcairn"
  },
  {
    "value": "PL",
    "title": "Poland"
  },
  {
    "value": "PT",
    "title": "Portugal"
  },
  {
    "value": "PR",
    "title": "Puerto Rico"
  },
  {
    "value": "QA",
    "title": "Qatar"
  },
  {
    "value": "RE",
    "title": "Reunion"
  },
  {
    "value": "RO",
    "title": "Romania"
  },
  {
    "value": "RU",
    "title": "Russian Federation"
  },
  {
    "value": "RW",
    "title": "Rwanda"
  },
  {
    "value": "SH",
    "title": "Saint Helena"
  },
  {
    "value": "KN",
    "title": "Saint Kitts and Nevis"
  },
  {
    "value": "LC",
    "title": "Saint Lucia"
  },
  {
    "value": "PM",
    "title": "Saint Pierre and Miquelon"
  },
  {
    "value": "VC",
    "title": "Saint Vincent and the Grenadines"
  },
  {
    "value": "WS",
    "title": "Samoa"
  },
  {
    "value": "SM",
    "title": "San Marino"
  },
  {
    "value": "ST",
    "title": "Sao Tome and Principe"
  },
  {
    "value": "SA",
    "title": "Saudi Arabia"
  },
  {
    "value": "SN",
    "title": "Senegal"
  },
  {
    "value": "RS",
    "title": "Serbia"
  },
  {
    "value": "SC",
    "title": "Seychelles"
  },
  {
    "value": "SG",
    "title": "Singapore"
  },
  {
    "value": "SK",
    "title": "Slovakia"
  },
  {
    "value": "SI",
    "title": "Slovenia"
  },
  {
    "value": "SB",
    "title": "Solomon Islands"
  },
  {
    "value": "ZA",
    "title": "South Africa"
  },
  {
    "value": "GS",
    "title": "South Georgia and the South Sandwich Islands"
  },
  {
    "value": "ES",
    "title": "Spain"
  },
  {
    "value": "SR",
    "title": "Suriname"
  },
  {
    "value": "SJ",
    "title": "Svalbard and Jan Mayen"
  },
  {
    "value": "SZ",
    "title": "Swaziland"
  },
  {
    "value": "SE",
    "title": "Sweden"
  },
  {
    "value": "CH",
    "title": "Switzerland"
  },
  {
    "value": "TW",
    "title": "Taiwan, Province of China"
  },
  {
    "value": "TZ",
    "title": "Tanzania, United Republic of"
  },
  {
    "value": "TH",
    "title": "Thailand"
  },
  {
    "value": "TL",
    "title": "Timor-Leste"
  },
  {
    "value": "TG",
    "title": "Togo"
  },
  {
    "value": "TK",
    "title": "Tokelau"
  },
  {
    "value": "TO",
    "title": "Tonga"
  },
  {
    "value": "TT",
    "title": "Trinidad and Tobago"
  },
  {
    "value": "TN",
    "title": "Tunisia"
  },
  {
    "value": "TR",
    "title": "Turkey"
  },
  {
    "value": "TM",
    "title": "Turkmenistan"
  },
  {
    "value": "TC",
    "title": "Turks and Caicos Islands"
  },
  {
    "value": "TV",
    "title": "Tuvalu"
  },
  {
    "value": "UG",
    "title": "Uganda"
  },
  {
    "value": "UA",
    "title": "Ukraine"
  },
  {
    "value": "AE",
    "title": "United Arab Emirates"
  },
  {
    "value": "GB",
    "title": "United Kingdom"
  },
  {
    "value": "UM",
    "title": "United States Minor Outlying Islands"
  },
  {
    "value": "UY",
    "title": "Uruguay"
  },
  {
    "value": "UZ",
    "title": "Uzbekistan"
  },
  {
    "value": "VU",
    "title": "Vanuatu"
  },
  {
    "value": "VE",
    "title": "Venezuela"
  },
  {
    "value": "VN",
    "title": "Viet nam"
  },
  {
    "value": "VG",
    "title": "Virgin Islands, British"
  },
  {
    "value": "VI",
    "title": "Virgin Islands, U.S."
  },
  {
    "value": "WF",
    "title": "Wallis and Futuna"
  },
  {
    "value": "EH",
    "title": "Western Sahara"
  },
  {
    "value": "ZM",
    "title": "Zambia"
  },
];

  states = [
  {
    "value": "",
    "title": "Choose"
  },
  {
    "value": "AL",
    "title": "Alabama"
  },
  {
    "value": "AK",
    "title": "Alaska"
  },
  {
    "value": "AZ",
    "title": "Arizona"
  },
  {
    "value": "AR",
    "title": "Arkansas"
  },
  {
    "value": "CA",
    "title": "California"
  },
  {
    "value": "CO",
    "title": "Colorado"
  },
  {
    "value": "CT",
    "title": "Connecticut"
  },
  {
    "value": "DC",
    "title": "District of Columbia"
  },
  {
    "value": "DE",
    "title": "Delaware"
  },
  {
    "value": "FL",
    "title": "Florida"
  },
  {
    "value": "GA",
    "title": "Georgia"
  },
  {
    "value": "HI",
    "title": "Hawaii"
  },
  {
    "value": "ID",
    "title": "Idaho"
  },
  {
    "value": "IL",
    "title": "Illinois"
  },
  {
    "value": "IN",
    "title": "Indiana"
  },
  {
    "value": "IA",
    "title": "Iowa"
  },
  {
    "value": "KS",
    "title": "Kansas"
  },
  {
    "value": "KY",
    "title": "Kentucky"
  },
  {
    "value": "LA",
    "title": "Louisiana"
  },
  {
    "value": "ME",
    "title": "Maine"
  },
  {
    "value": "MD",
    "title": "Maryland"
  },
  {
    "value": "MA",
    "title": "Massachusetts"
  },
  {
    "value": "MI",
    "title": "Michigan"
  },
  {
    "value": "MN",
    "title": "Minnesota"
  },
  {
    "value": "MS",
    "title": "Mississippi"
  },
  {
    "value": "MO",
    "title": "Missouri"
  },
  {
    "value": "MT",
    "title": "Montana"
  },
  {
    "value": "NE",
    "title": "Nebraska"
  },
  {
    "value": "NV",
    "title": "Nevada"
  },
  {
    "value": "NH",
    "title": "New Hampshire"
  },
  {
    "value": "NJ",
    "title": "New Jersey"
  },
  {
    "value": "NM",
    "title": "New Mexico"
  },
  {
    "value": "NY",
    "title": "New York"
  },
  {
    "value": "NC",
    "title": "North Carolina"
  },
  {
    "value": "ND",
    "title": "North Dakota"
  },
  {
    "value": "OH",
    "title": "Ohio"
  },
  {
    "value": "OK",
    "title": "Oklahoma"
  },
  {
    "value": "OR",
    "title": "Oregon"
  },
  {
    "value": "PA",
    "title": "Pennsylvania"
  },
  {
    "value": "RI",
    "title": "Rhode Island"
  },
  {
    "value": "SC",
    "title": "South Carolina"
  },
  {
    "value": "SD",
    "title": "South Dakota"
  },
  {
    "value": "TN",
    "title": "Tennessee"
  },
  {
    "value": "TX",
    "title": "Texas"
  },
  {
    "value": "UT",
    "title": "Utah"
  },
  {
    "value": "VT",
    "title": "Vermont"
  },
  {
    "value": "VA",
    "title": "Virginia"
  },
  {
    "value": "WA",
    "title": "Washington"
  },
  {
    "value": "WV",
    "title": "West Virginia"
  },
  {
    "value": "WI",
    "title": "Wisconsin"
  },
  {
    "value": "WY",
    "title": "Wyoming"
  },
  {
    "value": " ",
    "title": "--"
  },
  {
    "value": "AB",
    "title": "Alberta"
  },
  {
    "value": "BC",
    "title": "British Columbia"
  },
  {
    "value": "MB",
    "title": "Manitoba"
  },
  {
    "value": "NB",
    "title": "New Brunswick"
  },
  {
    "value": "NL",
    "title": "Newfoundland and Labrador"
  },
  {
    "value": "NT",
    "title": "Northwest Territories"
  },
  {
    "value": "NS",
    "title": "Nova Scotia"
  },
  {
    "value": "NU",
    "title": "Nunavut"
  },
  {
    "value": "ON",
    "title": "Ontario"
  },
  {
    "value": "PE",
    "title": "Prince Edward Island"
  },
  {
    "value": "QC",
    "title": "Quebec"
  },
  {
    "value": "SK",
    "title": "Saskatchewan"
  },
  {
    "value": "YT",
    "title": "Yukon Territory"
  },
];

  order: Order;
  Veterinarian = new Veterinarian();
  orderForm : FormGroup;
  dogForm : FormGroup;
  vetForm: FormGroup;
  formGroups: FormGroup[] = [];
  _agreement: boolean = false;

  set agreement(val: any) {
    this._agreement = val;
    this.flash();
  }

  get agreement() {
    return this._agreement;
  }

  completedOrder: Observable<any>;
  clinicName: string;
  product: string;

  checked: boolean;

  orderId: string;
  checkoutId: string;
  orderName: string;
  orderInfo: OrderInfo;

  @ViewChild("flasher") flasher: any;

  constructor(private store: Store<AppState>, fb: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private apiService: ApiService) {

    this.orderForm = fb.group({
      order_id: 0,
      checkout_id: 0,
      order_notes: "",
      source_of_information: "",
      ship_to_vet: false,
      opt: true,
    });

    this.store.select(x=>x.mainState.order.order).subscribe((info: any) => {

      if (info == null)
        return;

      info.id = this.orderName;
      info.productName = this.product;


      if (info.status == "complete") {
        var data = Object.assign({}, this.orderInfo, info);
        console.log(data);
        this.store.dispatch(new OrderGetInfoSuccessAction(data));
        // router.navigate(['/thank-you-order']);
      }
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.orderId = params['order'];
      this.checkoutId = params['checkout'];
      this.orderName = params['orderName'];
      this.product = params['product'];


      this.orderForm.patchValue({checkout_id: this.checkoutId});
      this.orderForm.patchValue({order_id: this.orderId});

      if (!this.checked)
      {
        this.checked = true;
        this.apiService.getOrderInfo(this.orderId, this.checkoutId).map((response) => {
          response.id = this.orderName;
          response.productName = this.product;
          this.orderInfo = response;
          this.store.dispatch(new OrderGetInfoSuccessAction(response));

          if (response.is_dog_vet_filled) {
            // router.navigate(['/thank-you-order']);
          }

        }).subscribe(() => console.log("Done"));
      }
    });



    this.dogForm = fb.group({
      name: [null, [Validators.required]],
      breed: [null, Validators.required],
      age: [null, Validators.required],
      weight: [null, Validators.required]
    });

    this.vetForm = fb.group({
      name: [null, Validators.required],
      zipcode: [null, Validators.required],
    doctor_name: [null, Validators.required],
    email: [null, [Validators.required, this.emailValidator()]],
    address: [null, Validators.required],
    city: [null, Validators.required],
    state: [null, Validators.required],
    country: [null, Validators.required],
    phone: [null, [Validators.required, this.phoneValidator()]],

  });


    this.formGroups.push(this.dogForm);
    this.formGroups.push(this.vetForm);
    this.formGroups.push(this.orderForm);

    this.vetForm.valueChanges.subscribe((x) => {
      if (x.name)
      {
        if (x.name.length >= 3 && x.name != this.lastClinic && !this.selectedClinic)
        {
          //this.store.dispatch(new OrderLoadClinicsAction(this.ownerForm.controls["zipCode"].value, x.name));
        }

        this.lastClinic = x.name;
      }
    });

    this.clinics = this.store.select((x) => x.mainState.order.clinics);
    this.completedOrder = this.store.select((x) => x.mainState.order.order);


    this.completedOrder.subscribe(x => {
      if (x == null)
        return;
    });
  }

  clinics: Observable<IClinic[]>;
  lastClinic: string;
  selectedClinic: boolean;

  @ViewChild('slider') slider: SliderComponent;

  display = (clinic) =>
  {
    if (!clinic || !clinic.name)
      return;

    this.selectedClinic = true;
    setTimeout(() => this.assignClinic(clinic));
    this.clinicName = clinic.name;
    return clinic.name;
  }

  updateClinicName(event)
  {
    this.clinicName = event;
  }

  assignClinic(clinic)
  {
    var newClinic = clinic;
    //this.vetForm.patchValue({name: newClinic.name});
    this.vetForm.patchValue({zipcode: newClinic.zipcode});
    this.vetForm.patchValue({doctor_name: newClinic.doctor_name});
    this.vetForm.patchValue({email: newClinic.email});
    this.vetForm.patchValue({address: newClinic.address});
    this.vetForm.patchValue({city: newClinic.city});
    this.vetForm.patchValue({state: newClinic.state});
    this.vetForm.patchValue({country: newClinic.country});
    this.vetForm.patchValue({phone: newClinic.phone});
    //setTimeout(() => this.vetForm.patchValue({name: newClinic.name}));
  }

  placeOrder()
  {
    this.ordered = true;
    this.order = this.orderForm.getRawValue();
    this.order.dog = this.dogForm.getRawValue();
    this.order.vet = this.vetForm.getRawValue();
    this.order.vet.name = $('#name')[0].value;
    var product = "";
    switch(this.order.product_id)
    {
      case 1:
        product = "Stifle Brace";
        break;
      case 2:
        product = "Hock Brace";
        break;
      case 3:
        product = "Carpal Brace (Jointed)";
        break;
      case 4:
        product = "Carpal Brace (Non Jointed)";
        break;
    }

    (<any>this.order).order_type = product;

    this.store.dispatch(new OrderSubmitAction(this.order))
  }

  slideNext()
  {
    this.slider.next();
  }
  slidePrev()
  {
    this.slider.prev();
  }


  emailValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      var regexp = /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/;
      var valid = regexp.test(control.value);
      return !valid ? {'emailPattern': {result: valid}} : null;
    }
  }

  phoneValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      var regexp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      var valid = regexp.test(control.value);
      return !valid ? {'phonePattern': {result: valid}} : null;
    }
  }

  ngOnInit()
  {
    this.flash();
  }
  ordered: boolean = false;
  timeout: any;

  flash()
  {

    var ref = <ElementRef>this.flasher;


    if (this.agreement)
      ref.nativeElement.style.border = "3px solid #5cb85c";
    else if (ref.nativeElement.style.border.indexOf('black') == -1)
      ref.nativeElement.style.border = "3px solid black";
    else
      ref.nativeElement.style.border = "3px solid red";

    if (this.timeout)
      clearInterval(this.timeout);

    this.timeout = setTimeout(() => this.flash(), 1000);

  }



  ngAfterViewInit() {

    console.log(this.flasher);

    /*if (AppModule.domain.indexOf('dev'))
      console.log("Sandbox")

    paypal.Button.render({

      env: (AppModule.domain.indexOf('dev') != -1 ? 'sandbox' : 'production'), // sandbox | production

      // Show the buyer a 'Pay Now' button in the checkout flow
      commit: true,

      // payment() is called when the button is clicked
      payment: () => {
        // Set up a url on your server to create the payment
        if (!this.ordered)
          this.placeOrder();
        // Make a call to your server to set up the payment
        return new Promise((resolve, reject) => {
          this.completedOrder.subscribe(x => {
            if (x == null)
              return;

            this.orderId = x.id;
            this.ordered = true;
            resolve(x.txid);
          })
        }).then(x => {
          return x;
        });
      },

      // onAuthorize() is called when the buyer approves the payment
      onAuthorize: (data, actions) => {

        // Set up a url on your server to execute the payment
        var EXECUTE_URL = AppModule.domain + '/api/payment/execute';

        // Set up the data you need to pass to your server
        var data = <any>{
          paymentId: data.paymentID,
          payerId: data.payerID,
          orderId: this.orderId
        };

        // Make a call to your server to execute the payment
        return paypal.request.post(EXECUTE_URL, data)
          .then((res) => {
            paypal.request.post(AppModule.domain + "/api/order/complete", {order_id: this.orderId, status: 1}).then(x => {
              this.store.dispatch(new OrderFinalizeSuccessAction(x.data));
              this.router.navigate(['/thank-you-order']);
            })
          });
      }

    }, '#paypal-button-container');*/
  }

  isFormValid = (section: any) =>
  {
    return this.formGroups[section].valid;
  }

  isInvalid(index: number, field: string, error?: string)
  {
    if (!this.formGroups[index].controls[field].errors)
      return false;

    if (error && this.formGroups[index].controls[field].errors && this.formGroups[index].controls[field].errors[error])
      return true;
    else if (error)
      return false;

    return Object.keys(
      this.formGroups[index].controls[field].errors).length == 1
      && Object.keys(this.formGroups[index].controls[field].errors)
        .find(x => x == 'required' || x == 'owner') &&
      this.formGroups[index].controls[field].invalid &&
      (this.formGroups[index].controls[field].dirty || this.formGroups[index].controls[field].touched
      );
  }
}

export class Dog {
  name = "";
  breed = "";
  age = "";
  weight = "";
}
export class Order {
  order_id = 0;
  product_id = 1;
  casting_kit = "Yes";
  shipping_to = 'clinic/vet';
  shipping_method = "2-day";
  coupon_code = "";
  order_notes = "";
  source_of_information = "";
  owner: Owner;
  dog: Dog;
  vet: Veterinarian;
  opt: boolean;
}

export class Owner {
  name = "";
  email = "";
  address = "";
  city = "";
  state = "";
  zipCode = "";
  country = "";
  phone = "";
}
export class Veterinarian {
  name = "";
  doctor_name = "";
  email = "";
  address = "";
  city = "";
  state = "";
  zipcode = "";
  country = "";
  phone = "";
}
