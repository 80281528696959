import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {


  navs = [
    {
      id: 'Save Time'
    },
    {
      id: 'Braces'
    },
    {
      id: 'Casting Kit'
    },
    {
      id: 'Payment Plan'
    }

  ]


  navsn = this.navs.map(x => x.id);


  constructor() { }

  ngOnInit() {
  }

}
