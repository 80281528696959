
import {filter} from 'rxjs/operators';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppState } from '../../reducers/index';
import { Store } from '@ngrx/store';
import { AppChangeHeaderStateAction, AppContactPageAction, AppContactButtonAction } from '../../actions/app';
import { MdDialog } from '@angular/material';
import { LoginFormComponent } from 'app/components/login-form/login-form.component';
import { AuthLogoutAction } from '../../actions/auth';
import { NavigationStart, Router } from '@angular/router';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { AppModule } from 'app/app.module';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  mobileMenu: boolean = false;
  navs: any;
  userToken: string = null;
  lastScroll: number = -1;
  hidden: boolean;
  privileges: any;
  routename : string;
  user:any;
  domain = AppModule.domain;


  @HostListener("window:scroll", [])
  onWindowScroll() {
    // Get the current scroll location
    var top = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;

    // If the current scroll location is greater than the last scroll location, then the user scrolled down
    // If the user scrolled down, hide the nav-menu
    // If not, show it
    var hide = top > this.lastScroll && this.lastScroll != -1;

    if (this.hidden == hide) {
      // Update the known scroll location to the current scroll location
      this.lastScroll = top;
      return;
    }

    // Dispatch the action to the store to initiate a state change in the application
    this.store.dispatch(new AppChangeHeaderStateAction(hide));

    // Update the known scroll location to the current scroll location
    this.lastScroll = top;
  }

  constructor(@Inject(DOCUMENT) private document: any, private store: Store<AppState>, private dialog: MdDialog, public router: Router) {
    this.store.select(x => x.mainState.app.hideHeader).subscribe((hide) => this.hidden = hide)

    this.store.select(x => x.mainState.app.navs).subscribe(x => this.navs = x);
    this.store.select(x => x.mainState.auth).subscribe(x => {
      this.userToken = x.userToken;
      this.privileges = x.privilege;
      this.user = x;
    });
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.mobileMenu = false;
      });
      const windowLocationPathName = window.location.pathname;
      if (windowLocationPathName == "/cases") {
        this.routename = 'cases';
      } else {
        this.routename = 'case-manager';
      }
  }

  Login() {
    this.dialog.open(LoginFormComponent);
  }

  Logout() {
    this.store.dispatch(new AuthLogoutAction(null));
    this.router.navigate(['login']);
  }

  openContactUs(event: any, contact: string) {
    event.stopPropagation();

    if ( contact != 'button')
      this.store.dispatch(new AppContactPageAction(null));
    else
      this.store.dispatch(new AppContactButtonAction(null));

    this.dialog.open(ContactFormComponent);
    //window.open("https://calendly.com/goherogo/free-brace-consult?month=2019-10");
  }

  loginButtonClicked = (event): void => {
    event.preventDefault();
    this.router.navigateByUrl(`${location.href}/login`).then();
  }

  switchPage(page){
    this.routename = page;
  }
}
