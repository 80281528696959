import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api/api-service';
import { HasSubscriptions } from '../directives/HasSubscriptions';
import { InteractionService } from '../services/interaction.service';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.css']
})
export class AddTagComponent extends HasSubscriptions {
  tagName;
  errorMessage = '';
  constructor(private apiService: ApiService, private interactionService: InteractionService) {
    super();
  }

  submit = () => {
    if (this.tagName) {
      const formattedCategoryPayload = {name: this.tagName};
      this.apiService.addCategory(formattedCategoryPayload).pipe(this.getTakeUntil()).subscribe((tagResponse) => {
        this.interactionService.setTagAddedInfo({action: 'added', tag: tagResponse});
      });
    } else {
      this.errorMessage = 'Tag Name is required.';
      this.resetErrorMessage();
    }
  }

  cancel = () => this.interactionService.setTagAddedInfo({action: 'cancelled'});

  resetErrorMessage = () => {
    setTimeout(() => {
      this.errorMessage = '';
    }, 3000);
  }
}
