import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';

declare var wplSlide;


@Component({
  selector: 'app-knee-brace',
  templateUrl: './knee-brace.component.html',
  styleUrls: ['./knee-brace.component.scss']
})
export class KneeBraceComponent implements OnInit {

  pageInfo: any;
  constructor()
  {
    this.pageInfo = {
      one: {
        title: 'ACL Tear?',
        text: 'Known as a CCL tear in dogs, cruciate ligament injuries allow a bad motion called the cranial drawer.  From the second your dog tears their ACL, three things happen. 1. muscles start to atrophy (get weaker and smaller), 2. arthritis formation starts, and 3 you have 60% chance to tear the other ACL.  All of these are helped by supporting your dogs leg to start weight bearing again.',
        photo: 'RobPossible-Dog-Knee-Brace-Yoga.jpg'
      },
      two: {
        title: 'When can dog knee braces be used?',
        blocks: [
          {
            title: 'Conservative Management',
            text: 'When surgery is not an option'
          },
          {
            title: 'Post-Surgical',
            text: 'Protect the surgery for best long term outcomes'
          },
          {
            title: 'Physical Therapy',
            text: 'Rehabilitation keeps the leg strong'
          },
        ]
      },
      three: {
        title: 'How does the brace work?',
        text: `When your veterinarian does a cranial drawer test they try to shift the bones to see if the ligament is damaged. (Red Arrows) The Hero Brace is the only brace to provide plastic in the opposite postitions to counter that motion. (Green Arrows) The mechanics are a bit more complicated involving a "force couple," but these are the necessary forces to support your dog's leg.`,
        photo: '1492513394.jpg'
      }
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit()
  {
    new wplSlide($('.wplSlide')[0], {
      autoRestart: true,
      auto: 5000,
    });

  }

}
