import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-veterinarian-success-stories',
  templateUrl: './veterinarian-success-stories.component.html',
  styleUrls: ['./veterinarian-success-stories.component.scss']
})
export class VeterinarianSuccessStoriesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
