import { Component, Inject, OnInit } from '@angular/core';
import { InteractionService } from '../services/interaction.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api/api-service';
import { HasSubscriptions } from '../directives/HasSubscriptions';
import { MD_DIALOG_DATA } from '@angular/material';
import { getReadableErrorMessage, truncateString } from '../utilities/utilities';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent extends HasSubscriptions implements OnInit {
  errorMessage = '';
  successMessage = '';
  taskFormGroup: FormGroup;
  availableCases = [];
  availableCollaborators = [];
  availableOwners = [];
  relatedToSettings = {
    singleSelection: true,
    text: 'Select Related to',
    enableSearchFilter: true,
    classes: 'task-form custom-class',
    labelKey: 'name',
  };
  taskDetail: {};
  isDetailView = false;

  collaboratorSettings: any = {
    text: 'Select Collaborators',
    enableSearchFilter: true,
    enableCheckAll: false,
    classes: 'task-form custom-class',
    labelKey: 'name',
  };

  personResponsibleSettings = {
    text: 'Select Person',
    singleSelection: true,
    enableSearchFilter: true,
    classes: 'task-form custom-class',
    labelKey: 'name',
  };

  constructor(
    @Inject(MD_DIALOG_DATA) public data: any,
    private interactionService: InteractionService,
    private apiService: ApiService
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.taskDetail) {
      this.apiService.getTask(this.data.taskDetail.id).pipe(this.getTakeUntil())
        .subscribe(taskDetail => {
          this.isDetailView = true;
          this.initializeFormGroup(taskDetail.data);
        });
    } else {
      this.initializeFormGroup();
    }
    this.setTaskRelatedData();
  }

  setTaskRelatedData = () => {
    this.taskDetail = this.data.taskDetail;
    this.availableCases = this.data.availableCases;
    this.availableOwners = this.data.availableOwners;
    this.availableCollaborators = this.data.availableCollaborators;
  }

  initializeFormGroup = (taskData = null) => {
    this.taskFormGroup = new FormGroup({
      due_date: new FormControl(taskData ? taskData.due_date.split('T')[0] : null, Validators.required),
      time: new FormControl(this.getFormattedTime(taskData), Validators.required),
      name: new FormControl(taskData ? taskData.name : null, Validators.required),
      description: new FormControl(taskData ? taskData.description : null),
      is_completed: new FormControl(taskData ? taskData.is_completed === 'Yes' : false),
      archived: new FormControl(taskData ? taskData.archived : false),
      assignee_id: new FormControl(
        (taskData && taskData.assignee) ? [{id: taskData.assignee.id, name: this.getAssigneeName(taskData)}] : null
      ),
      type_id: new FormControl((taskData && taskData.case) ? [{
        id: taskData.case.id,
        name: this.getFormattedCaseName(taskData.case)
      }] : null, Validators.required),
      collaborators: new FormControl(taskData ? taskData.collaborators.map(collaborator => {
        collaborator.name = truncateString(collaborator.username, 12);
        return collaborator;
      }) : null),
    });
  }

  getFormattedCaseName = (caseData: any): string => {
    if (caseData.dog_name) {
      return truncateString(`${caseData.order_id}-${caseData.dog_name}-${caseData.owner_last_name}`, 18);
    }
    return truncateString(`${caseData.order_id}-${caseData.owner_last_name}`, 18);
  }

  getFormattedTime = (taskData) => {
    if (taskData && taskData.due_date) {
      try {
        const timeArray = taskData.due_date.split('T')[1].split(':');
        return `${timeArray[0]}:${timeArray[1]}`;
      } catch (e) {
        this.setMessage({error: 'Something went wrong while parsing date.'});
      }
    }
    return null;
  }

  getAssigneeName = (taskData) => {
    if (taskData && taskData.assignee) {
      return truncateString(taskData.assignee.full_name ? taskData.assignee.full_name : taskData.assignee.username, 12);
    }
    return null;
  }

  cancelled = () => this.interactionService.setModalClose(true);

  resetErrorMessage = () => setTimeout(() => this.errorMessage = this.successMessage = '', 4000);

  submit = () => {
    if (this.taskFormGroup.valid) {
      const taskPayload = this.taskFormGroup.value;
      const taskPayloadTime = taskPayload.time || '00:00';
      taskPayload.due_date = taskPayload.due_date ? taskPayload.due_date + 'T' + taskPayloadTime : null;
      taskPayload.type_name = 'cases';
      taskPayload.is_completed = taskPayload.is_completed ? 'Yes' : 'No';
      taskPayload.assignee_id = taskPayload.assignee_id && taskPayload.assignee_id[0] ? taskPayload.assignee_id[0].id : null;
      taskPayload.type_id = taskPayload.type_id ? taskPayload.type_id[0].id : null;
      taskPayload.collaborators = taskPayload.collaborators ? taskPayload.collaborators.map(collaborator => collaborator.id) : null;
      if (this.data.taskDetail) {
        this.apiService.updateTask(this.data.taskDetail.id, taskPayload)
          .pipe(this.getTakeUntil()).subscribe((taskUpdateResponse) => {
          if (taskUpdateResponse.status === 1) {
            this.interactionService.setTaskAdded('updated');
            this.successMessage = 'Task Updated successfully.';
          } else {
            this.errorMessage = getReadableErrorMessage(taskUpdateResponse);
          }
        });
      } else {
        this.apiService.saveTask(taskPayload).pipe(this.getTakeUntil()).subscribe(taskData => {
          if (taskData.status === 1) {
            this.interactionService.setTaskAdded('added');
          } else {
          }
        });
      }
    } else {
      this.errorMessage = 'Please input all necessary fields.';
    }
    this.resetErrorMessage();
  }

  enableEdit = () => {
    this.isDetailView = false;
  }

  setMessage = (messageObject) => {
    if (messageObject.error) {
      this.errorMessage = messageObject.error;
    }
    if (messageObject.success) {
      this.successMessage = messageObject.success;
    }
    this.resetErrorMessage();
  }
}
