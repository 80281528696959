import { Component, Inject, OnInit } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { InteractionService } from '../services/interaction.service';
import { ApiService } from '../services/api/api-service';
import { truncateString } from '../utilities/utilities';
import { HasSubscriptions } from '../directives/HasSubscriptions';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-case-merge-popup',
  templateUrl: './case-merge-popup.component.html',
  styleUrls: ['./case-merge-popup.component.css']
})
export class CaseMergePopupComponent implements OnInit {
  getTakeUntil(): any {
    throw new Error('Method not implemented.');
  }

  allCases = [];
  filteredcases = [];
  selectedcaseIds = [];
  selectedcases = [];
  caseInput = null;
  iscaseLoading = true;
  timeout: any;
  currentCase:any;
  mergedCaseID=[];
  mergedCases=[];
  isParentCase:boolean=true;
  parentCaseDetails:any;




  constructor(@Inject(MD_DIALOG_DATA) public data: any, public dialogRef: MdDialogRef<CaseMergePopupComponent>, private interactionService: InteractionService, private apiService: ApiService) {
  }

  ngOnInit() {
    this.data.currentCase.subscribe(data=>{this.currentCase = data.case; })
    if (this.currentCase.merged_case_id) {
      if (this.currentCase.merged_case_id == 1) {
        this.getMergedCase();
      } else {
        this.isParentCase = false;
        this.getParentCaseDetails();
      }
    }

  }

  actionButtonClicked = (actionType) => {
    if (actionType === 'cancel') {
      this.interactionService.setModalClose(true);
    }

    if (actionType === 'merge') {
      let payload_data = {parent_case_id: this.currentCase.id,to_be_merged_case_ids: this.selectedcaseIds};
      this.apiService.mergeCases(payload_data).subscribe(res => {
        this.interactionService.setCaseMerged(true);
      });
    }
  }

  caseClicked = (caseSelected) => {
    this.selectedcases = [];
    if (this.selectedcaseIds.includes(caseSelected.id)) {
      this.selectedcaseIds.splice(this.selectedcaseIds.indexOf(caseSelected.id), 1);
    } else {
      this.selectedcaseIds.push(caseSelected.id);
    }
    this.selectedcaseIds.forEach(caseId => {
      this.selectedcases.push(this.filteredcases.find(singlecase => singlecase.id === caseId));
    });
  }

  resetSelectedcases = () => {
    this.selectedcases = [];
    this.selectedcaseIds = [];
  }

  truncateString = (stringToBeTruncated, maxCharacters = 20): string => truncateString(stringToBeTruncated, maxCharacters);

   caseInputChanged = (updatedSearchText): void => {
    clearTimeout(this.timeout);
    if (updatedSearchText.length != 0) {
      this.resetSelectedcases();
      this.timeout = setTimeout(() => {
        const formattedPayload = {q:updatedSearchText};
        this.apiService.feedSearch(formattedPayload).subscribe((caseDataResult) => {
          if (caseDataResult.data) {
            if (caseDataResult.data.values.length !=0) {
              this.filteredcases = caseDataResult.data.values
              .filter((cases) =>  this.mergedCaseID.indexOf(cases.id) === -1 && cases.id != this.currentCase.id)
              .map((cases) => {
                const formattedCase: any = cases;
                formattedCase.dog_name = cases.dog_name || '';
                formattedCase.order_id = cases.order_id || '';
                formattedCase.status = cases.status || '';
                return formattedCase;
              });
            }
          } else {
            this.filteredcases = [];
          }
        });
      }, 500);
    } else {
      this.filteredcases = [];
    }
  }

  /**
   * List all merged cases
   */
  getMergedCase(){
    this.apiService.fetchMergedCases(this.currentCase.id).subscribe(async (response)=>{
      if (response) {
        this.mergedCaseID = response;
        this.isParentCase = true;
        this.mergedCaseID.forEach((caseID,index) => {
          this.apiService.fetchFeed(caseID).subscribe(res=>{
            if(res.data){
              this.mergedCases.push(res.data.case);
            }
          })
        });
      }
    })
 
  }

  /** fetch parent case details if case is merged */
  getParentCaseDetails(){
      this.apiService.fetchFeed(this.currentCase.merged_case_id).subscribe(res=>{
        if(res.data){
          this.parentCaseDetails = res.data;
          this.isParentCase = false;
        }
      })
      
  }

  getFormattedCaseName = () => {
    if (this.parentCaseDetails.dog) {
      return truncateString(`${this.parentCaseDetails.case.order_id} ${this.parentCaseDetails.dog.name} ${this.parentCaseDetails.owner.last_name}`, 30);
    } else {
      return truncateString(`${this.parentCaseDetails.case.order_id} ${this.parentCaseDetails.owner.last_name}`, 30);

    }
  }

  close() {
    this.dialogRef.close();
  }

}
