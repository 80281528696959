
import {mergeMap, map } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import {
  ActionTypes,
  FeedsFetchAction,
  FeedsFetchSuccessAction,
  FeedsFetchFailureAction,
  FeedsFetchFeedAction,
  FeedsFetchFeedFailureAction,
  FeedsFetchFeedSuccessAction,
  FeedsFetchDogSuccessAction,
  FeedsFetchDogFailureAction,
  FeedsFetchDogAction,
  FeedsFetchVetsAction,
  FeedsFetchVetsFailureAction,
  FeedsFetchVetsSuccessAction,
  FeedsFetchVetAction,
  FeedsFetchVetFailureAction,
  FeedsFetchVetSuccessAction,
  FeedsSaveFeedAction,
  FeedsSaveFeedSuccessAction,
  FeedsSaveFeedFailureAction,
  FeedsSaveVetAction,
  FeedsSaveVetFailureAction,
  FeedsSaveVetSuccessAction,
  FeedsAddTaskActivityAction,
  FeedsAddTaskActivityFailureAction,
  FeedsAddTaskActivitySuccessAction,
  FeedsFetchTasksAction,
  FeedsFetchTasksSuccessAction,
  FeedsCompleteTaskAction,
  FeedsCompleteTaskSuccessAction,
  FeedsSearchAction,
  FeedsSearchSuccessAction,
  FeedsFetchOrderAction,
  FeedsFetchOrderSuccessAction,
  FeedsFetchOrderFailureAction,
  FeedsFetchOwnerAction,
  FeedsFetchOwnerFailureAction,
  FeedsFetchOwnerSuccessAction,
  FeedsFetchActivitiesAction,
  FeedsFetchActivitiesSuccessAction,
  FeedsSaveActivityAction,
  FeedsSaveActivityFailureAction,
  FeedsSaveActivitySuccessAction,
  FeedsFetchTasksFailureAction,
  FeedsFetchCasesAction,
  FeedsFetchCasesSuccessAction,
  FeedsSaveDogAction,
  FeedsSaveDogSuccessAction,
  FeedsSaveDogFailureAction,
  FeedsFetchActivityCategorySuccessAction,
  FeedsFetchActivityCategoryFailureAction,
  ActiveFeedsFetchAction,
  ActiveFeedsFetchFailureAction,
  ActiveFeedsFetchSuccessAction
} from '../actions/feeds';
import { ApiService } from "../services/api/api-service";
import { Actions, Effect } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { FeedResponse } from "../services/api/model/FeedResponse";
import { ActivityResponse } from "../services/api/model/Activity";

@Injectable()
export class FeedService {

  @Effect()
  FetchFeeds: Observable<{}> = this.actions$
    .ofType(ActionTypes.Fetch).pipe(
    map((action: FeedsFetchAction) => action.payload),
    mergeMap((model: any) =>
      this._api.fetchFeeds(model.page, model.pageSize).map((response: FeedResponse) => {
        if (response.status != 1)
          return new FeedsFetchFailureAction(response.message);
        return new FeedsFetchSuccessAction(response);
      })),);

  @Effect()
  FetchActiveFeeds: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchActive).pipe(
    map((action: ActiveFeedsFetchAction) => action.payload),
    mergeMap((model: any) =>
      this._api.fetchActiveFeeds(model.page, model.pageSize).map((response: FeedResponse) => {
        if (response.status != 1)
          return new ActiveFeedsFetchFailureAction(response.message);
        return new ActiveFeedsFetchSuccessAction(response);
      })),);

  @Effect()
  SaveFeed: Observable<{}> = this.actions$
    .ofType(ActionTypes.SaveFeed).pipe(
    map((action: FeedsSaveFeedAction) => action),
    mergeMap((model: any) => {
      return this._api.saveFeed(model.id, model.payload).pipe(
        map((response: FeedResponse) => {
          if (response.status != 1)
            return new FeedsSaveFeedFailureAction(response.message);
          return new FeedsSaveFeedSuccessAction(response);
        })
      )
    }),);

  @Effect()
  SaveVet: Observable<{}> = this.actions$
    .ofType(ActionTypes.SaveVet).pipe(
    map((action: FeedsSaveVetAction) => action.payload),
    mergeMap((model: any) =>
      this._api.saveVet(model).pipe(map((response: FeedResponse) => {
        if (response.status != 1)
          return new FeedsSaveVetFailureAction(response.message);
        return new FeedsSaveVetSuccessAction(response);
      }))),);

  @Effect()
  SaveDog: Observable<{}> = this.actions$
    .ofType(ActionTypes.SaveDog).pipe(
    map((action: FeedsSaveDogAction) => action.payload),
    mergeMap((model: any) => {
      return this._api.updateDogOnly(model.id, model).pipe(map((response: FeedResponse) => {
        if (response.status) {
          return new FeedsSaveDogSuccessAction(response);
        }
        return new FeedsSaveDogFailureAction(response.message);
      }));
      }
    ),);

  @Effect()
  FetchFeed: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchFeed).pipe(
    map((action: FeedsFetchFeedAction) => action.payload),
    mergeMap((id: number) =>
      this._api.fetchFeed(id).map((response) => {
        if (response.status === 1) {
          return new FeedsFetchFeedSuccessAction(response);
        }
        return new FeedsFetchFeedFailureAction(response.message);
      })),);

  @Effect()
  FetchDog: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchDog).pipe(
    map((action: FeedsFetchDogAction) => action.payload),
    mergeMap((id: number) =>
      this._api.fetchDog(id).map((response: FeedResponse) => {
        if (response.status != 1)
          return new FeedsFetchDogFailureAction(response.message);
        return new FeedsFetchDogSuccessAction(response);
      })),);

  @Effect()
  FetchVets: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchVets).pipe(
    map((action: FeedsFetchVetsAction) => action.payload),
    mergeMap((model: any) =>
      this._api.fetchVets(model.page, model.pageSize).map((response: FeedResponse) => {
        if (response.status != 1)
          return new FeedsFetchVetsFailureAction(response.message);
        return new FeedsFetchVetsSuccessAction(response);
      })),);

  @Effect()
  FetchVet: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchVet).pipe(
    map((action: FeedsFetchVetAction) => action.payload),
    mergeMap((id: number) =>
      this._api.fetchVet(id).map((response: FeedResponse) => {
        if (response.status != 1)
          return new FeedsFetchVetFailureAction(response.message);
        return new FeedsFetchVetSuccessAction(response);
      })),);

  @Effect()
  FetchTasks: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchTasks).pipe(
    map((action: FeedsFetchTasksAction) => action.payload),
    mergeMap((id: number) =>
      this._api.fetchTasks().map((response: any) => {
        if (response.message != 'success')
          return new FeedsFetchTasksFailureAction(response.message);
        return new FeedsFetchTasksSuccessAction(response);
      })),);

  @Effect()
  FetchCases: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchCases).pipe(
    map((action: FeedsFetchCasesAction) => action.payload),
    mergeMap((id: number) =>
      this._api.fetchFeedsWithoutPagination().map((response: any) => {
        if (response.status === 1) {
          return new FeedsFetchCasesSuccessAction(response);
        }

        return new FeedsFetchTasksFailureAction(response.message);
      })),);

  @Effect()
  AddActivity: Observable<{}> = this.actions$
    .ofType(ActionTypes.AddTaskActivity).pipe(
    map((action: FeedsAddTaskActivityAction) => action.payload),
    mergeMap((payload: any) =>
      this._api.addActivity(payload.id, payload.description).map((response: FeedResponse) => {
        return new FeedsAddTaskActivitySuccessAction(payload, response);
      })),);

  @Effect()
  CompleteTask: Observable<{}> = this.actions$
    .ofType(ActionTypes.CompleteTask).pipe(
    map((action: FeedsCompleteTaskAction) => action.payload),
    mergeMap((payload: any) =>
      this._api.completeTask(payload.id, payload.conclusion).map((response: FeedResponse) => {
        return new FeedsCompleteTaskSuccessAction(payload, response);
      })),);

  @Effect()
  Search: Observable<{}> = this.actions$
    .ofType(ActionTypes.Search).pipe(
    map((action: FeedsSearchAction) => action.payload),
    mergeMap((payload: any) =>
      this._api.feedSearch(payload).map((response: any) => {
        if (payload.object_type == 'Vet') {
            return new FeedsSearchSuccessAction(response);
        }else {
          if (typeof(response.data) != 'undefined'){
            return new FeedsSearchSuccessAction(response.data.values);
          }else {
            return new FeedsSearchSuccessAction([]);
          }
        }
        
      })),);

  @Effect()
  FetchOrder: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchOrder).pipe(
    map((action: FeedsFetchOrderAction) => action.payload),
    mergeMap((id: number) =>
      this._api.fetchOrder(id).map((response) => {
          if (response.status) {
              return new FeedsFetchOrderSuccessAction(response);
          }

          return new FeedsFetchOrderFailureAction(response.message);
      })),);

  @Effect()
  FetchOwner: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchOwner).pipe(
    map((action: FeedsFetchOwnerAction) => action.payload),
    mergeMap((id: number) =>
      this._api.fetchOwner(id).map((response) => {
        if (response.status) {
            return new FeedsFetchOwnerSuccessAction(response.data);
        }
        return new FeedsFetchOwnerFailureAction(response.message);
      })),);


  @Effect()
  FetchActivityCategory: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchActivityCategory).pipe(
    map((action: FeedsFetchActivitiesAction) => action.payload),
    mergeMap((id: number) =>
      this._api.fetchActivityCategories().pipe(map((response: any) => {
        if (response) {
          return new FeedsFetchActivityCategorySuccessAction(response);
        }
        return new FeedsFetchActivityCategoryFailureAction(response.message);
      }))),);


  @Effect()
  FetchActivities: Observable<{}> = this.actions$
    .ofType(ActionTypes.FetchActivities).pipe(
    map((action: FeedsFetchActivitiesAction) => action.payload),
    mergeMap((id: number) =>
      this._api.fetchActivities(id).map((response: ActivityResponse) => {
        return new FeedsFetchActivitiesSuccessAction(response);
      })),);

  @Effect()
  SaveActivity: Observable<{}> = this.actions$
    .ofType(ActionTypes.SaveActivity).pipe(
    map((action: FeedsSaveActivityAction) => action.payload),
    mergeMap((payload: any) =>
      this._api.saveActivity(payload).map((response: FeedResponse) => {
        if (response.status != 1)
          return new FeedsSaveActivityFailureAction(response.message);
        return new FeedsSaveActivitySuccessAction(response);
      })),);


  constructor(private actions$: Actions, private _api: ApiService) { }

}
