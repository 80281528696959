import {type} from 'ngrx-cli';
import {Action} from '@ngrx/store';

export const ActionTypes = {
    Fetch: type('[Feeds] Fetch'),
    FetchSuccess: type('[Feeds] FetchSuccess'),
    FetchFailure: type('[Feeds] FetchFailure'),
    FetchActive: type('[Feeds] FetchActive'),
    FetchActiveSuccess: type('[Feeds] FetchActiveSuccess'),
    FetchActiveFailure: type('[Feeds] FetchActiveFailure'),
    FetchFeed: type('[Feeds] FetchFeed'),
    FetchFeedSuccess: type('[Feeds] FetchFeedSuccess'),
    FetchFeedFailure: type('[Feeds] FetchFeedFailure'),
    FetchDog: type('[Feeds] FetchDog'),
    FetchDogSuccess: type('[Feeds] FetchDogSuccess'),
    FetchDogFailure: type('[Feeds] FetchDogFailure'),
    SaveDog: type('[Feeds] SaveDog'),
    SaveDogFailure: type('[Feeds] SaveDogFailure'),
    SaveDogSuccess: type('[Feeds] SaveDogSuccess'),
    FetchOrder: type('[Feeds] FetchOrder'),
    FetchOrderSuccess: type('[Feeds] FetchOrderSuccess'),
    FetchOrderFailure: type('[Feeds] FetchOrderFailure'),
    FetchVets: type('[Feeds] FetchVets'),
    FetchVetsSuccess: type('[Feeds] FetchVetsSuccess'),
    FetchVetsFailure: type('[Feeds] FetchVetsFailure'),
    FetchVet: type('[Feeds] FetchVet'),
    FetchVetSuccess: type('[Feeds] FetchVetSuccess'),
    FetchVetFailure: type('[Feeds] FetchVetFailure'),
    ClearFeed: type('[Feeds] ClearFeed'),
    SaveFeed: type('[Feeds] SaveFeed'),
    SaveFeedSuccess: type('[Feeds] SaveFeedSuccess'),
    SaveFeedFailure: type('[Feeds] SaveFeedFailure'),
    SaveVet: type('[Feeds] SaveVet'),
    SaveVetSuccess: type('[Feeds] SaveVetSuccess'),
    SaveVetFailure: type('[Feeds] SaveVetFailure'),
    FetchTasks: type('[Feeds] FetchTasks'),
    FetchCases: type('[Feeds] FetchCases'),
    FetchTasksSuccess: type('[Feeds] FetchTasksSuccess'),
    FetchCasesSuccess: type('[Feeds] FetchCasesSuccess'),
    FetchTasksFailure: type('[Feeds] FetchTasksFailure'),
    FetchCasesFailure: type('[Feeds] FetchCasesFailure'),
    AddTaskActivity: type('[Feeds] AddTaskActivity'),
    AddTaskActivitySuccess: type('[Feeds] AddTaskActivitySuccess'),
    AddTaskActivityFailure: type('[Feeds] AddTaskActivityFailure'),
    SetFilter: type('[Feeds] SetFilter'),
    CompleteTask: type('[Feeds] CompleteTask'),
    CompleteTaskSuccess: type('[Feeds] CompleteTaskSuccess'),
    CompleteTaskFailure: type('[Feeds] CompleteTaskFailure'),
    Search: type('[Feeds] Search'),
    SearchSuccess: type('[Feeds] Search Success'),
    ClearSearch: type('[Feeds] Clear Search'),
    SetSearchFilter: type('[Feeds] Set Search Filter'),
    FetchOwner: type('[Feeds] Fetch Owner'),
    FetchOwnerSuccess: type('[Feeds] Fetch Owner Success'),
    FetchOwnerFailure: type('[Feeds] Fetch Owner Failure'),

    FetchActivityCategory: type('[Feeds] Fetch Activity Category'),
    FetchActivityCategorySuccess: type('[Feeds] Fetch Activity Category Success'),
    FetchActivityCategoryFailure: type('[Feeds] Fetch Activity Category Failure'),

    FetchActivities: type('[Feeds] FetchActivities'),
    FetchActivitiesSuccess: type('[Feeds] FetchActivitiesSuccess'),
    SaveActivity: type('[Feeds] SaveActivity'),
    SaveActivitySuccess: type('[Feeds] SaveActivitySuccess'),
    SaveActivityFailure: type('[Feeds] SaveActivityFailure')
}

export class FeedsFetchAction implements Action {
    type = ActionTypes.Fetch;

    constructor(public payload: { page: number, pageSize: number, init?: boolean }) {
    }
}

export class FeedsFetchSuccessAction implements Action {
    type = ActionTypes.FetchSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchFailureAction implements Action {
    type = ActionTypes.FetchFailure;

    constructor(public payload: any) {
    }
}

export class ActiveFeedsFetchAction implements Action {
    type = ActionTypes.FetchActive;

    constructor(public payload: { page: number, pageSize: number, init?: boolean }) {
    }
}

export class ActiveFeedsFetchSuccessAction implements Action {
    type = ActionTypes.FetchActiveSuccess;

    constructor(public payload: any) {
    }
}

export class ActiveFeedsFetchFailureAction implements Action {
    type = ActionTypes.FetchActiveFailure;

    constructor(public payload: any) {
    }
}

export class FeedsFetchFeedAction implements Action {
    type = ActionTypes.FetchFeed;

    constructor(public payload: any) {
    }
}

export class FeedsFetchFeedSuccessAction implements Action {
    type = ActionTypes.FetchFeedSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchFeedFailureAction implements Action {
    type = ActionTypes.FetchFeedFailure;

    constructor(public payload: any) {
    }
}

export class FeedsFetchDogAction implements Action {
    type = ActionTypes.FetchDog;

    constructor(public payload: any) {
    }
}

export class FeedsFetchDogSuccessAction implements Action {
    type = ActionTypes.FetchDogSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchDogFailureAction implements Action {
    type = ActionTypes.FetchDogFailure;

    constructor(public payload: any) {
    }
}

export class FeedsSaveDogAction implements Action {
    type = ActionTypes.SaveDog;

    constructor(public payload: any) {
    }
}

export class FeedsSaveDogSuccessAction implements Action {
    type = ActionTypes.SaveDogSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsSaveDogFailureAction implements Action {
    type = ActionTypes.SaveDogFailure;

    constructor(public payload: any) {
    }
}

export class FeedsFetchVetsAction implements Action {
    type = ActionTypes.FetchVets;

    constructor(public payload: any) {
    }
}

export class FeedsFetchVetsSuccessAction implements Action {
    type = ActionTypes.FetchVetsSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchVetsFailureAction implements Action {
    type = ActionTypes.FetchVetsFailure;

    constructor(public payload: any) {
    }
}

export class FeedsFetchVetAction implements Action {
    type = ActionTypes.FetchVet;

    constructor(public payload: any) {
    }
}

export class FeedsFetchVetSuccessAction implements Action {
    type = ActionTypes.FetchVetSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchVetFailureAction implements Action {
    type = ActionTypes.FetchVetFailure;

    constructor(public payload: any) {
    }
}

export class FeedsClearFeedAction implements Action {
    type = ActionTypes.ClearFeed;

    constructor(public payload: any) {
    }
}

export class FeedsSaveFeedAction implements Action {
    type = ActionTypes.SaveFeed;

    constructor(public id: number, public payload: any) {
    }
}

export class FeedsSaveFeedSuccessAction implements Action {
    type = ActionTypes.SaveFeedSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsSaveFeedFailureAction implements Action {
    type = ActionTypes.SaveFeedFailure;

    constructor(public payload: any) {
    }
}

export class FeedsSaveVetAction implements Action {
    type = ActionTypes.SaveVet;

    constructor(public payload: any) {
    }
}

export class FeedsSaveVetSuccessAction implements Action {
    type = ActionTypes.SaveVetSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsSaveVetFailureAction implements Action {
    type = ActionTypes.SaveVetFailure;

    constructor(public payload: any) {
    }
}

export class FeedsFetchTasksAction implements Action {
    type = ActionTypes.FetchTasks;

    constructor(public payload: any) {
    }
}

export class FeedsFetchCasesAction implements Action {
    type = ActionTypes.FetchCases;

    constructor(public payload: any) {
    }
}

export class FeedsFetchTasksSuccessAction implements Action {
    type = ActionTypes.FetchTasksSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchCasesSuccessAction implements Action {
    type = ActionTypes.FetchCasesSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchCasesFailureAction implements Action {
    type = ActionTypes.FetchCasesFailure;

    constructor(public payload: any) {
    }
}

export class FeedsFetchTasksFailureAction implements Action {
    type = ActionTypes.FetchTasksFailure;

    constructor(public payload: any) {
    }
}

export class FeedsAddTaskActivityAction implements Action {
    type = ActionTypes.AddTaskActivity;

    constructor(public payload: any) {
    }
}

export class FeedsAddTaskActivitySuccessAction implements Action {
    type = ActionTypes.AddTaskActivitySuccess;

    constructor(public payload: any, public results: any) {
    }
}

export class FeedsAddTaskActivityFailureAction implements Action {
    type = ActionTypes.AddTaskActivityFailure;

    constructor(public payload: any) {
    }
}

export class FeedsCompleteTaskAction implements Action {
    type = ActionTypes.CompleteTask;

    constructor(public payload: any) {
    }
}

export class FeedsCompleteTaskSuccessAction implements Action {
    type = ActionTypes.CompleteTaskSuccess;

    constructor(public payload: any, public results: any) {
    }
}

export class FeedsCompleteTaskFailureAction implements Action {
    type = ActionTypes.CompleteTaskFailure;

    constructor(public payload: any) {
    }
}


export class FeedsSetFilterAction implements Action {
    type = ActionTypes.SetFilter;

    constructor(public payload: any, public thenBy: any) {
    }
}


export class FeedsSearchAction implements Action {
    type = ActionTypes.Search;

    constructor(public payload: any) {
    }
}

export class FeedsSearchSuccessAction implements Action {
    type = ActionTypes.SearchSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsClearSearchAction implements Action {
    type = ActionTypes.ClearSearch;

    constructor(public payload: any) {
    }
}

export class FeedsSetSearchFilterAction implements Action {
    type = ActionTypes.SetSearchFilter;

    constructor(public payload: any) {
    }
}

// todo rename this to case as this is giving case not order.
export class FeedsFetchOrderAction implements Action {
    type = ActionTypes.FetchOrder;

    constructor(public payload: any) {
    }
}

export class FeedsFetchOrderSuccessAction implements Action {
    type = ActionTypes.FetchOrderSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchOrderFailureAction implements Action {
    type = ActionTypes.FetchOrderFailure;

    constructor(public payload: any) {
    }
}

export class FeedsFetchOwnerAction implements Action {
    type = ActionTypes.FetchOwner;

    constructor(public payload: any) {
    }
}

export class FeedsFetchOwnerSuccessAction implements Action {
    type = ActionTypes.FetchOwnerSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchOwnerFailureAction implements Action {
    type = ActionTypes.FetchOwnerFailure;

    constructor(public payload: any) {
    }
}


export class FeedsFetchActivityCategoryAction implements Action {
    type = ActionTypes.FetchActivityCategory;

    constructor() {
    }
}

export class FeedsFetchActivityCategorySuccessAction implements Action {
    type = ActionTypes.FetchActivityCategorySuccess;

    constructor(public payload: any) {
    }
}

export class FeedsFetchActivityCategoryFailureAction implements Action {
    type = ActionTypes.FetchActivityCategoryFailure;

    constructor(public payload: any) {
    }
}


export class FeedsFetchActivitiesAction implements Action {
    type = ActionTypes.FetchActivities;

    constructor(public payload: any) {
    }
}

export class FeedsFetchActivitiesSuccessAction implements Action {
    type = ActionTypes.FetchActivitiesSuccess;

    constructor(public payload: any) {
    }
}

export class FeedsSaveActivityAction implements Action {
    type = ActionTypes.SaveActivity;

    constructor(public payload: any) {
    }
}

export class FeedsSaveActivitySuccessAction implements Action {
    type = ActionTypes.SaveActivitySuccess;

    constructor(public payload: any) {
    }
}

export class FeedsSaveActivityFailureAction implements Action {
    type = ActionTypes.SaveActivityFailure;

    constructor(public payload: any) {
    }
}

export type Actions =
    FeedsFetchOwnerAction
    | FeedsFetchOwnerSuccessAction
    | FeedsFetchOwnerFailureAction
    | FeedsFetchAction
    | FeedsFetchSuccessAction
    | FeedsFetchFailureAction
    | FeedsFetchFeedAction
    | FeedsFetchFeedSuccessAction
    | FeedsFetchFeedFailureAction
    | FeedsFetchDogAction
    | FeedsFetchDogSuccessAction
    | FeedsFetchDogFailureAction
    | FeedsFetchVetsAction
    | FeedsFetchVetsSuccessAction
    | FeedsFetchVetsFailureAction
    | FeedsFetchVetAction
    | FeedsFetchVetSuccessAction
    | FeedsFetchVetFailureAction
    | FeedsClearFeedAction
    | FeedsSaveFeedAction
    | FeedsSaveFeedSuccessAction
    | FeedsSaveFeedFailureAction
    | FeedsSaveVetAction
    | FeedsSaveVetSuccessAction
    | FeedsSaveVetFailureAction
    | FeedsFetchTasksAction
    | FeedsFetchTasksSuccessAction
    | FeedsFetchTasksFailureAction
    | FeedsFetchCasesAction
    | FeedsFetchCasesSuccessAction
    | FeedsFetchCasesFailureAction
    | FeedsAddTaskActivityAction
    | FeedsAddTaskActivitySuccessAction
    | FeedsAddTaskActivityFailureAction
    | FeedsSetFilterAction
    | FeedsCompleteTaskAction
    | FeedsCompleteTaskSuccessAction
    | FeedsCompleteTaskFailureAction
    | FeedsSearchAction
    | FeedsSearchSuccessAction
    | FeedsClearSearchAction
    | FeedsSetSearchFilterAction
    | FeedsFetchOrderAction
    | FeedsFetchOrderSuccessAction
    | FeedsFetchOrderFailureAction
    | FeedsFetchActivitiesAction
    | FeedsFetchActivitiesSuccessAction
    | FeedsSaveActivityAction
    | FeedsSaveActivityFailureAction
    | FeedsSaveActivitySuccessAction;
