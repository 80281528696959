import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MD_DIALOG_DATA } from '@angular/material';
import { InteractionService } from '../services/interaction.service';
import { ApiService } from '../services/api/api-service';
import { HasSubscriptions } from '../directives/HasSubscriptions';
import { capitalizeFirstLetter, getReadableErrorMessage } from '../utilities/utilities';

@Component({
  selector: 'app-shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.css']
})
export class ShippingFormComponent extends HasSubscriptions implements OnInit {
  shippingForm: FormGroup;
  actionMessage = '';
  isLoading = false;
  public errorMessages = [];
  public internalErrorMessage = '';
  public hideButtons = false;
  public showSubmitButton = true;
  public shippingTypes = [
    { slug: 'vet', label: 'Vet' },
    { slug: 'owner', label: 'Owner' },
    { slug: 'other', label: 'Other' },
    { slug: 'none', label: 'None' },
  ];

  public availableReturnLabels = [
    { slug: 'yes_printed', label: 'Yes Printed' },
    { slug: 'yes_emailed', label: 'Yes Emailed' },
    { slug: 'no', label: 'No'},
  ];

  public boxSizes = [
    { slug: 'small_8_4_4', label: 'Small 8*4*4' },
    { slug: 'med_12_6_6', label: 'Med 12*6*6' },
    { slug: 'large_18_6_6', label: 'Large 18*6*6' },
    { slug: 'envelope', label: 'Envelope' },
    { slug: 'other', label: 'Other' },
  ];

  public rates = [
    { slug: 'next_day', label: 'Next Day' },
    { slug: '2_day', label: '2 Day' },
    { slug: 'ground', label: 'Ground' },
  ];

  public caseShippingType:any;

  @Output() shippingFormCancelledOrSubmitted: EventEmitter<any> = new EventEmitter<any>();
  constructor(@Inject(MD_DIALOG_DATA) public data: any, private interactionService: InteractionService, private apiService: ApiService) {
    super();
  }

  ngOnInit() {
    const ownerAddress = this.data.owner.address;
    if (!ownerAddress.address1  || !ownerAddress.city || !ownerAddress.province || !ownerAddress.zip
      || !ownerAddress.country
    ) {
      const toTestKeys = ['address1', 'city', 'country', 'province', 'zip'];
      const invalidKeys = [];
      toTestKeys.forEach(toTestKey => {
        if (!ownerAddress[toTestKey]) {
          invalidKeys.push(' ' + capitalizeFirstLetter(toTestKey));
        }
      });

      this.internalErrorMessage = `Please input valid data for: ${invalidKeys.toString()}.`;
      this.showSubmitButton = false;
    }
    this.buildForm();
  }

  buildForm = () => {
    const dynamicOption = this.getDynamicOptions();
    this.caseShippingType = this.data.order && this.data.order.shipping_method === 'vet' ? 'vet' :
      (this.data.order && this.data.order.shipping_method === 'owner' ? 'owner' : 'other');
    this.shippingForm = new FormGroup({
      selected_shipping_type: new FormControl(this.caseShippingType),
      return_label: new FormControl(dynamicOption.return_label),
      box_size: new FormControl(dynamicOption.box_size),
      rate: new FormControl(dynamicOption.rate),
      weight: new FormControl(1, [Validators.required]),
      shipping_reference: new FormControl(this.getFormattedShippingReference(), [Validators.required]),
      shipping_note: new FormControl(''),
      vet_name: new FormControl(this.getVetName()),
      address: new FormControl(this.getOwnerAddress()),
      city_state_zip: new FormControl(this.getCityStateZip()),
      phone: new FormControl(this.getOwnerPhone()),
    });
    setTimeout(() => this.shippingValueUpdated(), 1000);
  }

  // vetinputfieldrequired(caseShippingType,inputFieldName) {
  //   if (caseShippingType === "owner") {
  //     return [Validators.required];
  //   }
   
  // }
  getVetName = (): string => this.data && this.data.vet ? this.data.vet.name : '';

  getOwnerAddress = (): string => this.data && this.data.owner && this.data.owner.address ? this.data.owner.address.address1 : '';

  getOwnerPhone = (): string => this.data && this.data.owner && this.data.owner.address ? this.data.owner.address.phone : '';

  getCityStateZip = (): string => {
    if (this.data && this.data.owner && this.data.owner.address) {
      const modalOwnerAddress = this.data.owner.address;
      return `${modalOwnerAddress.city} ${modalOwnerAddress.province} ${modalOwnerAddress.zip}`;
    }
    return '';
  }

  getDynamicOptions = () => {
    const availableOptions = {
      'Casting Kit Sent': {
        return_label: 'yes_printed',
        box_size: 'med_12_6_6',
        rate: '2_day',
        shipping_reference: '-Cast',
      },
      'Shipping Label Sent': {
        return_label: 'yes_emailed',
        box_size: 'med_12_6_6',
        rate: '2_day',
        shipping_reference: '-Cast',
      },
      'Brace Shipped': {
        return_label: 'no',
        box_size: 'med_12_6_6',
        rate: '2_day',
        shipping_reference: '-Brace',
      },
      'Waiting Adjustment': {
        return_label: 'yes_emailed',
        box_size: 'med_12_6_6',
        rate: '2_day',
        shipping_reference: '-Adj',
      },
      'Adjustment Shipped': {
        return_label: 'yes_emailed',
        box_size: 'med_12_6_6',
        rate: '2_day',
        shipping_reference: '',
      },
      'Waiting Refurbish': {
        return_label: 'yes_emailed',
        box_size: 'med_12_6_6',
        rate: '2_day',
        shipping_reference: '-Refurb',
      },
      'Refurbish Shipped': {
        return_label: 'yes_emailed',
        box_size: 'med_12_6_6',
        rate: '2_day',
        shipping_reference: '',
      },
    };

    return availableOptions[this.data.selected_case_option];
  }

  resetMessages = () => {
    this.internalErrorMessage = '';
    this.errorMessages = [];
    this.actionMessage = '';
  }

  getFormattedShippingReference = (): string => {
    const modalData = this.data;
    const orderId = modalData.order ? modalData.order.id : '';
    const dogName = modalData.dog ? modalData.dog.name : '';
    const ownerLastName = modalData.owner ? modalData.owner.last_name : '';
    return `(${orderId}) (${dogName}) (${ownerLastName})${this.getDynamicOptions()['shipping_reference']}`;
  }

  submitShippingForm = () => {
    this.resetMessages();
    if (!this.shippingForm.valid) {
      this.internalErrorMessage = 'Please fill up all the necessary values.';
      return false;
    }
    const formattedShippingValue = this.shippingForm.value;
    formattedShippingValue.case_id = this.data.case.id;
    this.isLoading = true;
    this.apiService.saveFedexShopping(formattedShippingValue).pipe(this.getTakeUntil())
      .subscribe((response: any) => {
        this.isLoading = false;
          if (response.status >= 400) {
            if (response.error && response.error.data && Array.isArray(response.error.data)) {
              this.errorMessages = response.error.data.map(singleError => {
                if (singleError.LocalizedMessage) {
                  return singleError.LocalizedMessage;
                }

                if (singleError.Message) {
                  return singleError.Message;
                }

                return null;
              });
            } else if (response.error && response.error.message) {
              this.internalErrorMessage = getReadableErrorMessage(response);
            } else if (response.message) {
            this.internalErrorMessage = response.message;
          }
            setTimeout(() => this.actionMessage = '', 4000);
          } else if (response.status === 0) {
            this.internalErrorMessage = 'Something went wrong.';
          } else if (response.status === 1) {
            this.hideButtons = true;
            this.actionMessage = response.message;
            setTimeout(() => this.interactionService.setShippingAction({action: 'saved', data: this.shippingForm.value}), 2000);
          }
        });
  }

  cancelShippingForm = () => this.interactionService.setShippingAction({action: 'cancelled'});

  shippingValueUpdated = (): void => {
    const vetControl = this.shippingForm.get('vet_name');
    const ownerAddressControl = this.shippingForm.get('address');
    const cityStateZipControl = this.shippingForm.get('city_state_zip');
    const phoneControl = this.shippingForm.get('phone');
    this.caseShippingType = this.shippingForm.value.selected_shipping_type;
    if (this.shippingForm.value.selected_shipping_type === 'other') {
      this.shippingForm.patchValue({
        vet_name: '',
        address: '',
        city_state_zip: '',
        phone: '',
      });
      vetControl.enable();
      ownerAddressControl.enable();
      cityStateZipControl.enable();
      phoneControl.enable();
      vetControl.setValidators([Validators.required]);
      vetControl.updateValueAndValidity();
      ownerAddressControl.setValidators(Validators.required);
      ownerAddressControl.updateValueAndValidity();
      cityStateZipControl.setValidators(Validators.required);
      cityStateZipControl.updateValueAndValidity();
      phoneControl.setValidators(Validators.required);
      phoneControl.updateValueAndValidity();


    } else {
      if (this.shippingForm.value.selected_shipping_type === 'none') {
        this.shippingForm.patchValue({
          vet_name: '',
          address: '',
          city_state_zip: '',
          phone: '',
        });
        this.cancelShippingForm();
      } else {
        this.shippingForm.patchValue({
          vet_name: this.getVetName(),
          address: this.getOwnerAddress(),
          city_state_zip: this.getCityStateZip(),
          phone: this.getOwnerPhone(),
        });
      }
      vetControl.disable();
      ownerAddressControl.disable();
      cityStateZipControl.disable();
      phoneControl.disable();
    }
  }
}
