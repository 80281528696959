import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toggleClass(event: any) {
    const hasClass = event.target.closest("div").classList.contains("active");

    if (hasClass) {
      event.target.closest("div").classList.remove("active");
    } else {
      event.target.closest("div").classList.add("active");
    }
  }
}
