import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feed-popup-follow-up',
  templateUrl: './feed-popup-follow-up.component.html',
  styleUrls: ['./feed-popup-follow-up.component.scss']
})
export class FeedPopupFollowUpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
