import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { Vet } from '../../../services/api/model/VetResponse';
import { AppState } from '../../../reducers/index';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedsFetchVetAction, FeedsSaveVetAction, ActionTypes } from '../../../actions/feeds';
import { AppModule } from '../../../app.module';
import { Actions } from '@ngrx/effects';
import { FormGroup, Validators } from '@angular/forms';
import { QuestionBase, QuestionControlService } from 'app/services/api/model/Question-Control-Service';
import { FileUploadComponent } from 'app/components/file-upload/file-upload.component';

@Component({
  selector: 'app-vet',
  templateUrl: './vet.component.html',
  styleUrls: ['./vet.component.css'],
  providers: [QuestionControlService]
})
export class VetComponent implements OnInit {

  files: any[] = [];
  vet: Vet = <any>{};
  showForm = false;
  domain = AppModule.domain;
  failureSubscription: any;
  successSubscription: any;
  success: boolean;
  failure: boolean;
  doctorName = '';
  loading = false;

  values: QuestionBase<any>[] = [];
  form: FormGroup;

  @ViewChild('uploadContainer', {read: ViewContainerRef}) container;

  constructor(
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private actions: Actions,
    private resolver: ComponentFactoryResolver,
    private router: Router,
  ) {
    this.failureSubscription = this.actions.ofType(ActionTypes.SaveVetFailure).subscribe((failure) => this.handleSaveVetFailure());
    this.successSubscription = this.actions.ofType(ActionTypes.SaveVetSuccess)
      .subscribe((result) => setTimeout(() => this.handleSaveVetSuccess(), 2000));

    this.route.params.subscribe((params) => this.store.dispatch(new FeedsFetchVetAction(params.id)));
    this.store.select(x => x.mainState.feeds.currentVet).subscribe((vet: Vet) => {
      this.vet = vet || <any>{};
      this.initializeForm();
    });
  }

  initializeForm() {
    this.values = [];
    if (this.vet) {
      this.values.push(new QuestionBase({value: this.vet.name, label: 'Clinic Name', key: 'name', validators: [Validators.required]}));
      this.values.push(new QuestionBase({value: this.vet.url, label: 'Clinic URL', type: 'url', key: 'url'}));
      this.values.push(new QuestionBase({
        value: this.vet.doctor_name,
        label: 'Doctor/CCRP Name',
        key: 'doctor_name',
        validators: [Validators.required]
      }));
      this.values.push(new QuestionBase({value: this.vet.email, label: 'Email', type: 'email', validators: [Validators.required]}));
      this.values.push(new QuestionBase({value: this.vet.address, label: 'Address', validators: [Validators.required]}));
      this.values.push(new QuestionBase({value: this.vet.city, label: 'City', validators: [Validators.required]}));
      this.values.push(new QuestionBase({value: this.vet.state, label: 'State', validators: [Validators.required]}));
      this.values.push(new QuestionBase({value: this.vet.zipcode, label: 'Zipcode', validators: [Validators.required]}));
      this.values.push(new QuestionBase({value: this.vet.country, label: 'Country', validators: [Validators.required]}));
      this.values.push(new QuestionBase({value: this.vet.phone, label: 'Phone', type: 'tel', validators: [Validators.required]}));
      this.values.push(new QuestionBase({key: 'picture', value: this.vet.picture, label: 'pictures', type: 'file-uploader'}));
      this.form = this.qcs.toFormGroup(this.values);
    }
  }

  ngOnInit() {
  }

  handleSaveVetSuccess(): void {
    this.loading = false;
    this.success = true;
    localStorage.setItem('name', this.doctorName);
    this.router.navigateByUrl('cases/(feed-view:vets)').then();
  }

  handleSaveVetFailure(): void {
    this.failure = true;
    this.loading = false;
  }

  handleUpload(file: any) {
    this.files.push(file);
    var factory = this.resolver.resolveComponentFactory(FileUploadComponent);
    let componentRef = this.container.createComponent(factory);
    componentRef.instance.onUpload.subscribe((x) => {
      this.handleUpload(x);
    });
  }

  showEditVet() {
    this.showForm = true;
    this.success = false;
    this.failure = false;
  }

  saveEditVet() {
    this.doctorName = this.form.get('doctor_name').value;
    this.showForm = false;
    if (this.vet) {
      const payLoad: FormData = new FormData();
      for (const key of Object.keys(this.vet)) {
        if (this.form.get(key) != null) {
          payLoad.append(key, (this.form.get(key).value == '' ? '' : this.form.get(key).value));
        }
      }

      for (let i = 0; i < this.files.length; i++) {
        payLoad.append('picture', this.files[i], this.files[i].name);
      }

      payLoad.append('id', this.vet.id.toString());
      if (this.form.valid) {
        this.loading = true;
        this.store.dispatch(new FeedsSaveVetAction(payLoad));
        this.initializeForm();
      }
    }
  }

  setUploadedFiles = (files) => {
    this.files = files;
  }
}
