import { Component, Inject, OnInit } from '@angular/core';
import { MD_DIALOG_DATA, MdDialog, MdDialogRef } from '@angular/material';
import { InteractionService } from '../../services/interaction.service';
import { ApiService } from '../../services/api/api-service';
import { HasSubscriptions } from '../../directives/HasSubscriptions';

@Component({
  selector: 'app-save-confirmation',
  templateUrl: './save-confirmation.component.html',
  styleUrls: ['./save-confirmation.component.css']
})
export class SaveConfirmation extends HasSubscriptions implements OnInit {

  ngOnInit() {

  }
  constructor(@Inject(MD_DIALOG_DATA) public data: any, private interactionService: InteractionService, private dialogRef: MdDialogRef<SaveConfirmation>, private apiService: ApiService) {
    super();
  }


  actionButtonClicked = (actionType) => {
    if (actionType === 'cancel') {
      this.dialogRef.close('close');
    }
    if (actionType === 'save') {
      this.dialogRef.close('save');
    }
  }
}


