export class utilities {
  public static dataToggle(text: string) {
    const str = text.replace(/^\s+|\s+$/g, '').replace(/ /g, '_');
    return str.toLowerCase();
  }
}

export function truncateString(truncateString, maxLength = 6, shouldDynamicallyDecreaseContent = false): string {
  if (truncateString && truncateString.length > maxLength) {
    const computedLength = (shouldDynamicallyDecreaseContent && screen.width < 450) ? 20 : maxLength;
    return `${truncateString.slice(0, computedLength)}..`;
  }
  return truncateString;
}

export function getReadableErrorMessage(failureAction): string {
  const failureActionPayload = failureAction && failureAction.payload ? failureAction.payload : {};
  const dynamicFailPayload = failureAction.hasOwnProperty('status') ? failureAction : failureActionPayload;
  if (dynamicFailPayload.status === 400) {
    if (dynamicFailPayload.error && dynamicFailPayload.error.errors) {
      let errorTabs = '';
      dynamicFailPayload.error.errors.forEach(error => {
        const errorField = (error && error.field && error.field[0]) ? capitalizeFirstLetter(error.field[0]) + ' ' : '';
        errorTabs = errorTabs + errorField;
      });
      return `Please fill proper values in ${errorTabs.trim().split(' ').join(', ')}.`;
    }

    if (failureAction && failureAction.error && failureAction.error.message) {
      return failureAction.error.message;
    }

    return 'Please fill up the form correctly.';
  }
  if (failureAction && failureAction.error && failureAction.error.message) {
    return failureAction && failureAction.error && failureAction.error.message;
  }

  return 'Something went wrong. Please try again';
}

export function capitalizeFirstLetter(argumentString = null): string {
  if (argumentString) {
    return argumentString.charAt(0).toUpperCase() + argumentString.slice(1);
  }
  return '';
}

export function addBusinessDays(dateToBeFormatted, numberOfDays) {
  if (!dateToBeFormatted) {
    return null;
  }

  dateToBeFormatted = new Date(dateToBeFormatted);
  const day = dateToBeFormatted.getDay();
  dateToBeFormatted.setDate(
    dateToBeFormatted.getDate() + numberOfDays + (day === 6 ? 2 : +!day) + (Math.floor((numberOfDays - 1 + (day % 6 || 1)) / 5) * 2)
  );
  return dateToBeFormatted;
}

export function findArrayIntersection(firstArray, secondArray): Array<any> {
  return firstArray.filter(value => secondArray.includes(value));
};


export const countryList = [
  {
    'value': '',
    'title': 'Choose'
  },
  {
    'value': 'US',
    'title': 'United States'
  },
  {
    'value': 'AX',
    'title': 'Aland Islands'
  },
  {
    'value': 'AL',
    'title': 'Albania'
  },
  {
    'value': 'DZ',
    'title': 'Algeria'
  },
  {
    'value': 'AS',
    'title': 'American Samoa'
  },
  {
    'value': 'AD',
    'title': 'Andorra'
  },
  {
    'value': 'AI',
    'title': 'Anguilla'
  },
  {
    'value': 'AQ',
    'title': 'Antarctica'
  },
  {
    'value': 'AG',
    'title': 'Antigua and Barbuda'
  },
  {
    'value': 'AR',
    'title': 'Argentina'
  },
  {
    'value': 'AM',
    'title': 'Armenia'
  },
  {
    'value': 'AW',
    'title': 'Aruba'
  },
  {
    'value': 'AU',
    'title': 'Australia'
  },
  {
    'value': 'AT',
    'title': 'Austria'
  },
  {
    'value': 'AZ',
    'title': 'Azerbaijan'
  },
  {
    'value': 'BS',
    'title': 'Bahamas'
  },
  {
    'value': 'BH',
    'title': 'Bahrain'
  },
  {
    'value': 'BD',
    'title': 'Bangladesh'
  },
  {
    'value': 'BB',
    'title': 'Barbados'
  },
  {
    'value': 'BE',
    'title': 'Belgium'
  },
  {
    'value': 'BZ',
    'title': 'Belize'
  },
  {
    'value': 'BJ',
    'title': 'Benin'
  },
  {
    'value': 'BM',
    'title': 'Bermuda'
  },
  {
    'value': 'BT',
    'title': 'Bhutan'
  },
  {
    'value': 'BA',
    'title': 'Bosnia-Herzegovina'
  },
  {
    'value': 'BW',
    'title': 'Botswana'
  },
  {
    'value': 'BV',
    'title': 'Bouvet Island'
  },
  {
    'value': 'BR',
    'title': 'Brazil'
  },
  {
    'value': 'IO',
    'title': 'British Indian Ocean Territory'
  },
  {
    'value': 'BN',
    'title': 'Brunei Darussalam'
  },
  {
    'value': 'BG',
    'title': 'Bulgaria'
  },
  {
    'value': 'BF',
    'title': 'Burkina Faso'
  },
  {
    'value': 'CA',
    'title': 'Canada'
  },
  {
    'value': 'CV',
    'title': 'Cape Verde'
  },
  {
    'value': 'KY',
    'title': 'Cayman Islands'
  },
  {
    'value': 'CF',
    'title': 'Central African Republic'
  },
  {
    'value': 'CL',
    'title': 'Chile'
  },
  {
    'value': 'CN',
    'title': 'China'
  },
  {
    'value': 'CX',
    'title': 'Christmas Island'
  },
  {
    'value': 'CC',
    'title': 'Cocos (Keeling) Islands'
  },
  {
    'value': 'CO',
    'title': 'Colombia'
  },
  {
    'value': 'CK',
    'title': 'Cook Islands'
  },
  {
    'value': 'CR',
    'title': 'Costa Rica'
  },
  {
    'value': 'CY',
    'title': 'Cyprus'
  },
  {
    'value': 'CZ',
    'title': 'Czech Republic'
  },
  {
    'value': 'DK',
    'title': 'Denmark'
  },
  {
    'value': 'DJ',
    'title': 'Djibouti'
  },
  {
    'value': 'DM',
    'title': 'Dominica'
  },
  {
    'value': 'DO',
    'title': 'Dominican Republic'
  },
  {
    'value': 'EC',
    'title': 'Ecuador'
  },
  {
    'value': 'EG',
    'title': 'Egypt'
  },
  {
    'value': 'SV',
    'title': 'El Salvador'
  },
  {
    'value': 'EE',
    'title': 'Estonia'
  },
  {
    'value': 'FK',
    'title': 'Falkland Islands (Malvinas)'
  },
  {
    'value': 'FO',
    'title': 'Faroe Islands'
  },
  {
    'value': 'FJ',
    'title': 'Fiji'
  },
  {
    'value': 'FI',
    'title': 'Finland'
  },
  {
    'value': 'FR',
    'title': 'France'
  },
  {
    'value': 'GF',
    'title': 'French Guiana'
  },
  {
    'value': 'PF',
    'title': 'French Polynesia'
  },
  {
    'value': 'TF',
    'title': 'French Southern Territories'
  },
  {
    'value': 'GA',
    'title': 'Gabon'
  },
  {
    'value': 'GM',
    'title': 'Gambia'
  },
  {
    'value': 'GE',
    'title': 'Georgia'
  },
  {
    'value': 'DE',
    'title': 'Germany'
  },
  {
    'value': 'GH',
    'title': 'Ghana'
  },
  {
    'value': 'GI',
    'title': 'Gibraltar'
  },
  {
    'value': 'GR',
    'title': 'Greece'
  },
  {
    'value': 'GL',
    'title': 'Greenland'
  },
  {
    'value': 'GD',
    'title': 'Grenada'
  },
  {
    'value': 'GP',
    'title': 'Guadeloupe'
  },
  {
    'value': 'GU',
    'title': 'Guam'
  },
  {
    'value': 'CG',
    'title': 'Guernsey'
  },
  {
    'value': 'GY',
    'title': 'Guyana'
  },
  {
    'value': 'HM',
    'title': 'Heard Island and Mcdonald Islands'
  },
  {
    'value': 'VA',
    'title': 'Holy see (Vatican City State)'
  },
  {
    'value': 'HN',
    'title': 'Honduras'
  },
  {
    'value': 'HK',
    'title': 'Hong Kong'
  },
  {
    'value': 'HU',
    'title': 'Hungary'
  },
  {
    'value': 'IS',
    'title': 'Iceland'
  },
  {
    'value': 'IN',
    'title': 'India'
  },
  {
    'value': 'ID',
    'title': 'Indonesia'
  },
  {
    'value': 'IE',
    'title': 'Ireland'
  },
  {
    'value': 'IM',
    'title': 'Isle of Man'
  },
  {
    'value': 'IL',
    'title': 'Israel'
  },
  {
    'value': 'IT',
    'title': 'Italy'
  },
  {
    'value': 'JM',
    'title': 'Jamaica'
  },
  {
    'value': 'JP',
    'title': 'Japan'
  },
  {
    'value': 'JE',
    'title': 'Jersey'
  },
  {
    'value': 'JO',
    'title': 'Jordan'
  },
  {
    'value': 'KZ',
    'title': 'Kazakhstan'
  },
  {
    'value': 'KI',
    'title': 'Kiribati'
  },
  {
    'value': 'KR',
    'title': 'Korea, Republic of'
  },
  {
    'value': 'KW',
    'title': 'Kuwait'
  },
  {
    'value': 'KG',
    'title': 'Kyrgyzstan'
  },
  {
    'value': 'LV',
    'title': 'Latvia'
  },
  {
    'value': 'LS',
    'title': 'Lesotho'
  },
  {
    'value': 'LI',
    'title': 'Liechtenstein'
  },
  {
    'value': 'LT',
    'title': 'Lithuania'
  },
  {
    'value': 'LU',
    'title': 'Luxembourg'
  },
  {
    'value': 'MO',
    'title': 'Macao'
  },
  {
    'value': 'MK',
    'title': 'Macedonia'
  },
  {
    'value': 'MG',
    'title': 'Madagascar'
  },
  {
    'value': 'MW',
    'title': 'Malawi'
  },
  {
    'value': 'MY',
    'title': 'Malaysia'
  },
  {
    'value': 'MT',
    'title': 'Malta'
  },
  {
    'value': 'MH',
    'title': 'Marshall Islands'
  },
  {
    'value': 'MQ',
    'title': 'Martinique'
  },
  {
    'value': 'MR',
    'title': 'Mauritania'
  },
  {
    'value': 'MU',
    'title': 'Mauritius'
  },
  {
    'value': 'YT',
    'title': 'Mayotte'
  },
  {
    'value': 'MX',
    'title': 'Mexico'
  },
  {
    'value': 'FM',
    'title': 'Micronesia, Federated States of'
  },
  {
    'value': 'MD',
    'title': 'Moldova, Republic of'
  },
  {
    'value': 'MC',
    'title': 'Monaco'
  },
  {
    'value': 'MN',
    'title': 'Mongolia'
  },
  {
    'value': 'ME',
    'title': 'Montenegro'
  },
  {
    'value': 'MS',
    'title': 'Montserrat'
  },
  {
    'value': 'MA',
    'title': 'Morocco'
  },
  {
    'value': 'MZ',
    'title': 'Mozambique'
  },
  {
    'value': 'NA',
    'title': 'Namibia'
  },
  {
    'value': 'NR',
    'title': 'Nauru'
  },
  {
    'value': 'NP',
    'title': 'Nepal'
  },
  {
    'value': 'NL',
    'title': 'Netherlands'
  },
  {
    'value': 'AN',
    'title': 'Netherlands Antilles'
  },
  {
    'value': 'NC',
    'title': 'New Caledonia'
  },
  {
    'value': 'NZ',
    'title': 'New Zealand'
  },
  {
    'value': 'NI',
    'title': 'Nicaragua'
  },
  {
    'value': 'NE',
    'title': 'Niger'
  },
  {
    'value': 'NU',
    'title': 'Niue'
  },
  {
    'value': 'NF',
    'title': 'Norfolk Island'
  },
  {
    'value': 'MP',
    'title': 'Northern Mariana Islands'
  },
  {
    'value': 'NO',
    'title': 'Norway'
  },
  {
    'value': 'OM',
    'title': 'Oman'
  },
  {
    'value': 'PW',
    'title': 'Palau'
  },
  {
    'value': 'PS',
    'title': 'Palestine'
  },
  {
    'value': 'PA',
    'title': 'Panama'
  },
  {
    'value': 'PY',
    'title': 'Paraguay'
  },
  {
    'value': 'PE',
    'title': 'Peru'
  },
  {
    'value': 'PH',
    'title': 'Philippines'
  },
  {
    'value': 'PN',
    'title': 'Pitcairn'
  },
  {
    'value': 'PL',
    'title': 'Poland'
  },
  {
    'value': 'PT',
    'title': 'Portugal'
  },
  {
    'value': 'PR',
    'title': 'Puerto Rico'
  },
  {
    'value': 'QA',
    'title': 'Qatar'
  },
  {
    'value': 'RE',
    'title': 'Reunion'
  },
  {
    'value': 'RO',
    'title': 'Romania'
  },
  {
    'value': 'RU',
    'title': 'Russian Federation'
  },
  {
    'value': 'RW',
    'title': 'Rwanda'
  },
  {
    'value': 'SH',
    'title': 'Saint Helena'
  },
  {
    'value': 'KN',
    'title': 'Saint Kitts and Nevis'
  },
  {
    'value': 'LC',
    'title': 'Saint Lucia'
  },
  {
    'value': 'PM',
    'title': 'Saint Pierre and Miquelon'
  },
  {
    'value': 'VC',
    'title': 'Saint Vincent and the Grenadines'
  },
  {
    'value': 'WS',
    'title': 'Samoa'
  },
  {
    'value': 'SM',
    'title': 'San Marino'
  },
  {
    'value': 'ST',
    'title': 'Sao Tome and Principe'
  },
  {
    'value': 'SA',
    'title': 'Saudi Arabia'
  },
  {
    'value': 'SN',
    'title': 'Senegal'
  },
  {
    'value': 'RS',
    'title': 'Serbia'
  },
  {
    'value': 'SC',
    'title': 'Seychelles'
  },
  {
    'value': 'SG',
    'title': 'Singapore'
  },
  {
    'value': 'SK',
    'title': 'Slovakia'
  },
  {
    'value': 'SI',
    'title': 'Slovenia'
  },
  {
    'value': 'SB',
    'title': 'Solomon Islands'
  },
  {
    'value': 'ZA',
    'title': 'South Africa'
  },
  {
    'value': 'GS',
    'title': 'South Georgia and the South Sandwich Islands'
  },
  {
    'value': 'ES',
    'title': 'Spain'
  },
  {
    'value': 'SR',
    'title': 'Suriname'
  },
  {
    'value': 'SJ',
    'title': 'Svalbard and Jan Mayen'
  },
  {
    'value': 'SZ',
    'title': 'Swaziland'
  },
  {
    'value': 'SE',
    'title': 'Sweden'
  },
  {
    'value': 'CH',
    'title': 'Switzerland'
  },
  {
    'value': 'TW',
    'title': 'Taiwan, Province of China'
  },
  {
    'value': 'TZ',
    'title': 'Tanzania, United Republic of'
  },
  {
    'value': 'TH',
    'title': 'Thailand'
  },
  {
    'value': 'TL',
    'title': 'Timor-Leste'
  },
  {
    'value': 'TG',
    'title': 'Togo'
  },
  {
    'value': 'TK',
    'title': 'Tokelau'
  },
  {
    'value': 'TO',
    'title': 'Tonga'
  },
  {
    'value': 'TT',
    'title': 'Trinidad and Tobago'
  },
  {
    'value': 'TN',
    'title': 'Tunisia'
  },
  {
    'value': 'TR',
    'title': 'Turkey'
  },
  {
    'value': 'TM',
    'title': 'Turkmenistan'
  },
  {
    'value': 'TC',
    'title': 'Turks and Caicos Islands'
  },
  {
    'value': 'TV',
    'title': 'Tuvalu'
  },
  {
    'value': 'UG',
    'title': 'Uganda'
  },
  {
    'value': 'UA',
    'title': 'Ukraine'
  },
  {
    'value': 'AE',
    'title': 'United Arab Emirates'
  },
  {
    'value': 'GB',
    'title': 'United Kingdom'
  },
  {
    'value': 'UM',
    'title': 'United States Minor Outlying Islands'
  },
  {
    'value': 'UY',
    'title': 'Uruguay'
  },
  {
    'value': 'UZ',
    'title': 'Uzbekistan'
  },
  {
    'value': 'VU',
    'title': 'Vanuatu'
  },
  {
    'value': 'VE',
    'title': 'Venezuela'
  },
  {
    'value': 'VN',
    'title': 'Viet nam'
  },
  {
    'value': 'VG',
    'title': 'Virgin Islands, British'
  },
  {
    'value': 'VI',
    'title': 'Virgin Islands, U.S.'
  },
  {
    'value': 'WF',
    'title': 'Wallis and Futuna'
  },
  {
    'value': 'EH',
    'title': 'Western Sahara'
  },
  {
    'value': 'ZM',
    'title': 'Zambia'
  },
];

export const states = [
  {
    'value': '',
    'title': 'Choose'
  },
  {
    'value': 'AL',
    'title': 'Alabama'
  },
  {
    'value': 'AK',
    'title': 'Alaska'
  },
  {
    'value': 'AZ',
    'title': 'Arizona'
  },
  {
    'value': 'AR',
    'title': 'Arkansas'
  },
  {
    'value': 'CA',
    'title': 'California'
  },
  {
    'value': 'CO',
    'title': 'Colorado'
  },
  {
    'value': 'CT',
    'title': 'Connecticut'
  },
  {
    'value': 'DC',
    'title': 'District of Columbia'
  },
  {
    'value': 'DE',
    'title': 'Delaware'
  },
  {
    'value': 'FL',
    'title': 'Florida'
  },
  {
    'value': 'GA',
    'title': 'Georgia'
  },
  {
    'value': 'HI',
    'title': 'Hawaii'
  },
  {
    'value': 'ID',
    'title': 'Idaho'
  },
  {
    'value': 'IL',
    'title': 'Illinois'
  },
  {
    'value': 'IN',
    'title': 'Indiana'
  },
  {
    'value': 'IA',
    'title': 'Iowa'
  },
  {
    'value': 'KS',
    'title': 'Kansas'
  },
  {
    'value': 'KY',
    'title': 'Kentucky'
  },
  {
    'value': 'LA',
    'title': 'Louisiana'
  },
  {
    'value': 'ME',
    'title': 'Maine'
  },
  {
    'value': 'MD',
    'title': 'Maryland'
  },
  {
    'value': 'MA',
    'title': 'Massachusetts'
  },
  {
    'value': 'MI',
    'title': 'Michigan'
  },
  {
    'value': 'MN',
    'title': 'Minnesota'
  },
  {
    'value': 'MS',
    'title': 'Mississippi'
  },
  {
    'value': 'MO',
    'title': 'Missouri'
  },
  {
    'value': 'MT',
    'title': 'Montana'
  },
  {
    'value': 'NE',
    'title': 'Nebraska'
  },
  {
    'value': 'NV',
    'title': 'Nevada'
  },
  {
    'value': 'NH',
    'title': 'New Hampshire'
  },
  {
    'value': 'NJ',
    'title': 'New Jersey'
  },
  {
    'value': 'NM',
    'title': 'New Mexico'
  },
  {
    'value': 'NY',
    'title': 'New York'
  },
  {
    'value': 'NC',
    'title': 'North Carolina'
  },
  {
    'value': 'ND',
    'title': 'North Dakota'
  },
  {
    'value': 'OH',
    'title': 'Ohio'
  },
  {
    'value': 'OK',
    'title': 'Oklahoma'
  },
  {
    'value': 'OR',
    'title': 'Oregon'
  },
  {
    'value': 'PA',
    'title': 'Pennsylvania'
  },
  {
    'value': 'RI',
    'title': 'Rhode Island'
  },
  {
    'value': 'SC',
    'title': 'South Carolina'
  },
  {
    'value': 'SD',
    'title': 'South Dakota'
  },
  {
    'value': 'TN',
    'title': 'Tennessee'
  },
  {
    'value': 'TX',
    'title': 'Texas'
  },
  {
    'value': 'UT',
    'title': 'Utah'
  },
  {
    'value': 'VT',
    'title': 'Vermont'
  },
  {
    'value': 'VA',
    'title': 'Virginia'
  },
  {
    'value': 'WA',
    'title': 'Washington'
  },
  {
    'value': 'WV',
    'title': 'West Virginia'
  },
  {
    'value': 'WI',
    'title': 'Wisconsin'
  },
  {
    'value': 'WY',
    'title': 'Wyoming'
  },
  {
    'value': ' ',
    'title': '--'
  },
  {
    'value': 'AB',
    'title': 'Alberta'
  },
  {
    'value': 'BC',
    'title': 'British Columbia'
  },
  {
    'value': 'MB',
    'title': 'Manitoba'
  },
  {
    'value': 'NB',
    'title': 'New Brunswick'
  },
  {
    'value': 'NL',
    'title': 'Newfoundland and Labrador'
  },
  {
    'value': 'NT',
    'title': 'Northwest Territories'
  },
  {
    'value': 'NS',
    'title': 'Nova Scotia'
  },
  {
    'value': 'NU',
    'title': 'Nunavut'
  },
  {
    'value': 'ON',
    'title': 'Ontario'
  },
  {
    'value': 'PE',
    'title': 'Prince Edward Island'
  },
  {
    'value': 'QC',
    'title': 'Quebec'
  },
  {
    'value': 'SK',
    'title': 'Saskatchewan'
  },
  {
    'value': 'YT',
    'title': 'Yukon Territory'
  },
];

