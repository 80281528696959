
import { ReducerBase } from "ngrx-cli/reducer-base";
import * as app from '../actions/app';
import { MediaService } from "../directives/media-service";

export interface State {
	hideHeader: boolean;
	navs: {
		braces: string[];
		vets: string[];
		pricing: string[];
		resources: string[];
		about: string[];
	};
	mediaService: MediaService;
	contact: string;

}

export class AppReducer extends ReducerBase {
	static initialState: State = {
		hideHeader: false,
		navs: {
			braces: ["Knee Brace", "Ankle Brace", "Wrist Brace", "Testimonials"],
			vets: ['Research Study', 'Successful Veterinarians', 'Practice Implementation', 'Case Studies'],
			pricing: ['What to Expect', 'Save Time', 'Braces', 'Casting Kit'],
			resources: ['FAQ', 'Videos', 'Blog', 'Virtual Clinic'],
			about: ['The Journey', 'The Team', 'Programs We Support', 'Contact Us']
		},
		mediaService: null,
		contact: null
	};

	static reducer(state = AppReducer.initialState, action: app.Actions): State {
		switch (action.type) {
			case app.ActionTypes.ChangeHeaderState:
				return AppReducer.handleChangeHeaderState(state, <app.AppChangeHeaderStateAction>action);
			case app.ActionTypes.InitializeMediaService:
				return AppReducer.handleInitializeMediaService(state, <app.AppInitializeMediaServiceAction>action);
			case app.ActionTypes.SendContact:
				return AppReducer.handleSendContact(state, <app.AppSendContactAction>action);
			case app.ActionTypes.SendContactSuccess:
				return AppReducer.handleSendContactSuccess(state, <app.AppSendContactSuccessAction>action);
			case app.ActionTypes.SendContactFailure:
				return AppReducer.handleSendContactFailure(state, <app.AppSendContactFailureAction>action);
			case app.ActionTypes.ContactButton:
				return AppReducer.handleContactButton(state, <app.AppContactButtonAction>action);
			case app.ActionTypes.ContactPage:
				return AppReducer.handleContactPage(state, <app.AppContactPageAction>action);
		}
		return state;
	}

	static handleChangeHeaderState(state: State, action: app.AppChangeHeaderStateAction): State {
		return Object.assign({}, state, { hideHeader: action.payload });
	}
	static handleInitializeMediaService(state: State, action: app.AppInitializeMediaServiceAction): State {

		return Object.assign({}, state, { mediaService: action.payload });
	}
	static handleSendContact(state: State, action: app.AppSendContactAction): State {
		return state;
	}
	static handleSendContactSuccess(state: State, action: app.AppSendContactSuccessAction): State {
		return state;
	}
	static handleSendContactFailure(state: State, action: app.AppSendContactFailureAction): State {
		return state;
	}
	static handleContactButton(state: State, action: app.AppContactButtonAction): State {
		return Object.assign({}, state, { contact: "button" });
	}
	static handleContactPage(state: State, action: app.AppContactPageAction): State {
		return Object.assign({}, state, { contact: "page" });
	}
}
