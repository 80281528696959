import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(private _router: Router)
  {
    this.navigate();
  }

  navigate()
  {
    //temporary forward to home page
    this._router.navigate(['/home'])

/*    var userType = localStorage.getItem("userType");

    if (!userType)
      return;

    if (userType == 'parent')
      this._router.navigate(['/home'])
    else if (userType == 'vet')
      this._router.navigate(['/veterinarians'])*/

  }

  ngOnInit() {
  }

  parent()
  {
    localStorage.setItem("userType", "parent");
    this.navigate();
  }

  vet()
  {
    localStorage.setItem("userType", "vet");
    this.navigate();
  }
}
