import { Component, Inject, OnInit } from '@angular/core';
import { MD_DIALOG_DATA, MdDialog, MdDialogRef } from '@angular/material';
import { InteractionService } from '../../services/interaction.service';
import { ApiService } from '../../services/api/api-service';
import { HasSubscriptions } from '../../directives/HasSubscriptions';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmation extends HasSubscriptions implements OnInit {

  ngOnInit() {

  }
  constructor(@Inject(MD_DIALOG_DATA) public data: any, private interactionService: InteractionService, private dialogRef: MdDialogRef<DeleteConfirmation>, private apiService: ApiService) {
    super();
  }


  actionButtonClicked = (actionType) => {
    if (actionType === 'cancel') {
      this.dialogRef.close();
    }
    if (actionType === 'delete') {
      this.apiService.deletecase(this.data.caseData.id).pipe(this.getTakeUntil()).subscribe(data => {
        this.interactionService.setModalClose(true);
        this.interactionService.setShouldUpdateCaseFeed(true);
      });
    }
  }
}
