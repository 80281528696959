import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-vets',
	templateUrl: './veterinarians.component.html',
	styleUrls: ['./veterinarians.component.scss']
})
export class VeterinariansComponent implements OnInit {

	navs = [
		{
			id: 'Research Studies',
			class: 'leftText_rightImage_wrap4',
			heading: 'How do you help an owner make a rational decision in such an emotional time?',
			content: 'That is why we do research studies.  All Veterinarians want to feel confident in what we prescribe.  We want the best for the dog.',
			image: '/assets/img/Torres-et-al-brace-study.jpg',
			link: 'research',
			link_text: 'Learn What Research Says'
		},

		{
			id: 'Successful Veterinarians',
			class: 'leftText_rightImage_wrap3',
			heading: 'What Veterinainans offer bracing in their practice?',
			content: "Do you have dogs that cannot have surgery? What is your treatment protocol for those animals? Tools like bracing will help those dogs live a better quality of life.",
			image: '/assets/img/Veterinarian-holding-cast-for-dog-brace.jpg',
			link: 'veterinarian-success-stories',
			link_text: 'Veterinarian Success Stories'
		},

		{
			id: 'Practice Implementation',
			class: 'leftText_rightImage_wrap2',
			heading: 'How long did it take you to learn surgery?',
			content: 'Did you need a mentor? You will be the expert in your city by learning from resouces devleoped by our experienced team.',
			image: '/assets/img/Dog-ACL-Surgery.jpg',
			link: 'resources',
			link_text: 'Learn Nuances of Bracing'
		},

		{
			id: 'Case Studies',
			class: 'leftText_rightImage_wrap3',
			heading: 'Case Studies',
			content: 'How are Vets using bracing in their clinics? <br> “We were very excited Annie could go from non-ambulatory to playing in 5 months.” – Anna Bade, LVT',
			image: '/assets/img/Morgan2.jpg',
			link: 'case-studies',
			link_text: 'See More Case Studies'
		}
	];
	navsn = this.navs.map(x => x.id);


	constructor() {
	}

	ngOnInit() {
	}

}
