import { Component, Inject, OnInit } from '@angular/core';
import { MdDialog, MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { InteractionService } from '../services/interaction.service';
import { ApiService } from '../services/api/api-service';
import { truncateString } from '../utilities/utilities';
import { HasSubscriptions } from '../directives/HasSubscriptions';
import { SaveConfirmation } from 'app/components/save-confirmation/save-confirmation.component';

@Component({
  selector: 'app-merge-vet',
  templateUrl: './merge-vet.component.html',
  styleUrls: ['./merge-vet.component.css']
})
export class MergeVetComponent extends HasSubscriptions implements OnInit {
  allAvailableVets = [];
  filteredVets = [];
  selectedVetIds = [];
  selectedVets = [];
  vetInput = null;
  isVetLoading = true;
  timeout: any;


    constructor(@Inject(MD_DIALOG_DATA) public data: any, private interactionService: InteractionService, private apiService: ApiService, private dialog: MdDialog,
    ) {
    super();
  }

  ngOnInit() {
    this.setAllVets();
  }

  setAllVets = () => {
    this.apiService.fetchVetWithQueryParams(`page_nr=1&page_size=500&compact=true`)
      .pipe(this.getTakeUntil())
      .subscribe((vetData: { data, total_count, next_page, prev_page }) => {
        this.isVetLoading = false;
        this.allAvailableVets = vetData.data
          .filter(singleVet => singleVet.id !== this.data.currentVet.id)
          .map(vet => {
            const formattedVet: any = vet;
            formattedVet.doctor_name = vet.doctor_name;
            formattedVet.city = vet.city || '';
            formattedVet.zipcode = vet.zipcode || '';
            formattedVet.country = vet.country || '';
            formattedVet.email = vet.email || '';
            formattedVet.state = vet.state || '';
            formattedVet.address = vet.address || '';
            formattedVet.formattedName = vet.name ? vet.name.replaceAll(' ', '').toLowerCase() : '';
            formattedVet.formattedDoctorName = vet.doctor_name ? vet.doctor_name.replaceAll(' ', '').toLowerCase() : '';
            return formattedVet;
          });
        this.filteredVets = this.allAvailableVets;
      });
  }

  actionButtonClicked = (actionType) => {
    if (actionType === 'cancel') {
      this.interactionService.setModalClose(true);
    }

    if (actionType === 'merge') {
      let mergeVetString = ''
      if(this.selectedVets){
        this.selectedVets.forEach((element,index) => {
          if (index == this.selectedVets.length -1) {
            mergeVetString = mergeVetString + element.doctor_name;
          } else {
            mergeVetString = mergeVetString + element.doctor_name + ' , ';
          }
        });
      }
      let dialogRef = this.dialog.open(SaveConfirmation,{
        data:{
          form:'merge vet',
          vet:this.data.currentVet.doctor_name,
          vet_to_merge:mergeVetString
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        // received data from dialog-component
        if (res == "save") {
          this.apiService.mergeVets({
            parent_vet_id: this.data.currentVet.id,
            to_be_merged_vet_ids: this.selectedVetIds
          }).pipe(this.getTakeUntil()).subscribe(() => {
            this.interactionService.setVetMerged(true);
          });
        } else {
          this.interactionService.setModalClose(true);
        }
      })
    }
  }

  vetClicked = (vet) => {
    this.selectedVets = [];
    if (this.selectedVetIds.includes(vet.id)) {
      this.selectedVetIds.splice(this.selectedVetIds.indexOf(vet.id), 1);
    } else {
      this.selectedVetIds.push(vet.id);
    }
    this.selectedVetIds.forEach(vetId => {
      this.selectedVets.push(this.filteredVets.find(singleVet => singleVet.id === vetId));
    });
  }

  resetSelectedVets = () => {
    this.selectedVets = [];
    this.selectedVetIds = [];
  }

  truncateString = (stringToBeTruncated, maxCharacters = 20): string => truncateString(stringToBeTruncated, maxCharacters);

  /**
   * Search vet
   * @param updatedSearchText 
   * 
   * return Array
   */
  vetInputChanged = (updatedSearchText): void => {
    clearTimeout(this.timeout);
    this.resetSelectedVets();
    this.timeout = setTimeout(() => {
      // call search vet API if search keyword length is greater than or equal to 4
      if (updatedSearchText.length >= 4) {
        this.apiService.searchVets(updatedSearchText).subscribe((vetDataResult) => {
          if (vetDataResult.length !=0) {
            this.filteredVets = vetDataResult
              .filter((vet) => vet.name && vet.zipcode)
              .map((vet) => {
                const formattedVet: any = vet;
                formattedVet.doctor_name = vet.doctor_name;
                formattedVet.city = vet.city || '';
                formattedVet.zipcode = vet.zipcode || '';
                formattedVet.country = vet.country || '';
                formattedVet.email = vet.email || '';
                formattedVet.state = vet.state || '';
                formattedVet.address = vet.address || '';
                formattedVet.formattedName = vet.name ? vet.name.replaceAll(' ', '').toLowerCase() : '';
                formattedVet.formattedDoctorName = vet.doctor_name ? vet.doctor_name.replaceAll(' ', '').toLowerCase() : '';
                return formattedVet;
              });
          } else {
            this.filteredVets = [];
          }
        });
      } else {
        this.filteredVets = this.allAvailableVets;
      }
    }, 500);
  
  }


}
