import {
  Component, ElementRef, HostBinding, HostListener, Inject, Input, OnInit
} from '@angular/core';
import { utilities } from "../../utilities/utilities";
import * as $ from "jquery";
import { AppState } from "../../reducers/index";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-page-nav',
  templateUrl: './page-nav.component.html',
  styleUrls: ['./page-nav.component.css']
})
export class PageNavComponent implements OnInit {

  @Input() navs: string[] = [];

  selectedIndex: number = 0;
  floating: boolean = false;
  floatOffset: number = 0;
  headerHidden: boolean;
  jumping: boolean = false;

  navIndices: number[] = [];

  constructor(private _element: ElementRef, @Inject(DOCUMENT) private document: any, private store: Store<AppState>, private route: ActivatedRoute, private router: Router) {
    this.store.select(x => x.mainState.app.hideHeader).subscribe((hidden) => {
      this.headerHidden = hidden;
      this.buildIndices();
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => this.buildIndices());
    this.route.fragment.subscribe(x => setTimeout(() => this.navToFragment(x)));
  }

  navToFragment(fragment: string) {
    if (fragment) {
      var index = this.navs.map(x => utilities.dataToggle(x)).indexOf(fragment);

      this.jumpTo(null, index, fragment);
    }
  }

  buildIndices() {
    this.navIndices = [];

    for (var nav of this.navs) {
      var toggleName = utilities.dataToggle(nav);

      if (!$('#' + toggleName).offset())
        continue;

      this.navIndices.push($('#' + toggleName).offset().top);
    }
  }

  @HostBinding('class')
  get getClass() {
    if (this.router.url != '/pricing' && this.router.url != '/case-manager' && this.router.url != '/cases' && this.router.url != '/order') {
      // If the navbar is 'floating' and the header is hidden, make sure it 'floats' at the very top of the page
      if (this.floating && this.headerHidden)
        return "floating-with-announcement very-top-with-announcement";

      // Else, have it float just below the header
      return this.floating ? "floating-with-announcement" : null;
    } else {
      // If the navbar is 'floating' and the header is hidden, make sure it 'floats' at the very top of the page
      if (this.floating && this.headerHidden)
        return "floating-without-announcement very-top-without-announcement";

      // Else, have it float just below the header
      return this.floating ? "floating-without-announcement" : null;
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {

    this.updateNavBar();
  }

  updateNavBar() {
    // Get the current scroll location
    var top = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;

    // If the current scroll location is greater than the offset of the navbar
    if (top > $(this._element.nativeElement).offset().top) {

      // 'float' the navbar
      this.floating = true;

      // Record the offset that we started 'floating' the navbar
      this.floatOffset = $(this._element.nativeElement).offset().top - 80;
    }
    // If the current scroll location is less than the offset where we started 'floating' the navbar
    else if (top < this.floatOffset) {
      // Reset the float offset
      this.floatOffset = 0;

      // Stop 'floating' the navbar
      this.floating = false;
    }

    if (this.jumping)
      return;

    // If the navbar is 'floating' and the header is hidden, add the height of the navbar to the current location
    var adjustment = this.floating && this.headerHidden ? 80 : this.floating && !this.headerHidden ? 160 : 0;

    var adjustedTop = top + adjustment

    // Get a list of section offsets that are less than our adjusted location
    var currentNav = this.navIndices.filter(x => adjustedTop >= x);

    // No matches, return
    if (currentNav.length == 0)
      return;

    // Set the selected index to the index of the last matching location that is less than our adjusted location
    this.selectedIndex = this.navIndices.indexOf(currentNav[currentNav.length - 1]);
  }

  jumpTo(event: Event, index: number, section: string) {
    // Prevent this event from propagating
    if (event)
      event.stopPropagation();

    // The target section that we want to scroll to
    var target = "#" + utilities.dataToggle(section);

    // The offset of the target section
    var offset = $(target).offset().top;

    // Index of the offset
    var offsetIndex = this.navIndices.indexOf(offset);

    // We're 'jumping' to a location on the page
    this.jumping = true;

    // Use jQuery to animate a smooth scroll animation with a duration of 400ms to the section's offset
    $("html, body").animate({
      scrollTop: this.calculateOffset(offset, offsetIndex)
    }, 400, () => {

      // Create a timeout 10ms into the future so that we prevent updateNavBar from modifiying the selected index
      setTimeout(() => {

        // We've reached the destination location on the page
        this.jumping = false;

        // Update the nav index
        this.selectedIndex = index;
      }, 10)

    });
  }

  calculateOffset(targetOffset: number, index: number) {
    // Get the current scroll location
    var top = this.document.body.scrollTop;

    // We're already at the offset
    if (top == targetOffset)
      return targetOffset;

    // Current offset is greater than the target, so we're scrolling up
    if (top > targetOffset)
      // When we scroll up our header is revealed
      // If we're scrolling to the first section on the page, our header will be shown but our navbar won't. Subtract the header height from the offset for an aligned transition
      return targetOffset;

    // We're scrolling down, the header will be hidden, we only need to adjust for the navbar.
    if (top < targetOffset)
      return this.floating ? targetOffset - 80 : targetOffset - 160;

    return targetOffset;
  }

}
