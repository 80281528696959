import { Component, OnInit } from "@angular/core";
import { MdDialog } from "@angular/material";
import { ContactFormComponent } from "../../components/contact-form/contact-form.component";
import { FormControl } from "@angular/forms";
import { ActionTypes } from "../../actions/feeds";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../../reducers/index";
import { Router } from "@angular/router";
import { AuthLogoutAction } from "../../actions/auth";
import { AppModule } from "app/app.module";

export function requiredFileType(types: string[]) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      const extension = file.name.split(".")[1].toLowerCase();
      if (types.includes(extension.toLowerCase())) {
        return {
          requiredFileType: true,
        };
      }
      return null;
    }
    return null;
  };
}

@Component({
  selector: "app-cases",
  templateUrl: "./cases.component.html",
  styleUrls: ["./cases.component.scss"],
})
export class CasesComponent implements OnInit {
  name: string;
  user: any;
  domain = AppModule.domain;
  open_status = [ "Ordered", "In Process"];
  total_opencase:string;

  constructor(
    private dialog: MdDialog,
    private actions: Actions,
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit() {
    this.store
      .select((x) => x.mainState.auth)
      .subscribe((x) => {
        this.user = x;
      });
      this.store
      .select((x) =>x.mainState.feeds.feeds)
      .subscribe((x) => {
        if (x.length) {
         this.total_opencase = x.filter(d => d.status.toLowerCase() == 'in process' ||   d.status.toLowerCase() == 'ordered').length;
        }
      });

  }

  openContactUs(event: any) {
    event.stopPropagation();
    this.dialog.open(ContactFormComponent);
  }

  isActiveLink = (activeLink): boolean => {
    const windowLocationPathName = window.location.pathname;
    if (activeLink === "current-cases") {
      return (
        windowLocationPathName === "/cases" ||
        !windowLocationPathName.includes("feed-view:vets")
      );
    } else {
      return windowLocationPathName.includes("vets");
    }
  };

  logout() {
    this.store.dispatch(new AuthLogoutAction(null));
    this.router.navigate(["login"]);
  }

  switchPage(page) {
    this.router.navigate([page]);
  }

}
