import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {AppState} from "../../reducers/index";
import {Store} from "@ngrx/store";
import {AuthResetPasswordAction} from "../../actions/auth";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  model: any = {new_password: "", new_password_repeat: ""};
  token: string;
  firstError: string = "";
  secondError: string = "";

  constructor(activatedRoute: ActivatedRoute, private store: Store<AppState>)
  {
    activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = params['token'];
    });
  }

  ngOnInit() {
  }

  hasUpperCase(str) {
    return (/[A-Z]/.test(str));
  }

  firstChanged(change: string)
  {
    if (change.length < 6)
    {
      this.firstError = "Password must be at least 6 characters with at least one capital.";
    }
    else if (!this.hasUpperCase(change))
    {
      this.firstError = "Password must be at least 6 characters with at least one capital.";
    }
    else
    {
      this.firstError = "";
    }

    if (this.model.new_password != this.model.new_password_repeat)
    {
      this.secondError = "Passwords must match."
    }
  }

  secondChanged(change: string)
  {
    if (this.model.new_password != this.model.new_password_repeat)
    {
      this.secondError = "Passwords must match."
    }
    else
    {
      this.secondError = "";
    }
  }



  submit()
  {
    this.store.dispatch(new AuthResetPasswordAction({newPass: this.model, token: this.token}));
  }
}
