import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  programs: any[] = [
    {
      image: "/assets/img/ut-ccrp.jpg",
      title: "UT Canine Rehab",
      details: "At UT Canine Rehab, our goal and focus is educating professionals who share a common interest in helping animals recover from illness and/or injury and live life with minimal pain.",
      link: "https://www.utvetce.com/canine-rehab-ccrp"
    },
    {
      image: "/assets/img/online-pet-health.jpg",
      title: "Online Pet Health",
      details: "Designed for veterinary rehabilitation therapists, WEBINAR is an online platform for the the Veterinary Rehabilitation Professional providing webinars, instructional videos and business blogs in the field of veterinary rehabilitation",
      link: "https://www.onlinepethealthwebinar.com/"
    },
    {
      image: "/assets/img/aarv.jpg",
      title: "American Association of Rehabilitation Veterinarians",
      details: "The National Association of Veterinary Technicians in America (NAVTA) Committee for Veterinary Technician Specialties (CVTS) governs organizations termed Veterinary Technician Specialties. Veterinary Technician Specialists (VTS) are expert level veterinary technicians who are seeking to learn an advanced level of education and skill.",
      link: "http://rehabvets.org/"
    },
    {
      image: "/assets/img/aprv.jpg",
      title: "Academy of Physical Rehabilitation Veterinary Technicians",
      details: "APRVT's mission is to provide assistance in physical rehabilitation, encouraging veterinary professionals and colleagues to further their education, while improving the quality of animals' lives.",
      link: "http://www.aprvt.com/"
    },
    {
      image: "/assets/img/tripawds.jpg",
      title: "Tripawds",
      details: "The Tripawds mission is to maintain a community of support for those faced with amputation for their dogs, cats and other animals, by providing helpful information and educational resources and creating a platform for discussion.",
      link: "http://tripawds.com/"
    },
    {
      image: "/assets/img/arc.jpg",
      title: "Atlas Rehabilitation for Canines",
      details: "It's with great excitement and anticipation that we introduce our community to Atlas Rehabilitation for Canines (ARC). ARC is Santa Barbara's only stand-alone canine rehabilitation and performance facility led by certified and licensed professionals.",
      link: "https://www.atlasrehabforcanines.com/about-arc-manual-therapy-conference/"
    },
    {
      image: "/assets/img/staar.jpg",
      title: "STAAR Educational Programs",
      details: "The mission of the Symposium on Therapeutic Advances in Animal Rehabilitation (STAAR) is to provide the continuing interactive, practical education for animal rehabilitation professionals.",
      link: "https://www.staarconference.com/"
    },
    {
      image: "/assets/img/be-the-change.jpg",
      title: "Be The Change Volunteers",
      details: "Be The Change Volunteers is a non-profit NGO dedicated to providing educational opportunities around the world. Please see the Info Tab for more information including a list of our schools and learning centers to date!",
      link: "https://www.bethechangevolunteers.org/"
    },
    {
      image: "/assets/img/finleys-barkery.jpg",
      title: "Finley's Barkery",
      details: "As former teachers turned entrepreneurs, we set out to empower people to do what they love, one dog treat at a time. Our recipes were born from the idea that spoiling your pup shouldn't come at the expense of proper nutrition.",
      link: "https://finleysbarkery.com/"
    }
    ];

  constructor()
  {

  }

  test()
  {

  }

  ngOnInit() {
  }
}
