import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-braces',
  templateUrl: './braces.component.html',
  styleUrls: ['./braces.component.css']
})
export class BracesComponent implements OnInit {


  navs = [
    {
      id: 'Knee Brace',
      class: 'leftText_rightImage_wrap3',
      heading: 'Knee Brace',
      content: 'ACL tears are the most common orthopedic problem in dogs. Yes, more than hips! The dog braces apply the corrective forces needed to support proper joint function allowing for more consistent results in your ACL treatment.',
      image: '/assets/img/Dog-wearing-two-knee-braces.jpg',
      link: 'dog-knee-brace',
      link_text: 'Learn more'
    },
    {
      id: 'Ankle Brace',
      class: 'leftText_rightImage_wrap4',
      heading: 'Ankle Brace',
      content: 'Dogs with Achilles ruptures, Degenerative myelopathy (DM), or other neurological issues have hind limb problems. Crouching dogs need a lift.  Dogs dragging their knuckles need to be put in a postition to heal. Dog braces provide that support.',
      image: '/assets/img/German-Shepherd-Dog-Ankle-Brace.jpg',
      link: 'dog-ankle-brace',
      link_text: 'Learn more'
    },
    {
      id: 'Wrist Brace',
      class: 'leftText_rightImage_wrap3',
      heading: 'Wrist Brace',
      content: 'Most commonly used to support carpal hyperextension, some dogs cannot lift their body weight.  Bracing the wrist has also been shown to help some deformity when the wrist is at an angle.',
      image: '/assets/img/Dog-Carpal-Wrist-Braces.jpg',
      link: 'dog-wrist-brace',
      link_text: 'Learn more'
    },
    {
      id: 'Testimonials',
      class: 'leftText_rightImage_wrap4',
      heading: 'Testimonials',
      content: 'You can tell when an animal is happy. You can see it in his eyes, in his ears, in his tail. I look at Caleb and I can just tell that he is happier. Caleb is happier than a pig in slop. -- Greg K. – North Grafton, Massachusetts',
      image: '/assets/img/Dog-Riding-Sidecar.jpg',
      link: 'owner-experiences',
      link_text: 'Read More Experiences'
    },

  ];
  navsn = this.navs.map(x => x.id);

  constructor() { }

  ngOnInit() {
  }

}
