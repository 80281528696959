
import {type} from "ngrx-cli";
import {Action} from "@ngrx/store";
import {ResetPasswordComponent} from "../containers/reset-password/reset-password.component";

export const ActionTypes = {
	Login: type('[Auth] Login'),
	LoginSuccess: type('[Auth] LoginSuccess'),
	LoginFailure: type('[Auth] LoginFailure'),
  Logout: type('[Auth] Logout'),
  RequestPasswordReset: type('[Auth] RequestPasswordReset'),
  RequestPasswordResetSuccess: type('[Auth] RequestPasswordResetSuccess'),
  RequestPasswordResetFailure: type('[Auth] RequestPasswordResetFailure'),
  ResetPassword: type('[Auth] ResetPassword'),
  ResetPasswordSuccess: type('[Auth] ResetPasswordSuccess'),
  ResetPasswordFailure: type('[Auth] ResetPasswordFailure'),
}

export class AuthLoginAction implements Action {
	type = ActionTypes.Login;
	constructor(public payload: any) { }
}

export class AuthLoginSuccessAction implements Action {
	type = ActionTypes.LoginSuccess;
	constructor(public payload: any) { }
}

export class AuthLoginFailureAction implements Action {
	type = ActionTypes.LoginFailure;
	constructor(public payload: any) { }
}

export class AuthLogoutAction implements Action {
  type = ActionTypes.Logout;
  constructor(public payload: any) { }
}

export class AuthRequestPasswordResetAction implements Action {
  type = ActionTypes.RequestPasswordReset;
  constructor(public payload: any) { }
}

export class AuthRequestPasswordResetSuccessAction implements Action {
  type = ActionTypes.RequestPasswordResetSuccess;
  constructor(public payload: any) { }
}

export class AuthRequestPasswordResetFailureAction implements Action {
  type = ActionTypes.RequestPasswordResetFailure;
  constructor(public payload: any) { }
}


export class AuthResetPasswordAction implements Action {
  type = ActionTypes.ResetPassword;
  constructor(public payload: any) { }
}

export class AuthResetPasswordSuccessAction implements Action {
  type = ActionTypes.ResetPasswordSuccess;
  constructor(public payload: any) { }
}

export class AuthResetPasswordFailureAction implements Action {
  type = ActionTypes.ResetPasswordFailure;
  constructor(public payload: any) { }
}

export type Actions = AuthLoginAction | AuthLoginSuccessAction | AuthLoginFailureAction | AuthLogoutAction | AuthRequestPasswordResetAction | AuthRequestPasswordResetSuccessAction | AuthRequestPasswordResetFailureAction | AuthResetPasswordAction | AuthResetPasswordSuccessAction | AuthResetPasswordFailureAction;
