/// <reference types="@types/googlemaps" />

import {
  AfterContentInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppState } from "../../../reducers/index";
import { Store } from "@ngrx/store";
import {
  ActiveFeedsFetchAction,
  FeedsCompleteTaskAction,
  FeedsFetchAction,
} from "../../../actions/feeds";
import { Observable, forkJoin } from "rxjs";
import { Task } from "app/services/api/model/Task";
import { SearchResponse } from "../../../services/api/model/SearchResponse";
import { MdDialog } from "@angular/material";
import { FeedPopupComponent } from "../../../components/feed-popup/feed-popup.component";
import { HasSubscriptions } from "../../../directives/HasSubscriptions";
import { ApiService } from "../../../services/api/api-service";
import { AppModule } from "../../../app.module";
import {
  capitalizeFirstLetter,
  getReadableErrorMessage,
  truncateString,
} from "../../../utilities/utilities";
import { InteractionService } from "../../../services/interaction.service";
import { Activity } from "../../../services/api/model/Activity";
import { AddNoteComponent } from "../../../add-note/add-note.component";
import { AddTaskComponent } from "../../../add-task/add-task.component";
import { VetInfoComponent } from "../../../vet-info/vet-info.component";
import { MergeVetComponent } from "../../../merge-vet/merge-vet.component";
import { LeadFormComponent } from "../../../lead-form/lead-form.component";
import { WebsocketService } from "../../../services/websocket.service";

@Component({
  selector: "app-feeds-new",
  templateUrl: "./feeds-new.component.html",
  styleUrls: ["./feeds-new.component.scss"],
})
export class FeedsNewComponent
  extends HasSubscriptions
  implements OnInit, AfterContentInit
{
  selectedCase: any;
  tasks = [];
  searchResults: any;
  addingNote: boolean;
  activity: { description: string } = <any>{};
  conclusion: string;
  mode: Observable<string>;
  page = 1;
  scrollThreshHoldPercent = 300;
  currentThenBy;
  apiPageSize = 50;
  fetchingFeeds = true;
  vets = [];
  feedTasks = [];
  feedLeads = [];
  nextPageExists = true;
  previousPageExists = true;
  selectedVetActivities = [];
  selectedVetCases = [];
  selectedCaseManagers = [];
  domain = AppModule.domain;
  isEditActivated = false;
  vetInfo: any = {};
  errorMessage = "";
  showSuccessMessage = false;
  availableTiers = [
    { id: 1, name: "T1" },
    { id: 2, name: "T2" },
    { id: 3, name: "T3" },
  ];
  availableCaseManagers = [];
  availableCaseManagersForTask = [];
  noteForVetAdded = false;
  private currentUserPrivilege: any = {};
  showNoteRelatedWarningMessage = false;
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    text: "Case Manager",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableSearchFilter: true,
    classes: "case-manager-select",
    labelKey: "username",
    enableCheckAll: false,
    maxHeight: 150,
  };
  availableCollaborators = [];
  availableCases = [];
  inProgressCases = [];
  @ViewChild("gmap") gmapElement: any;
  map: google.maps.Map;
  coOrdinateCollection = [];
  zipCode = " Santa Clarita, CA 91355, USA";
  latitude: number;
  longitude: number;
  noMapFoundMessage = "";
  private nearByVets = [];
  private markers = {};
  showVetMergeMessage = false;
  vetLatitudeLongitude = [];
  processedCase = [];
  showLeadDeleteMessage = false;
  allVets=[];
  finishedCaseStatus = [
    "brace shipped",
    "order cancelled",
    "adjustment shipped",
    "refurbish shipped",
    "completed",
  ];
  showCaseMergeMessage = false;
  vetFetched = false;
  milesRange:any;
  milesRangeValue:any = 'all';

  @ViewChild("scrollDiv") scrollDiv: ElementRef;

  constructor(
    private store: Store<AppState>,
    private dialog: MdDialog,
    private apiService: ApiService,
    private interactionService: InteractionService,
    private websocketService: WebsocketService
  ) {
    super();
    // this.tasks = this.store.select(x => x.mainState.feeds.tasks);

    this.store
      .select((storeData) => storeData.mainState.auth.privilege)
      .pipe(this.getTakeUntil())
      .subscribe((privilegeData: any) => {
        this.currentUserPrivilege = privilegeData;
      });
    this.searchResults = this.store.select((x) => {
      this.currentThenBy = x.mainState.feeds.thenBy;
      if (x.mainState.feeds.mode === "search") {
        if (x.mainState.feeds.thenBy == "doctors") {
          this.vets = x.mainState.feeds.internalSearchResults;
          this.allVets = this.vets;
          this.vetFetched = false;
        }
        return x.mainState.feeds.searchResults;
      }
      if (x.mainState.feeds.mode === "feeds") {
        if (x.mainState.feeds.thenBy === "active") {
          const activeFeedPaginationData: any =
            x.mainState.feeds && x.mainState.feeds.activeFeedPaginationData
              ? x.mainState.feeds.activeFeedPaginationData
              : {};
          this.updatePaginationData(
            activeFeedPaginationData.next_page,
            activeFeedPaginationData.prev_page
          );
          return x.mainState.feeds.activeCaseFeeds;
        }
        //fetched vet after vet search form is cleared
        if (
          x.mainState.feeds.thenBy === "doctors" &&
          x.mainState.feeds.internalSearchResults.length == 0 &&
          x.mainState.feeds.searchType == "Vet" &&
          !this.vetFetched
        ) {
          this.selectedCase = null;
          this.getVets(null, true);
          this.vetFetched = true;
        }
        const feedPaginationData: any =
          x.mainState.feeds && x.mainState.feeds.feedPaginationData
            ? x.mainState.feeds.feedPaginationData
            : {};
        // console.log(this.currentThenBy);
        if (this.currentThenBy == "all") {
          this.updatePaginationData(
            feedPaginationData.next_page,
            feedPaginationData.prev_page
          );
        }
        return x.mainState.feeds.feeds;
      }
    });
    this.mode = this.store.select((x) => x.mainState.feeds.mode);
    this.store
      .select((x) => x.mainState.feeds.fetching)
      .subscribe((fetching) =>
        setTimeout(() => {
          this.fetchingFeeds = fetching;
        }, 1000)
      );
    this.store
      .select((storeFeed) => storeFeed.mainState.feeds.thenBy)
      .pipe(this.getTakeUntil())
      .subscribe((thenBy) => {
        this.select({}, true);
        this.page = 1;
        this.currentThenBy = thenBy;
        this.fetchFeedInformationDynamically();
      });
    this.websocketService.getData("dogupdated").subscribe((response) => {
      // console.log('response',response);
      if (response && this.searchResults) {
        this.searchResults.subscribe((data) => {
          if (data.length) {
            let caseKey = data.findIndex(
              (x) => x.order_id == response.order_id
            );
            data[caseKey].dog.name = response.name;
            data[caseKey].bracing_goal = response.bracing_goal;
            data[caseKey].medical_notes = response.medical_notes;
          }
        });
      }
    });

    this.websocketService.getData("caseadded").subscribe((response) => {
      if (response) {
        if (typeof this.searchResults !== "undefined") {
          this.searchResults.subscribe((data) => {
            if (data.length) {
              data.unshift(response);
            }
          });
        }
      }
    });
    this.websocketService.getData("ownerupdated").subscribe((response) => {
      if (response) {
        if (typeof this.searchResults !== "undefined") {
          this.searchResults.subscribe((data) => {
            if (data.length) {
              let caseKey = data.findIndex((x) => x.owner.id == response.id);
              data[caseKey].owner.first_name = response.first_name;
              data[caseKey].owner.last_name = response.last_name;
              data[caseKey].owner.email = response.email;
            }
          });
        }
      }
    });
  }

  updatePaginationData = (nextPage = 0, previousPage = 0) => {
    this.nextPageExists = !!nextPage;
    this.previousPageExists = !!previousPage;
  };

  ngOnInit() {
    // this.setTaskRelatedData();
    this.setInteractions();
    // this.fetchFeedInformationDynamically();
    this.apiService
      .getUsers("case_manager")
      .pipe(this.getTakeUntil())
      .subscribe((userData) => {
        if (userData.data) {
          this.availableCaseManagers = userData.data.map((singleUser) => {
            return {
              id: singleUser.id,
              username: singleUser.full_name
                ? singleUser.full_name
                : singleUser.username,
            };
          });
        }
      });
    this.apiService
      .getNearByVets(34.4198555, -118.5462873)
      .pipe(this.getTakeUntil())
      .subscribe((vetData) => {
        if (vetData.data) {
          this.vetLatitudeLongitude = vetData.data;
          return vetData.data.map((vetDetail) => {
            return {
              latitude: vetDetail.latitude,
              longitude: vetDetail.longitude,
            };
          });
        }
      });
  }

  addEnterButtonEventListener = (): void => {
    const addressInput = document.getElementById("zip_code");
    if (addressInput) {
      addressInput.addEventListener("keyup", (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          document.getElementById("go-btn").click();
        }
      });
    }
  };

  getLatLngByZipcode(zipcode) {
    const geocoder = new google.maps.Geocoder();
    const address = zipcode;
    geocoder.geocode({ address: "zipcode " + address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        this.latitude = results[0].geometry.location.lat();
        this.longitude = results[0].geometry.location.lng();
      } else {
        this.noMapFoundMessage = "Invalid address.";
      }
    });
  }

  getGeoLocationDetailsByZipcode(zipcode):any {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      const address = zipcode;
      geocoder.geocode({ address: "zipcode " + address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(results[0].formatted_address.toLowerCase());
        } else {
          reject(false);
        }
      });
    });
  }

  ngAfterContentInit() {
    this.initiateGoogleMap();
  }

  zipCodeEntered = () => {
    this.noMapFoundMessage = "";
    this.coOrdinateCollection = [];
    this.getLatLngByZipcode(this.zipCode);
    setTimeout(() => {
      if (this.latitude && this.longitude) {
        this.apiService
          .getNearByVets(this.latitude, this.longitude, this.zipCode)
          .pipe(this.getTakeUntil())
          .subscribe((vetInfo) => {
            this.vets = vetInfo.data;
            this.allVets = this.vets;
            if (vetInfo.data.length) {
              this.nearByVets = vetInfo.data;
              this.addMarker(vetInfo.data);
              this.milesRangeValue = 'all';
              this.generateMileRange();
            } else {
              this.noMapFoundMessage = "No Nearby Vet/s found.";
            }
          });
      }
    }, 1000);
  };

  vetShowOnMapUpdated = (vet) => {
    if (vet.show_on_map) {
      this.markers[vet.id].setMap(this.map);
    } else {
      this.markers[vet.id].setMap(null);
    }
  };

  addMarker = (vets) => {
      this.getGeoLocationDetailsByZipcode(this.zipCode).then(data=>{
         let geoLocationDetails = data;
         vets.forEach((vetEntity) => {
          let checkStateExist = false;
          if (vetEntity.state) {
            checkStateExist = geoLocationDetails.includes(vetEntity.state.toLowerCase());
          }
           const computedLatLong = new google.maps.LatLng(
             vetEntity.latitude,
             vetEntity.longitude
           );
           const icon= checkStateExist ? 'blue-dot.png' : 'red-dot.png';
           const marker = new google.maps.Marker({
             position: computedLatLong,
             map: this.map,
             icon: {
               url: "http://maps.google.com/mapfiles/ms/icons/"+icon,
             },
             title: capitalizeFirstLetter(
               vetEntity.name + ", " + vetEntity.doctor_name
             ),
           });
           marker.addListener("click", () =>
             this.dialog.open(VetInfoComponent, { data: vetEntity })
           );
           if (vetEntity.show_on_map) {
             marker.setMap(this.map);
           }
           this.map.panTo(marker.getPosition());
           this.markers[vetEntity.id] = marker;
           if (!vetEntity.show_on_map) {
             this.vetShowOnMapUpdated(vetEntity);
           }
         });
      });
  };

  initiateGoogleMap = () => {
    this.vets = [];
    setTimeout(() => {
      const mapProp = {
        center: new google.maps.LatLng(41.4925, 99.9018),
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      if (this.gmapElement) {
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
      }
    }, 500);
  };

  setInteractions = () => {
    this.interactionService.sharedOwnerAdded
      .pipe(this.getTakeUntil())
      .subscribe((isOwnerAdded) => {
        if (isOwnerAdded === true) {
          this.fetchFeedInformationDynamically();
          this.interactionService.setOwnerAddedInfo(false);
        }
      });
    this.interactionService.sharedTaskAdded
      .pipe(this.getTakeUntil())
      .subscribe((isTagAdded) => {
        if (isTagAdded === "added") {
          this.dialog.closeAll();
        }
        if (isTagAdded === "updated" || isTagAdded === "added") {
          this.getTasks();
          this.interactionService.setTaskAdded(false);
        }
      });
    this.interactionService.shouldUpdateCaseFeed
      .pipe(this.getTakeUntil())
      .subscribe((shouldUpdateCaseFeed) => {
        if (shouldUpdateCaseFeed === true) {
          this.fetchFeedInformationDynamically();
          this.interactionService.setShouldUpdateCaseFeed(false);
        }
      });
    this.interactionService.shouldCloseModal
      .pipe(this.getTakeUntil())
      .subscribe((shouldCloseModal) => {
        if (shouldCloseModal === true) {
          this.dialog.closeAll();
        }
      });
    this.interactionService.sharedNoteAdded
      .pipe(this.getTakeUntil())
      .subscribe((isNoteAdded) => {
        if (isNoteAdded === true) {
          this.noteForVetAdded = true;
          this.resetResult();
          this.dialog.closeAll();
          this.fetchVetSummary(this.selectedCase);
        }
      });
    this.interactionService.shouldUpdateWipListing
      .pipe(this.getTakeUntil())
      .subscribe((shouldUpdateWipListing) => {
        if (shouldUpdateWipListing === true && this.currentThenBy === "wip") {
          this.getWipCases();
          this.interactionService.setShouldUpdateWipListing(false);
        }
      });

    this.interactionService.sharedVetMerged
      .pipe(this.getTakeUntil())
      .subscribe((isVetMerged) => {
        if (isVetMerged === true) {
          this.showVetMergeMessage = true;
          this.fetchFeedInformationDynamically();
          this.dialog.closeAll();
          setTimeout(() => (this.showVetMergeMessage = false), 3000);
        }
      });
    //show case merged message
    this.interactionService.sharedCaseMerged
      .pipe(this.getTakeUntil())
      .subscribe((isCaseMerged) => {
        if (isCaseMerged === true) {
          this.showCaseMergeMessage = true;
          this.fetchFeedInformationDynamically();
          this.dialog.closeAll();
          setTimeout(() => (this.showCaseMergeMessage = false), 3000);
        }
      });

    this.interactionService.shouldUpdateLeadsListing
      .pipe(this.getTakeUntil())
      .subscribe((leadAction) => {
        if (leadAction === "lead_created" || leadAction === "lead_updated") {
          this.fetchFeedInformationDynamically();

          if (leadAction === "lead_created") {
            this.dialog.closeAll();
          }
        }
        //so case delete message
        if (leadAction == "lead_deleted") {
          this.showLeadDeleteMessage = true;
          setTimeout(() => (this.showLeadDeleteMessage = false), 3000);
        }
      });
  };

  resetResult = () => {
    setTimeout(() => {
      this.noteForVetAdded = false;
      this.showSuccessMessage = false;
      this.showNoteRelatedWarningMessage = false;
    }, 3000);
  };

  onItemSelect(item: any, vet) {
    this.apiService
      .addVetManagers({ vet_id: vet.id, case_manager_id: item.id })
      .pipe(this.getTakeUntil())
      .subscribe(() => {});
  }

  OnItemDeSelect(item: any, vet) {
    this.apiService
      .disAssociateVetManager(vet.id, item.id)
      .pipe(this.getTakeUntil())
      .subscribe((data) => {});
  }

  onSelectAll(items: any, vet) {
    console.log(items);
  }

  onDeSelectAll(items: any, vet) {
    console.log(items);
  }

  getVets = (filterParam = null, forceFetch = false) => {
    if (!this.vets.length || forceFetch) {
      this.apiService
        .fetchVets(this.page, this.apiPageSize, filterParam)
        .subscribe((vetData: { data; total_count; next_page; prev_page }) => {
          this.vets = vetData.data;
          this.setSelected();
          this.fetchingFeeds = false;
          this.updatePaginationData(vetData.next_page, vetData.prev_page);
        });
    }
  };

  getTasks = () => {
    this.apiService
      .fetchPaginatedTasks(this.page, this.apiPageSize)
      .subscribe((feedTaskData) => {
        this.feedTasks = feedTaskData.data;
        this.fetchingFeeds = false;
        this.updatePaginationData(
          feedTaskData.next_page,
          feedTaskData.prev_page
        );
      });
  };

  getLeads = () => {
    this.apiService.fetchPaginatedLeads(this.page, 50).subscribe((leadData) => {
      if (leadData) {
        this.feedLeads = leadData.data.filter((item) => {
          return item.case_id == null || item.case_id == "";
        });
        this.fetchingFeeds = false;
        this.updatePaginationData(leadData.next_page, leadData.prev_page);
      }
    });
  };

  getWipCases = () => {
    this.apiService
      .fetchPaginatedFeedDynamically(this.page, this.apiPageSize, "In Process")
      .subscribe((feedWipData) => {
        this.inProgressCases = feedWipData.data;
        this.fetchingFeeds = false;
        this.updatePaginationData(feedWipData.next_page, feedWipData.prev_page);
      });
  };

  setSelected = () => {
    if (this.currentUserPrivilege.is_admin && this.vets) {
      this.vets.forEach((singleVet, index) => {
        const caseManagers = singleVet.case_managers.map((caseManager) => {
          return {
            id: caseManager.id,
            username: truncateString(
              caseManager.full_name
                ? caseManager.full_name
                : caseManager.username,
              12
            ),
          };
        });
        this.selectedItems[index] = caseManagers;
      });
    } else if (this.vets) {
      this.vets.forEach((singleVet, index) => {
        this.selectedItems[index] = [];
      });
    }
  };

  fetchFeedInformationDynamically = () => {
    switch (this.currentThenBy) {
      case "all":
      case "lead":
        this.store.dispatch(
          new FeedsFetchAction({
            page: this.page,
            pageSize: this.apiPageSize,
            init: true,
          })
        );
        break;
      case "active":
        // this.store.dispatch(new ActiveFeedsFetchAction({ page: this.page, pageSize: this.apiPageSize, init: true }));
        break;
      case "doctors":
        this.getVets(null, true);
        break;
      case "tasks":
        this.getTasks();
        break;
      case "leads":
        this.getLeads();
        break;
      case "wip":
        this.getWipCases();
        break;
      case "locate":
        this.initiateGoogleMap();
        this.zipCodeEntered();
        this.addEnterButtonEventListener();
        break;
    }
  };

  select(feed: any, shouldClose = false) {
    this.isEditActivated = false;
    if (shouldClose) {
      this.selectedCase = null;
      return;
    }

    if (!(this.selectedCase && this.selectedCase.id === feed.id)) {
      this.apiService
        .fetchVetSummary(feed.id)
        .pipe(this.getTakeUntil())
        .subscribe((vetData: { data }) => {
          this.selectedVetActivities = vetData.data.activities;
          this.selectedVetCases = vetData.data.cases;
          if (this.selectedVetCases.length) {
            this.selectedVetCases.forEach((element, index) => {
              this.apiService.fetchOwner(element.id).subscribe((res) => {
                if (res.data.owner) {
                  let caseOwner = res.data.owner;
                  this.selectedVetCases[index].owner_name = caseOwner.last_name;
                }
              });
            });
          }
        });
    }
    this.selectedCase = this.selectedCase == feed ? null : feed;
  }

  hasProcessing(task: Task) {
    return this.selectedVetCases.some(
      (x) => x.status.toLowerCase() == "in process"
    );
  }

  getProcessing() {
    let processedCase = this.selectedVetCases.filter(
      (x) => x.status.toLowerCase() == "in process"
    );
    return processedCase;
  }

  hasFinished(task: Task) {
    return this.selectedVetCases.some(
      (x) => x.status.toLowerCase() !== "in process"
    );
  }

  getFinished(task: Task) {
    return this.selectedVetCases.filter(
      (x) => x.status.toLowerCase() !== "in process"
    );
  }

  addNote() {
    this.addingNote = true;
  }

  getDate(date: string) {
    return new Date(date);
  }

  complete() {
    if (!this.conclusion || this.conclusion == "") {
      alert("Please select a conclusion for this Task");
      return;
    }

    this.store.dispatch(
      new FeedsCompleteTaskAction({
        id: this.selectedCase.id,
        conclusion: this.conclusion,
      })
    );
  }

  getIcon(result: SearchResponse) {
    switch (result.object_type) {
      case "dog":
        return "/assets/img/dog.svg";
      case "vet":
        return "/assets/img/vet.svg";
      case "order":
        return "/assets/img/order.svg";
      case "case":
      default:
        return "/assets/img/case.svg";
    }
  }

  openPopup(selectedResult: any) {
    this.dialog.open(FeedPopupComponent, { data: selectedResult });
  }

  handleScroll = () => {
    console.log("scroll handler");
  };

  navigationButtonClicked = (
    buttonState,
    isClickedFromBottom = false
  ): void => {
    this.fetchingFeeds = true;
    this.page = buttonState === "next" ? this.page + 1 : this.page - 1;
    this.fetchFeedInformationDynamically();
    if (isClickedFromBottom) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  getFormattedName = (caseEntity): string => {
    if (caseEntity.object_type === "dog") {
      return caseEntity.name;
    }
    let formattedName =
      (caseEntity.order_id ? caseEntity.order_id : "-") +
      " " +
      (caseEntity.dog && caseEntity.dog.name ? caseEntity.dog.name : "-") +
      " " +
      (caseEntity.owner && caseEntity.owner.last_name
        ? caseEntity.owner.last_name
        : "-");

    if (formattedName.includes("- -")) {
      formattedName = "-";
    }

    return formattedName;
  };

  getVetPicture = (vet): string => {
    return vet.picture && vet.picture[0]
      ? this.domain + vet.picture[0]
      : "https://picsum.photos/120/120?image=237";
  };

  editVet = (vet) => {
    this.vetInfo = vet;
    this.vetInfo.is_shipping_label_only =
      vet.shipping_label_only.toLowerCase() === "yes";
    this.isEditActivated = true;
  };

  fetchVetSummary = (vet) => {
    if (vet) {
      this.apiService
        .fetchVetSummary(vet.id)
        .pipe(this.getTakeUntil())
        .subscribe((vetData: { data }) => {
          this.selectedVetActivities = vetData.data.activities;
          this.selectedVetCases = vetData.data.cases;
          this.selectedCaseManagers = vetData.data.case_managers;
          this.resetResult();
        });
    }
  };

  saveVetInfo = () => {
    this.vetInfo.shipping_label_only = this.vetInfo.is_shipping_label_only
      ? "Yes"
      : "No";
    this.apiService
      .updateVet(this.vetInfo.id, this.vetInfo)
      .pipe(this.getTakeUntil())
      .subscribe((vetUpdateData) => {
        if (vetUpdateData.status === 1) {
          this.fetchVetSummary(this.vetInfo);
          this.isEditActivated = false;
          this.showSuccessMessage = true;
          this.resetResult();
        } else {
          this.errorMessage = getReadableErrorMessage(vetUpdateData);
          setTimeout(() => (this.errorMessage = ""), 5000);
        }
      });
  };

  tierChanged = (event, vet) => {
    const formattedVetInfo = vet;
    formattedVetInfo.tier = event.target.value;
    this.apiService.updateVet(vet.id, formattedVetInfo).subscribe(() => {});
  };

  addNotePopUp = () => {
    this.interactionService.setNoteAddedInfo(false);
    if (this.selectedCase && this.selectedCase.credential) {
      this.dialog.open(AddNoteComponent, { data: this.selectedCase });
    } else {
      this.showNoteRelatedWarningMessage = true;
      this.resetResult();
    }
  };

  openTaskModal = async (taskData = null) => {
    let availableCases = await this.getAvailableCases();
    let availableCaseManagersForTask =
      await this.getAvailableCaseManagersForTask();
    let availableCollaborators = await this.getAvailableCollaborators();
    this.dialog.open(AddTaskComponent, {
      data: {
        taskDetail: taskData,
        availableCases: availableCases,
        availableOwners: availableCaseManagersForTask,
        availableCollaborators: availableCollaborators,
      },
      backdropClass: "add-task-modal",
      width: "60rem",
    });
  };

  openLeadForm = async (leadDetail, view) => {
    let availableCases = await this.getAvailableCases();
    this.dialog.open(LeadFormComponent, {
      data: {
        lead: leadDetail,
        viewType: view,
        availableCases: availableCases,
      },
      backdropClass: "add-task-modal",
      width: "60rem",
    });
  };

  /**
   * Open lead email in email details page
   * @param leadDetail Object
   */
  openLeadDetails = (leadDetail) => {
    if (leadDetail.detail != "") {
      if (typeof leadDetail.detail === "string") {
        try {
          let lead = JSON.parse(leadDetail.detail);
          if (lead.url) {
            window.open(lead.url);
          }
        } catch (e) {}
      }
    }
  };
  setTaskRelatedData = () => {
    // if (!this.availableCases.length && !this.availableCaseManagersForTask.length && !this.availableCollaborators.length) {
    forkJoin([
      this.apiService.getUsers(),
      this.apiService.getCompactCases(),
      this.apiService.getUsers("case_manager"),
    ]).subscribe((data: any) => {
      if (!this.availableCollaborators.length) {
        this.availableCollaborators = data[0].data.map((owner) => {
          owner.name = truncateString(owner.username, 12);
          return owner;
        });
      }
      if (!this.availableCases.length) {
        this.availableCases = data[1].data.map((singleCase) => {
          if (singleCase.dog_name) {
            singleCase.name = truncateString(
              `${singleCase.order_id}-${singleCase.dog_name}-${singleCase.owner_last_name}`,
              18
            );
          } else {
            singleCase.name = truncateString(
              `${singleCase.order_id}-${singleCase.owner_last_name}`,
              18
            );
          }
          return singleCase;
        });
      }
      if (!this.availableCaseManagersForTask.length) {
        this.availableCaseManagersForTask = data[2].data.map((caseManager) => {
          caseManager.name = truncateString(
            caseManager.full_name
              ? caseManager.full_name
              : caseManager.username,
            12
          );
          return caseManager;
        });
      }
    });
    // }
  };

  getFormattedActivities = (activities): Array<Activity> => {
    const noteActivities = [];
    const otherActivities = [];
    activities.forEach((activity) => {
      if (activity.event_type.name === "Note") {
        noteActivities.push(activity);
      } else {
        otherActivities.push(activity);
      }
    });
    // console.log([...noteActivities, ...otherActivities]);
    return [...noteActivities, ...otherActivities];
  };

  editVetCancelled = (): void => {
    this.isEditActivated = false;
  };

  truncateString = (stringToTruncate, limit) =>
    truncateString(stringToTruncate, limit);

  mergeVetSelected = (currentVet) => {
    this.dialog.open(MergeVetComponent, {
      data: {
        currentVet,
      },
    });
  };

  getAvailableCollaborators() {
    return new Promise((resolve, reject) => {
      let availableCollaborators = [];
      this.apiService.getUsers().subscribe((data) => {
        availableCollaborators = data.data.map((owner) => {
          owner.name = truncateString(owner.username, 12);
          return owner;
        });
        resolve(availableCollaborators);
      });
    });
  }

  getAvailableCases() {
    return new Promise((resolve, reject) => {
      let availableCases = [];
      this.apiService.getCompactCases().subscribe((data) => {
        availableCases = data.data.map((singleCase) => {
          if (singleCase.dog_name) {
            singleCase.name = truncateString(
              `${singleCase.order_id}-${singleCase.dog_name}-${singleCase.owner_last_name}`,
              18
            );
          } else {
            singleCase.name = truncateString(
              `${singleCase.order_id}-${singleCase.owner_last_name}`,
              18
            );
          }
          return singleCase;
        });
        resolve(availableCases);
      });
    });
  }

  getAvailableCaseManagersForTask() {
    return new Promise((resolve, reject) => {
      let availableCaseManagersForTask = [];
      this.apiService.getCompactCases().subscribe((data) => {
        availableCaseManagersForTask = data.data.map((caseManager) => {
          caseManager.name = truncateString(
            caseManager.full_name
              ? caseManager.full_name
              : caseManager.username,
            12
          );
          return caseManager;
        });
        resolve(availableCaseManagersForTask);
      });
    });
  }

  /*
  Filter vet near by miles ranger
  */
  updateMilesRange = ()=>{
    //convert distance from km to miles
    let mile =  0.621371;
    let filterVets = [];
    if (this.milesRangeValue != "all") {
      filterVets = this.allVets.filter(vet=>{return Math.round(vet.distance * mile) <= this.milesRangeValue});
    } else {
      filterVets = this.allVets;
    }
    this.vets = filterVets; 
    this.addMarker(this.vets);
 
  }


  generateMileRange()
  {
    let range = [];
    let mile =  0.621371;
    this.allVets.forEach(element=>{
      let distance = Math.round(element.distance * mile) 
      if (element.distance >= 100) {
        range.push(100);
      } else if(element.distance >= 75){
        range.push(75);
      } else if(element.distance >= 50){
        range.push(50); 
      }else if(element.distance >= 25){
        range.push(25);
      }else if(element.distance >= 15){
        range.push(15);  
      }else if(element.distance >= 10){
        range.push(15);           
      } else if(element.distance >= 5){
        range.push(5);
      }
    })
    let uniqueRange = range.filter((v, i, a) => a.indexOf(v) === i);
    if (uniqueRange) {
      this.milesRange = uniqueRange.sort(function(a, b){return a-b});
    }
  }


}
function data(data: any, arg1: (any: any) => void) {
  throw new Error("Function not implemented.");
}

