import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { Order, OrderInfo } from "../../services/api/model/Order";
import { AppState } from "../../reducers/index";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {

  order: Observable<OrderInfo>;
  invalidproduct = false;

  constructor(private store: Store<AppState>, private router: Router) {
    this.order = store.select(x => x.mainState.order.orderInfo);
    if (this.router.getCurrentNavigation().extras.state) {
      this.invalidproduct = this.router.getCurrentNavigation().extras.state.invalidproduct;
    }
  }
  
  ngOnInit() {
    //redirect to login page if order is not available
    if (!this.invalidproduct) {
      this.order.subscribe(res => {
        if (res == null) {
          this.router.navigate(["/login"]).then();
        }
      })
    }

  }


}
