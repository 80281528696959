import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from '@angular/router';
import { MdAutocompleteModule, MdDialogModule } from "@angular/material";
import { DatePipe } from '@angular/common';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { MetaModule } from "@ngx-meta/core";
import { routes } from './routes';
import { reducer } from "./reducers/index";
import { AuthService } from "./effects/auth";
import { AppService } from "app/effects/app";
import { DataTogglePipe } from 'app/pipes/data-toggle.pipe';
import { AppComponent } from './app.component';
import { HomeComponent } from './containers/home/home.component';
import { AboutComponent } from './containers/about/about.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BracesComponent } from './containers/braces/braces.component';
import { PageNavComponent } from './components/page-nav/page-nav.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { CasesComponent } from './containers/cases/cases.component';
import { PricingComponent } from './containers/pricing/pricing.component';
import { ResourcesComponent } from './containers/resources/resources.component';
import { KneeBraceComponent } from './containers/braces/knee-brace/knee-brace.component';
import { FaqComponent } from './containers/faq/faq.component';
import { AnkleBraceComponent } from './containers/braces/ankle-brace/ankle-brace.component';
import { WristBraceComponent } from './containers/braces/wrist-brace/wrist-brace.component';
import { BraceSubpageComponent } from './components/brace-subpage/brace-subpage.component';
import { LoginFormComponent } from "./components/login-form/login-form.component";
import { ApiService } from "app/services/api/api-service";
import { AuthGuard } from "app/services/auth-guard";
import { FeedService } from "app/effects/feeds";
import { FeedComponent } from './containers/cases/feed/feed.component';
import { FeedsComponent } from './containers/cases/feeds/feeds.component';
import { VeterinariansComponent } from "./containers/veterinarians/veterinarians.component";
import { VeterinarianSuccessStoriesComponent } from "./containers/veterinarian-success-stories/veterinarian-success-stories.component";
import { VetsComponent } from './containers/cases/vets/vets.component';
import { VetComponent } from './containers/cases/vet/vet.component';
import { InfiniteScrollDirective } from './directives/infinite-scroll/infinite-scroll.directive';
import { SupportComponent } from './containers/support/support.component';
import { CaseStudiesComponent } from './containers/case-studies/case-studies.component';
import { OwnerExperiencesComponent } from './containers/owner-experiences/owner-experiences.component';
import { TermsOfServiceComponent } from './containers/terms-of-service/terms-of-service.component';
import { MediaService } from "./directives/media-service";
import { VideosComponent } from './containers/videos/videos.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { SafeHtmlPipe, SafePipe } from "./pipes/safe-pipe";
import { OrderComponent } from './containers/order/order.component';
import { SliderComponent } from './components/slider/slider.component';
import { OrderService } from "app/effects/order";
import { ResearchComponent } from './containers/research/research.component';
import { ResearchStudyComponent } from './containers/research-study/research-study.component';
import { JourneyComponent } from './containers/journey/journey.component';
import { TeamComponent } from './containers/team/team.component';
import { ThankYouComponent } from './containers/thank-you/thank-you.component';
import { LandingPageComponent } from './containers/landing-page/landing-page.component';
import { FileUploadComponent } from "app/components/file-upload/file-upload.component";
import { ThankYouContactComponent } from "app/containers/thank-you-contact/thank-you-contact.component";
import { ThankYouButtonComponent } from "app/containers/thank-you-button/thank-you-button.component";
import { environment2 } from "../environments/environment";
import { MedicalFormsComponent } from "./containers/medical-forms/medical-forms.component";
import { NewOrderComponent } from './containers/new-order/new-order.component';
import { ResetPasswordComponent } from "app/containers/reset-password/reset-password.component";
import { CasesNewComponent } from "app/containers/cases-new/cases-new.component";
import { FeedsNewComponent } from './containers/cases-new/feeds-new/feeds-new.component';
import { FeedSearchComponent } from './components/feed-search/feed-search.component';
import { FeedPopupComponent } from './components/feed-popup/feed-popup.component';
import { FeedPopupMedicalComponent } from './components/feed-popup/content/feed-popup-medical/feed-popup-medical.component';
import { FeedPopupUserComponent } from './components/feed-popup/content/feed-popup-user/feed-popup-user.component';
import { FeedPopupDogsComponent } from './components/feed-popup/content/feed-popup-dogs/feed-popup-dogs.component';
import { FeedPopupVetComponent } from './components/feed-popup/content/feed-popup-vet/feed-popup-vet.component';
import { FeedPopupOrdersComponent } from './components/feed-popup/content/feed-popup-orders/feed-popup-orders.component';
import { FeedPopupTasksComponent } from './components/feed-popup/content/feed-popup-tasks/feed-popup-tasks.component';
import { FeedPopupBraceSpecsComponent } from './components/feed-popup/content/feed-popup-brace-specs/feed-popup-brace-specs.component';
import { FeedPopupFollowUpComponent } from './components/feed-popup/content/feed-popup-follow-up/feed-popup-follow-up.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './services/Auth-Interceptor';
import { AddNoteComponent } from './add-note/add-note.component';
import { InteractionService } from './services/interaction.service';
import { AddTagComponent } from './add-tag/add-tag.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { WipTableComponent } from './wip-table/wip-table.component';
import { VetInfoComponent } from './vet-info/vet-info.component';
import { AngularMultiSelectModule } from 'angular4-multiselect-dropdown/angular4-multiselect-dropdown';
import { MergeVetComponent } from './merge-vet/merge-vet.component';
import { DeleteConfirmation } from './components/delete-confirmation/delete-confirmation.component';
import { SaveConfirmation } from './components/save-confirmation/save-confirmation.component'
import { ClientPostOrderComponent } from './client-post-order/client-post-order.component';
import { CaseMergePopupComponent } from './case-merge-popup/case-merge-popup.component';

import { ShippingFormComponent } from './shipping-form/shipping-form.component';
import { LeadsComponent } from './leads/leads.component';
import { LeadFormComponent } from './lead-form/lead-form.component';
import { WebsocketService } from './services/websocket.service'

@NgModule({
  declarations: [
    AppComponent,
    DataTogglePipe,
    HomeComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
    BracesComponent,
    PageNavComponent,
    ItemListComponent,
    CasesComponent,
    VeterinariansComponent,
    VeterinarianSuccessStoriesComponent,
    PricingComponent,
    ResourcesComponent,
    KneeBraceComponent,
    FaqComponent,
    AnkleBraceComponent,
    WristBraceComponent,
    BraceSubpageComponent,
    LoginFormComponent,
    FeedComponent,
    FeedsComponent,
    VetsComponent,
    VetComponent,
    InfiniteScrollDirective,
    SupportComponent,
    CaseStudiesComponent,
    OwnerExperiencesComponent,
    TermsOfServiceComponent,
    MediaService,
    VideosComponent,
    ContactFormComponent,
    VideoPlayerComponent,
    SafePipe,
    SafeHtmlPipe,
    OrderComponent,
    SliderComponent,
    ResearchComponent,
    ResearchStudyComponent,
    JourneyComponent,
    TeamComponent,
    ThankYouComponent,
    LandingPageComponent,
    FileUploadComponent,
    ThankYouContactComponent,
    ThankYouButtonComponent,
    MedicalFormsComponent,
    NewOrderComponent,
    ResetPasswordComponent,
    CasesNewComponent,
    FeedsNewComponent,
    FeedSearchComponent,
    FeedPopupComponent,
    FeedPopupMedicalComponent,
    FeedPopupUserComponent,
    FeedPopupDogsComponent,
    FeedPopupVetComponent,
    FeedPopupOrdersComponent,
    FeedPopupTasksComponent,
    FeedPopupBraceSpecsComponent,
    FeedPopupFollowUpComponent,
    AddNoteComponent,
    AddTagComponent,
    AddTaskComponent,
    WipTableComponent,
    VetInfoComponent,
    MergeVetComponent,
    DeleteConfirmation,
    SaveConfirmation,
    ClientPostOrderComponent,
    CaseMergePopupComponent,
    ShippingFormComponent,
    LeadsComponent,
    LeadFormComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot({ mainState: reducer }),
    EffectsModule.forRoot([AuthService, FeedService, OrderService, AppService]),
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MdDialogModule,
    MdAutocompleteModule,
    MetaModule.forRoot(),
    AngularMultiSelectModule,
  ],
  providers: [
    ApiService,
    AuthGuard,
    InteractionService,
    WebsocketService,
    DatePipe, {
      provide: 'externalUrlRedirectResolver',
      useValue: AppModule.route
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    LoginFormComponent,
    ContactFormComponent,
    VideoPlayerComponent,
    FileUploadComponent,
    FeedPopupComponent,
    AddNoteComponent,
    AddTagComponent,
    AddTaskComponent,
    VetInfoComponent,
    MergeVetComponent,
    DeleteConfirmation,
    SaveConfirmation,
    CaseMergePopupComponent,
    ShippingFormComponent,
    LeadFormComponent,
  ]
})
export class AppModule {
  static get domain() {
    // return 'http://localhost:3000';
    return environment2.production ? "https://api.goherogo.com" : "http://dev-api.goherogo.com";
  };

  public static route(route, state) {
    window.location.href = (route.data as any).externalUrl;
  }
}

