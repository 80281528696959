import { Component, OnInit } from '@angular/core';
import {MdDialog} from "@angular/material";
import {VideoPlayerComponent} from "../../components/video-player/video-player.component";

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  constructor(private dialog: MdDialog) { }

  ngOnInit() {
  }

  showVideo(event: any, title: string, url: string)
  {
    event.stopPropagation();
    var dialogRef = this.dialog.open(VideoPlayerComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.url = url;
  }
}
