import {Component, Input, OnInit} from '@angular/core';
import * as $ from 'jquery';

declare var wplSlide;

@Component({
  selector: 'app-brace-subpage',
  templateUrl: './brace-subpage.component.html',
  styleUrls: ['./brace-subpage.component.css']
})
export class BraceSubpageComponent implements OnInit {

  @Input() pageInfo: any;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    new wplSlide($('.wplSlide')[0], {
      autoRestart: true,
      auto: 5000,
    });
  }
}
