
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { AuthLogoutAction } from 'app/actions/auth';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userToken: string = null;

  constructor(private router: Router, private store: Store<AppState>) {
    this.store.select(x => x.mainState.auth).subscribe(x => {
      this.userToken = x.userToken;
    });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedRequest;
    const token = this.token;
    if (token) {
      if (this.isJson(req.body)) {
        clonedRequest = req.clone({
          headers: req.headers.set('Content-Type', 'application/json; charset=utf-8').set('x-access-token', token)
        });
      } else {
        clonedRequest = req.clone({
          headers: req.headers.set('x-access-token', token)
        });
      }
    } else {
      clonedRequest = req.clone({
        headers: req.headers.set('Content-Type', 'application/json; charset=utf-8')
      });
    }

    return next.handle(clonedRequest).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status == 403) {
          this.store.dispatch(new AuthLogoutAction(null));
          this.router.navigate(['login']);
        }
      }
      return observableThrowError(err);
    }));
  }

  get token(): string {
    let parsedToken = null;

    if (!this.userToken || this.userToken == '') {
      return null;
    }

    try {
      const base64Url = this.userToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      parsedToken = JSON.parse(window.atob(base64));
    } catch (err) {
      return null;
    }

    if (parsedToken) {
      const expired = new Date(parseInt(parsedToken.exp, 10) * 1000) < new Date();
      if (expired) {
        this.store.dispatch(new AuthLogoutAction(null));
        this.router.navigate(['login']);
        return null;
      }
    }

    return this.userToken;
  }
}
