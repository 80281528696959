import {type} from 'ngrx-cli';
import {Action} from '@ngrx/store';

export const ActionTypes = {
    LoadClinics: type('[Order] LoadClinics'),
    LoadClinicsSuccess: type('[Order] LoadClinicsSuccess'),
    LoadClinicsFailure: type('[Order] LoadClinicsFailure'),
    Submit: type('[Order] Submit'),
    SubmitSuccess: type('[Order] SubmitSuccess'),
    SubmitFailure: type('[Order] SubmitFailure'),
    Finalize: type('[Order] Finalize'),
    FinalizeSuccess: type('[Order] FinalizeSuccess'),
    FinalizeFailure: type('[Order] FinalizeFailure'),
    GetInfo: type('[Order] GetInfo'),
    GetInfoSuccess: type('[Order] GetInfoSuccess'),
    GetInfoFailure: type('[Order] GetInfoFailure'),
    SaveOwnerInfo: type('[Order] SaveOwnerInfo'),
    SaveOwnerInfoSuccess: type('[Order] SaveOwnerInfoSuccess'),
    SaveOwnerInfoFailure: type('[Order] SaveOwnerInfoFailure'),
    SaveDogInfo: type('[Order] SaveDogInfo'),
    SaveDogInfoSuccess: type('[Order] SaveDogInfoSuccess'),
    SaveDogInfoFailure: type('[Order] SaveDogInfoFailure'),
    SaveVetInfo: type('[Order] SaveVetInfo'),
    SaveVetInfoSuccess: type('[Order] SaveVetInfoSuccess'),
    SaveVetInfoFailure: type('[Order] SaveVetInfoFailure'),
    SaveLabInfo: type('[Order] SaveLabInfo'),
    SaveLabInfoSuccess: type('[Order] SaveLabInfoSuccess'),
    SaveLabInfoFailure: type('[Order] SaveLabInfoFailure'),
}

export class OrderLoadClinicsAction implements Action {
    type = ActionTypes.LoadClinics;

    constructor(public code: number, public name: string) {
    }
}

export class OrderLoadClinicsSuccessAction implements Action {
    type = ActionTypes.LoadClinicsSuccess;

    constructor(public payload: any) {
    }
}

export class OrderLoadClinicsFailureAction implements Action {
    type = ActionTypes.LoadClinicsFailure;

    constructor(public payload: any) {
    }
}

export class OrderSubmitAction implements Action {
    type = ActionTypes.Submit;

    constructor(public payload: any) {
    }
}

export class OrderSubmitSuccessAction implements Action {
    type = ActionTypes.SubmitSuccess;

    constructor(public payload: any) {
    }
}

export class OrderSubmitFailureAction implements Action {
    type = ActionTypes.SubmitFailure;

    constructor(public payload: any) {
    }
}

export class OrderFinalizeAction implements Action {
    type = ActionTypes.Finalize;

    constructor(public payload: any) {
    }
}

export class OrderFinalizeSuccessAction implements Action {
    type = ActionTypes.FinalizeSuccess;

    constructor(public payload: any) {
    }
}

export class OrderFinalizeFailureAction implements Action {
    type = ActionTypes.FinalizeFailure;

    constructor(public payload: any) {
    }
}

export class OrderGetInfoAction implements Action {
    type = ActionTypes.GetInfo;

    constructor(public payload: any) {
    }
}

export class OrderGetInfoFailureAction implements Action {
    type = ActionTypes.GetInfoFailure;

    constructor(public payload: any) {
    }
}

export class OrderGetInfoSuccessAction implements Action {
    type = ActionTypes.GetInfoSuccess;

    constructor(public payload: any) {
    }
}

export class OrderSaveOwnerInfoAction implements Action {
    type = ActionTypes.SaveOwnerInfo;

    constructor(public id: number, public payload: any) {
    }
}

export class OrderSaveOwnerInfoSuccessAction implements Action {
    type = ActionTypes.SaveOwnerInfoSuccess;

    constructor(public payload: any) {
    }
}

export class OrderSaveOwnerInfoFailureAction implements Action {
    type = ActionTypes.SaveOwnerInfoFailure;

    constructor(public payload: any) {
    }
}

export class OrderSaveDogInfoAction implements Action {
    type = ActionTypes.SaveDogInfo;

    constructor(public dogid: number, public feedid: number, public payload: any) {
    }
}

export class OrderSaveDogInfoSuccessAction implements Action {
    type = ActionTypes.SaveDogInfoSuccess;

    constructor(public payload: any) {
    }
}

export class OrderSaveDogInfoFailureAction implements Action {
    type = ActionTypes.SaveDogInfoFailure;

    constructor(public payload: any) {
    }
}

export class OrderSaveVetInfoAction implements Action {
    type = ActionTypes.SaveVetInfo;

    constructor(public id: number, public payload: any) {
    }
}

export class OrderSaveVetInfoSuccessAction implements Action {
    type = ActionTypes.SaveVetInfoSuccess;

    constructor(public payload: any) {
    }
}

export class OrderSaveVetInfoFailureAction implements Action {
    type = ActionTypes.SaveVetInfoFailure;

    constructor(public payload: any) {
    }
}

export class OrderSaveLabInfoAction implements Action {
    type = ActionTypes.SaveLabInfo;

    constructor(private id: number, public payload: any) {
    }
}

export class OrderSaveLabInfoSuccessAction implements Action {
    type = ActionTypes.SaveLabInfoSuccess;

    constructor(public payload: any) {
    }
}

export class OrderSaveLabInfoFailureAction implements Action {
    type = ActionTypes.SaveLabInfoFailure;

    constructor(public payload: any) {
    }
}

export type Actions =
    OrderGetInfoAction
    | OrderGetInfoSuccessAction
    | OrderGetInfoFailureAction
    | OrderLoadClinicsAction
    | OrderLoadClinicsSuccessAction
    | OrderLoadClinicsFailureAction
    | OrderSubmitAction
    | OrderSubmitSuccessAction
    | OrderSubmitFailureAction
    | OrderFinalizeAction
    | OrderFinalizeSuccessAction
    | OrderFinalizeFailureAction
    | OrderSaveOwnerInfoAction
    | OrderSaveOwnerInfoSuccessAction
    | OrderSaveOwnerInfoFailureAction
    | OrderSaveDogInfoAction
    | OrderSaveDogInfoSuccessAction
    | OrderSaveDogInfoFailureAction
    | OrderSaveVetInfoAction
    | OrderSaveVetInfoSuccessAction
    | OrderSaveVetInfoFailureAction
    | OrderSaveLabInfoAction
    | OrderSaveLabInfoSuccessAction
    | OrderSaveLabInfoFailureAction;
