import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class InteractionService {
  private tagAdded = new BehaviorSubject({});
  private taskAdded = new BehaviorSubject({});
  private vetMerged = new BehaviorSubject({});
  private editedTab = new BehaviorSubject({});
  private noteAdded = new BehaviorSubject({});
  private ownerAdded = new BehaviorSubject({});
  private modalClosed = new BehaviorSubject({});
  private popUpDataChanged = new BehaviorSubject({});
  private isDataUpdating = new BehaviorSubject({});
  private wipListRelevantDataUpdated = new BehaviorSubject({});
  private caseMerged = new BehaviorSubject({});

  private shippingActionPerformed = new BehaviorSubject({});
  private leadCreatedOrUpdated = new BehaviorSubject({});

  public sharedTagAdded = this.tagAdded.asObservable();
  public sharedTaskAdded = this.taskAdded.asObservable();
  public sharedVetMerged = this.vetMerged.asObservable();
  public sharedEditedTab = this.editedTab.asObservable();
  public sharedNoteAdded = this.noteAdded.asObservable();
  public sharedOwnerAdded = this.ownerAdded.asObservable();
  public shouldCloseModal = this.modalClosed.asObservable();
  public sharedIsDataUpdating = this.isDataUpdating.asObservable();
  public shouldUpdateCaseFeed = this.popUpDataChanged.asObservable();
  public shouldUpdateWipListing = this.wipListRelevantDataUpdated.asObservable();
  public sharedCaseMerged = this.caseMerged.asObservable();

  public sharedShippingActionPerformed = this.shippingActionPerformed.asObservable();
  public shouldUpdateLeadsListing = this.leadCreatedOrUpdated.asObservable();

  setNoteAddedInfo = (isNoteAdded) => this.noteAdded.next(isNoteAdded);

  setTagAddedInfo = (isTagAdded) => this.tagAdded.next(isTagAdded);

  setOwnerAddedInfo = (isOwnerAdded) => this.ownerAdded.next(isOwnerAdded);

  setShouldUpdateCaseFeed = (shouldUpdateCaseFeed) => this.popUpDataChanged.next(shouldUpdateCaseFeed);

  setEditedTab = (editedTab) => this.editedTab.next(editedTab);

  setModalClose = (shouldCloseModal) => this.modalClosed.next(shouldCloseModal);

  setTaskAdded = (isTaskAdded) => this.taskAdded.next(isTaskAdded);

  setVetMerged = (isVetMerged) => this.vetMerged.next(isVetMerged);

  setShouldUpdateWipListing = (shouldUpdateWipListing) => this.wipListRelevantDataUpdated.next(shouldUpdateWipListing);

  setIsDataUpdating = (isDataUpdating) => this.isDataUpdating.next(isDataUpdating);

  setCaseMerged = (isCaseMerged) => this.caseMerged.next(isCaseMerged);

  setShippingAction = (shippingActionPerformed) => this.shippingActionPerformed.next(shippingActionPerformed);
  setShouldUpdateLeadList = (leadAction) => this.leadCreatedOrUpdated.next(leadAction);
}
