import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {Observable} from 'rxjs';
import {Order, Vet} from '../../../../services/api/model/Order';
import {FormControl, FormGroup} from '@angular/forms';
import {ApiService} from '../../../../services/api/api-service';
import {FeedsFetchOrderAction} from '../../../../actions/feeds';
import {getReadableErrorMessage} from '../../../../utilities/utilities';
import { InteractionService } from '../../../../services/interaction.service';
import { HasSubscriptions } from '../../../../directives/HasSubscriptions';
import { MdDialog } from "@angular/material";

@Component({
  selector: "app-feed-popup-orders",
  templateUrl: "./feed-popup-orders.component.html",
  styleUrls: ["./feed-popup-orders.component.scss"],
})
export class FeedPopupOrdersComponent extends HasSubscriptions implements OnInit {
    isEdit;
    order: Order;
    vet: Vet;
    orderFormGroup: FormGroup = null;
    subscribedOrder: Observable<Order>;
    currentCaseId: number = null;
    shipOptions = ['vet', 'owner'];
    orderTypes = [
        'Knee Brace', 'Wrist Brace', 'Ankle Brace', 'Stifle Brace', 'Casting Kit', 'Hock Brace', 'Hock-Solid', 'Hock-J-Stop',
        'Hock-J-Stop P.Cut', 'Hock-J-Free', 'Hock-J-Free H.E Stop', 'Carpal - Solid', 'Carpal - J - Stop', 'Carpal - J - Stop P. Cut',
        'Carpal - J - Free', 'Other'
    ];
    success = false;
    failure = false;
    errorMessage = '';
    castingKitOptions = [
        {name: 'Yes', value: 'Yes'},
        {name: 'No', value: 'No'},
        {name: 'shipping_label_only', value: 'Shipping Label Only'},
    ];
    showCastingKitRelatedMessage = false;
    @Output() shippingMethodUpdated: EventEmitter<object> = new EventEmitter<object>();

  constructor(
    private store: Store<AppState>,
    private apiService: ApiService,
    private interactionService: InteractionService,
    private dialog: MdDialog
  ) {
    super();
    this.subscribedOrder = this.store.select(
      (x) => x.mainState.feeds.currentOrder
    );
    this.subscribedOrder.subscribe((orderData) => {
      this.vet = orderData.vet;
      this.order = orderData.order;
    });
  }

  ngOnInit() {
    this.interactionService.sharedEditedTab
      .pipe(this.getTakeUntil())
      .subscribe((data: any) => {
        if (data.section === "order") {
          if (data.currentAction === "edit") {
            this.activateEdit();
          }

          if (data.currentAction === "edit_performed") {
            this.submit();
          }
        }
      });
  }

  getCastingKitValue = (): string => {
    if (this.order) {
      return (this.vet &&
        this.vet.shipping_label_only.toLowerCase() === "yes") ||
        this.order.casting_kit === "shipping_label_only"
        ? "Shipping Label Only"
        : this.order.casting_kit;
    }
    return "";
  };

  buildForm = (order) => {
    const castingKitValue =
      this.vet && this.vet.shipping_label_only.toLowerCase() === "yes"
        ? "shipping_label_only"
        : order.casting_kit;
    if (!this.orderFormGroup && order) {
      this.orderFormGroup = new FormGroup({
        ship_to: new FormControl(order.ship_to),
        amount: new FormControl(order.amount || 0),
        order_type: new FormControl(order.order_type),
        casting_kit: new FormControl(castingKitValue),
        order_notes: new FormControl(order.order_notes),
        how_hear_about: new FormControl(order.how_hear_about),
        shipping_method: new FormControl(order.shipping_method),
        coupon_code: new FormControl(order.coupon ? order.coupon.code : null),
      });
    }
  };

  activateEdit = () => {
    this.showCastingKitRelatedMessage = true;
    this.resetMessageShow();
    this.buildForm(this.order);
    this.setCurrentCaseId();
    this.isEdit = true;
    this.success = this.failure = false;
  };

  setCurrentCaseId = () => {
    if (!this.currentCaseId) {
      const storeSubscriber = this.store.select((x) => {
        return x.mainState.feeds.currentOrder;
      });
      storeSubscriber.subscribe((data) => {
        this.currentCaseId = data && data.case ? data.case.id : null;
      });
    }
  };

  resetMessageShow = () => {
    setTimeout(() => {
      this.showCastingKitRelatedMessage = false;
    }, 5000);
  };

  submit = () => {
    this.success = this.failure = false;
    const formattedOrderPayload = this.orderFormGroup.value;
    formattedOrderPayload.amount = formattedOrderPayload.amount || 0;
    this.apiService
      .updateOrder(this.order.id, formattedOrderPayload)
      .subscribe((apiResponse) => {
        if (
          apiResponse.error &&
          apiResponse.error.message === "Coupon not found"
        ) {
          this.errorMessage =
            "Invalid Coupon Code. Please try with a valid one.";
        } else {
          this.errorMessage = getReadableErrorMessage(apiResponse);
        }
        if (apiResponse.status === 1) {
          this.success = true;
          if (this.order.order_type !== formattedOrderPayload.order_type) {
            this.interactionService.setShouldUpdateWipListing(true);
          }
          this.orderFormGroup.markAsPristine();
          this.store.dispatch(new FeedsFetchOrderAction(this.currentCaseId));
        } else {
          this.failure = true;
        }
        this.isEdit = false;
      });
  };

  ngOnDestroy() {
    //show confirmation dialog if form input field value are changed
    if (this.orderFormGroup != null) {
      if (this.orderFormGroup.dirty) {
        this.submit();
      }
    } else {
      this.interactionService.setEditedTab(false);
    }
    super.ngOnDestroy();
  }
}
