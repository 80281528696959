import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from "socket.io-client";
import { environment2 } from "./../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  constructor() {
  }
  // socket = io('http://localhost:3000/');
  socket = io(environment2.production ? "https://api.goherogo.com" : "http://dev-api.goherogo.com");
  
  /**
   * send updated data from event to socket
   * @param event 
   * @param data 
   */
  public sendData(event,data) {
    this.socket.emit(event, data);
  }

  /**
   * Get updated data for event from socket
   * 
   * @param event 
   * @returns 
   */
  getData(event) : Observable<any> {
    return new Observable((subscriber) => {
        this.socket.on(event, (data) => {
            subscriber.next(data);
        })
    })
}

}
