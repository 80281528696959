import { Directive, EventEmitter, Output, Renderer2 } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers/index";
import { AppInitializeMediaServiceAction } from "../actions/app";

export class Viewport {
  width: number;
  height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}

export class MediaEvent {
  old: Viewport;
  new: Viewport;
  event: any;

  constructor(evt: any, old: Viewport, _new: Viewport) {
    this.event = evt;
    this.old = old;
    this.new = _new;
  }
}

@Directive({
  selector: '[media-service]'
})
export class MediaService {
  @Output() mediaEvent: EventEmitter<MediaEvent> = new EventEmitter<MediaEvent>();
  currentViewport: Viewport;

  constructor(private _renderer: Renderer2, private _store: Store<AppState>) {
    this._store.dispatch(new AppInitializeMediaServiceAction(this));

    this.currentViewport = new Viewport(window.innerWidth, window.innerHeight);

    this._renderer.listen('window', 'resize', (evt) => {
      var newPort = new Viewport(window.innerWidth, window.innerHeight);
      this.mediaEvent.emit(new MediaEvent(evt, this.currentViewport, newPort));
      this.currentViewport = newPort;
    });
  }

  initialize() {
    var newPort = new Viewport(window.innerWidth, window.innerHeight);
    this.mediaEvent.emit(new MediaEvent(null, this.currentViewport, newPort));
    this.currentViewport = newPort;
  }
}
