import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent {
  @Input() leads = [];
  @Output() leadDetailsClicked: EventEmitter<object> = new EventEmitter<object>();
  @Output() leadClicked: EventEmitter<object> = new EventEmitter<object>();


  leadRowClicked = (lead) => this.leadClicked.emit(lead);
  openLeadDetails = (lead) => this.leadDetailsClicked.emit(lead);
}
