import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers/index";

@Injectable()
export class AuthGuard implements CanActivate {

  userToken: String;

  constructor(private store: Store<AppState>, public router: Router) { }

  canActivate() {
    this.store.select(x => x.mainState.auth).subscribe(x => {
      this.userToken = x.userToken;
    });

    if (this.userToken != null && this.userToken != "") {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
