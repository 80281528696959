import { Component, Inject, OnInit } from "@angular/core";
import { Dog, Feed } from "../../../services/api/model/FeedResponse";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "../../../reducers/index";
import { Store } from "@ngrx/store";
import {
  FeedsFetchDogAction,
  FeedsFetchFeedAction,
  FeedsClearFeedAction,
  ActionTypes,
  FeedsSaveFeedAction,
  FeedsSaveDogAction,
} from "../../../actions/feeds";
import { AppModule } from "../../../app.module";
import {
  QuestionBase,
  QuestionControlService,
} from "app/services/api/model/Question-Control-Service";
import { Actions } from "@ngrx/effects";
// import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { MdDialog, MdDialogRef, MD_DIALOG_DATA } from "@angular/material";
import { ApiService } from '../../../services/api/api-service';
import { SaveConfirmation } from 'app/components/save-confirmation/save-confirmation.component';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: "app-feed",
  templateUrl: "./feed.component.html",
  styleUrls: ["./feed.component.css"],
  providers: [QuestionControlService],
})
export class FeedComponent  implements OnInit {
  showForm: boolean = false;
  progress: number = 0;

  failure: boolean = false;
  success: boolean = false;
  loading: boolean = false;

  values: QuestionBase<any>[] = [];
  formValues: QuestionBase<any>[] = [];
  form: FormGroup;

  domain = AppModule.domain;

  feed: Feed;
  dog: Dog;
  // todo typehint these as well.
  currentCaseId;
  owner;
  files;
  dogFetched = false;
  closeModal = false;

  constructor(
    @Inject(MD_DIALOG_DATA) public data: any,
    private qcs: QuestionControlService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private actions: Actions,
    public dialogRef: MdDialogRef<FeedComponent>,
    private dialog:MdDialog
  ) {
  }

  ngOnInit() {
    this.actions.ofType(ActionTypes.SaveFeedFailure).subscribe((failure) => {
      this.failure = true;
      this.resetForm();
      setTimeout(() => { this.failure = false}, 2000)
    });
    this.actions.ofType(ActionTypes.SaveFeedSuccess).subscribe((result) => {
      setTimeout(
        () => {
          this.success = true;
          this.resetForm();
          document.querySelector('#form').scrollTo(0,0);
          const currentFeed = this.store.select((x) => x.mainState.feeds.currentFeed);
          currentFeed.subscribe(response=>{
            if (response && this.success) {
              if (response.id == this.data.case_id) {
                this.feed = response;
                this.initializeForm();
              }
            }
          })
          this.store.dispatch(new FeedsFetchFeedAction(this.currentCaseId));
          setTimeout(() => { this.success = false}, 2000)
        },
        
        // delaying the the success response as files are being uploaded asynchronously.
        (this.files.length + 2) * 1000,
      );
    })
    if (this.data.case_id) {
      this.currentCaseId = this.data.case_id;
      this.store.dispatch(new FeedsFetchFeedAction(this.currentCaseId));
      this.store
      .select((x) => x.mainState.feeds.currentFeed)
      .subscribe((feed: Feed) => this.updateFeed(feed));
        this.store
          .select((x) => x.mainState.feeds.currentDog)
          .subscribe((dog: Dog) => {
            if (this.feed) {
              this.updateDog(dog)
            }
          });
    }
  
   
 
  }

  ngOnDestroy(): void {
      this.store.dispatch(new FeedsClearFeedAction(null));
  }

  resetForm() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.loading = false;
    this.initializeForm();
  }

  updateDog(dog: Dog) {
    this.dog = dog || <any>{};
    this.initializeForm();
  }

  // setDogName = () => {
  //   if (this.showForm) {
  //     this.values[0].value = this.dog.name;
  //   } else {
  //     const ownerName =
  //       this.feed && this.feed.owner
  //         ? this.feed.owner.first_name + " " + this.feed.owner.last_name
  //         : "";
  //     const orderId = this.feed ? this.feed.order_id : "";
  //     this.values[0].value = `${this.dog.name} ${ownerName}`;
  //   }
  // };

  initializeForm() {
    this.values = [];
    this.files = [];
    if (
      (this.dog.id != undefined || this.feed.dog != undefined) &&
      this.feed.dog == this.dog.id
    ) {
      this.values.push(
        new QuestionBase({
          key: "name",
          value: this.feed.name,
          label: "dog name",
          type: "text",
          validators: [Validators.required]
        })
      );
      this.values.push(
        new QuestionBase({
          key: "order_id",
          value: this.feed.order_id,
          label: "order id",
          type: "text",
          readOnly: true,
        })
      );
      this.values.push(
        new QuestionBase({
          key: "status",
          value: this.feed.status,
          label: "status",
          type: "text",
          readOnly: true,
        })
      );
      this.values.push(
        new QuestionBase({
          key: "diagnosis",
          value: this.feed.diagnosis,
          label: "diagnosis",
          type: "text",
        })
      );
      this.values.push(
        new QuestionBase({
          key: "cranial_length",
          value: this.feed.cranial_length,
          label: "cranial length",
          type: "text",
        })
      );
      this.values.push(
        new QuestionBase({
          key: "side",
          value: this.feed.side,
          label: "injury side",
          type: "text",
        })
      );
    
      this.values.push(
        new QuestionBase({
          key: "bracing_goal",
          value: this.feed.bracing_goal,
          label: "bracing goal",
          type: "text",
        })
      );
      this.values.push(
        new QuestionBase({
          key: "medical_notes",
          value: this.feed.medical_notes,
          label: "medical notes",
          type: "textarea",
        })
      );
      this.values.push(
        new QuestionBase({
          key: "picture",
          value: this.feed.picture,
          label: "pictures",
          type: "file-uploader",
        })
      );
      this.form = this.qcs.toFormGroup(this.values);
      if (this.feed.picture) {
        this.files = this.feed.picture;
      }
      this.showForm = true;
    }
    this.formValues = this.values.filter((singleData) => !singleData.readOnly);
  }

 

  disableCheckBoxInView = () => {
    setTimeout(() => {
      const cuffSlantElement = <HTMLInputElement>(
        document.getElementById("cuff_slant-value")
      );
      if (cuffSlantElement) {
        cuffSlantElement.disabled = true;
      }
    }, 1700);
  };

  updateFeed(feed: Feed) {
    if (feed != null) {
      this.feed = feed || <any>{};
      if (this.feed.dog && !this.dogFetched) {
        this.dogFetched = true;
        this.store.dispatch(new FeedsFetchDogAction(this.feed.dog));
      }
    }
    // if (feed ==  null) {
    //   this.feed = this.data.case;
    // } else if(this.feed.id == this.data.case_id){
    //   this.feed = feed || <any>{};
    // }
    
  }

  showEditPage() {
    this.failure = false;
    this.success = false;
  }


  

  saveEditPage() {
    this.loading = true;
    const payLoad = new FormData();
    Object.keys(this.form.controls).forEach((key) => {
      if (key !== "picture" ) {
        const value = this.form.get(key).value;
        payLoad.append(key, value);
      }
    });

    if (this.form.controls.picture.dirty) {
      Array.from(this.files).forEach((e: File, fileIndex) => {
        payLoad.append("picture[" + fileIndex + "]", e, e.name);
      });
    } else{
      payLoad.append('picture', this.files);
    }
    if (this.form.valid) {
      this.store.dispatch(new FeedsSaveFeedAction(this.feed.id, payLoad));
      this.store.dispatch(
        new FeedsSaveDogAction(this.getFormattedPayloadForDog())
      );
    }
  }

  getFormattedPayloadForDog = (): Dog => {
    const currentDog = this.dog;
    return {
      id: currentDog.id,
      name: this.form.get("name").value,
      breed: currentDog.breed,
      age: currentDog.age,
      weight: currentDog.weight,
    };
  };

  setUploadedFiles = (files) => {
    this.files = files;
  };


  close=()=>{
    if (this.form.dirty) {
      this.showSaveConfirmationDialog();
    } else {
      this.dialogRef.close('close');
      
    }
  }

  previewImage(file){
    window.open(this.domain + file,"_blank");
  }

  showSaveConfirmationDialog(){
      let dialogRef = this.dialog.open(SaveConfirmation,{
        data:{
          form:'Case Feed form'
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        // received data from dialog-component
        if (res == "save") {
          this.closeModal
          this.saveEditPage();
        } else{
          this.dialogRef.close('close');
        }
      })
    }

    checkInformationComplete() {
      if (this.feed.diagnosis && this.feed.side && this.feed.name) {
        return "#1eac09";
      } else {
        return "#c10505";
      }
    }

    
}
