import { Component, OnInit } from '@angular/core';

// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers/index';
import { FormGroup, Validators } from '@angular/forms';
import { FeedsClearSearchAction, FeedsSearchAction } from '../../actions/feeds';
import { QuestionControlService, QuestionBase } from 'app/services/api/model/Question-Control-Service';


@Component({
  selector: 'app-feed-search',
  templateUrl: './feed-search.component.html',
  styleUrls: ['./feed-search.component.scss'],
  providers: [QuestionControlService]
})
export class FeedSearchComponent implements OnInit {
  showDropDown: boolean;
  mode: Observable<string>;

  values: QuestionBase<any>[] = [];
  form: FormGroup;

  constructor(private store: Store<AppState>, private qcs: QuestionControlService) {
    this.mode = store.select(x => x.mainState.feeds.mode);
  }

  ngOnInit() {
    this.initializeForm();
    // this.testFunction();
  }

  testFunction = () => {
    setTimeout(() => {
      const testElement = document.getElementsByClassName('search-result-container')[1] as HTMLElement;
      testElement.click();
    }, 3000);
  }
  initializeForm() {
    this.values = [];
    this.values.push(new QuestionBase({key: 'q', value: '', type: 'main', validators: [Validators.required]}));
    this.values.push(new QuestionBase({
      key: 'object_type',
      value: '',
      label: 'type',
      type: 'checkboxSelect',
      options: ['Dog', 'Vet', 'Order', 'Case']
    }));
    this.values.push(new QuestionBase({key: 'start_date', value: '', label: 'start date', type: 'dateSelect'}));
    this.values.push(new QuestionBase({key: 'end_date', value: '', label: 'end date', type: 'dateSelect'}));
    this.values.push(new QuestionBase({key: 'order', value: '', label: 'order', type: 'checkboxSelect', options: ['asc', 'desc']}));
    this.form = this.qcs.toFormGroup(this.values);
  }

  getWidth(element: HTMLElement) {
    return element.offsetWidth;
  }

  clearSearch() {
    this.store.dispatch(new FeedsClearSearchAction(null));
    this.form.reset();
    this.showDropDown = false;
  }

  showOptions() {
    this.showDropDown = true;
  }

  hideOptions() {
    this.showDropDown = false;
  }

  doSearch() {
    this.store.dispatch(new FeedsSearchAction(this.form.value));
    this.showDropDown = false;
  }
}
