import { Component, Inject, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MdDialog } from "@angular/material";
import { AppState } from "../../reducers/index";
import { Store } from "@ngrx/store";
import { DOCUMENT } from "@angular/platform-browser";
import { ContactFormComponent } from "../contact-form/contact-form.component";
import { AppContactButtonAction, AppContactPageAction } from "../../actions/app";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  navs: any;

  constructor(@Inject(DOCUMENT) private document: any, private store: Store<AppState>, private dialog: MdDialog, private router: Router) {
    this.store.select(x => x.mainState.app.navs).subscribe(x => this.navs = x);
  }

  ngOnInit() {
  }

  openContactUs(event: any, contact: string) {
    event.stopPropagation();

    if ( contact != 'button')
      this.store.dispatch(new AppContactPageAction(null));
    else
      this.store.dispatch(new AppContactButtonAction(null));

    this.dialog.open(ContactFormComponent);
    //window.open("https://calendly.com/goherogo/free-brace-consult?month=2019-10");
  }

}
