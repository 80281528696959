
import {mergeMap, map} from 'rxjs/operators';
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import {
  ActionTypes, OrderLoadClinicsAction,
  OrderLoadClinicsFailureAction,
  OrderLoadClinicsSuccessAction, OrderSubmitAction,
  OrderSubmitFailureAction,
  OrderSubmitSuccessAction,
  OrderSaveOwnerInfoAction,
  OrderSaveOwnerInfoFailureAction,
  OrderSaveOwnerInfoSuccessAction,
  OrderSaveDogInfoAction,
  OrderSaveDogInfoFailureAction,
  OrderSaveDogInfoSuccessAction,
  OrderSaveLabInfoAction,
  OrderSaveLabInfoFailureAction,
  OrderSaveLabInfoSuccessAction,
  OrderSaveVetInfoAction,
  OrderSaveVetInfoFailureAction,
  OrderSaveVetInfoSuccessAction
} from "../actions/order";
import { ApiService } from "../services/api/api-service";
import { Actions, Effect } from "@ngrx/effects";
import { Injectable } from "@angular/core";

@Injectable()
export class OrderService {
  @Effect()
  LoadClinics: Observable<{}> = this.actions$
    .ofType(ActionTypes.LoadClinics).pipe(
    map((action: OrderLoadClinicsAction) => action),
    mergeMap((model: OrderLoadClinicsAction) =>
      this._api.fetchClinics(model.code, model.name).map((response: any) => {
        if (response.status != 1)
          return new OrderLoadClinicsFailureAction(response.error);
        return new OrderLoadClinicsSuccessAction(response.data);
      })),);

  @Effect()
  Submit: Observable<{}> = this.actions$
    .ofType(ActionTypes.Submit).pipe(
    map((action: OrderSubmitAction) => action),
    mergeMap((model: OrderSubmitAction) =>
      this._api.submitOrder(model.payload).map((response: any) => {
        if (response.status != "complete")
          return new OrderSubmitFailureAction(response.error);
        return new OrderSubmitSuccessAction(response);
      })),);

  @Effect()
  UpdateOwner: Observable<{}> = this.actions$
    .ofType(ActionTypes.SaveOwnerInfo).pipe(
    map((action: OrderSaveOwnerInfoAction) => action),
    mergeMap((model: any) =>
      this._api.updateOwner(model.id, model.payload).map((response: any) => {
        if (response.status != 1)
          return new OrderSaveOwnerInfoFailureAction(response);
        return new OrderSaveOwnerInfoSuccessAction(response);
      })),);

  @Effect()
  UpdateDog: Observable<{}> = this.actions$
    .ofType(ActionTypes.SaveDogInfo).pipe(
    map((action: OrderSaveDogInfoAction) => action),
    mergeMap((model: any) =>
      this._api.updateDog(model.dogid, model.feedid, model.payload).map((response: any) => {
        if (response.status != 1)
          return new OrderSaveDogInfoFailureAction(response);

        return new OrderSaveDogInfoSuccessAction(response);
      })),);

  @Effect()
  UpdateFeed: Observable<{}> = this.actions$
    .ofType(ActionTypes.SaveLabInfo).pipe(
    map((action: OrderSaveLabInfoAction) => action),
    mergeMap((model: any) =>
      this._api.updatecasefeed(model.id, model.payload).map((response: any) => {
        if (response.status != 1)
          return new OrderSaveLabInfoFailureAction(response.message);
        return new OrderSaveLabInfoSuccessAction(response);
      })),);

  @Effect()
  UpdateVet: Observable<{}> = this.actions$
    .ofType(ActionTypes.SaveVetInfo).pipe(
    map((action: OrderSaveVetInfoAction) => action),
    mergeMap((model: any) =>
      this._api.updateVet(model.id, model.payload).map((response: any) => {
        if (response.status != 1)
          return new OrderSaveVetInfoFailureAction(response);
        return new OrderSaveVetInfoSuccessAction(response);
      })),);

  constructor(private actions$: Actions, private _api: ApiService) { }

}
