
import {map, mergeMap} from 'rxjs/operators';
import { Observable } from "rxjs";
import {
  ActionTypes, AuthLoginAction, AuthLoginFailureAction, AuthLoginSuccessAction, AuthRequestPasswordResetAction,
  AuthRequestPasswordResetFailureAction,
  AuthRequestPasswordResetSuccessAction,
  AuthResetPasswordAction,
  AuthResetPasswordSuccessAction
} from "../actions/auth";
import { ApiService } from "../services/api/api-service";
import { Actions, Effect } from "@ngrx/effects";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthService {
  @Effect()
  Login: Observable<{}> = this.actions$
    .ofType(ActionTypes.Login).pipe(
    map((action: AuthLoginAction) => action.payload),
    mergeMap((model: any) =>
      this._api.login(model).map((response: any) => {
        if (!response.success)
          return new AuthLoginFailureAction(response.error);
        return new AuthLoginSuccessAction(response);
      })),);

  @Effect()
  RequestReset: Observable<{}> = this.actions$
    .ofType(ActionTypes.RequestPasswordReset).pipe(
    map((action: AuthRequestPasswordResetAction) => action.payload),
    mergeMap((model: any) =>
      this._api.requestReset(model).map((response: any) => {
        if (!response.error) {
          alert(response.message);
          return new AuthRequestPasswordResetSuccessAction(response);
        }
        else {
          alert(response.error.message);
          return new AuthRequestPasswordResetFailureAction(response);
        }
      })),);

  @Effect()
  ResetPassword: Observable<{}> = this.actions$
    .ofType(ActionTypes.ResetPassword).pipe(
    map((action: AuthResetPasswordAction) => action.payload),
    mergeMap((model: any) =>

      this._api.resetPassword(model.newPass, model.token).map((response: any) => {
        if (response.message) {
          alert(response.message);
        }
        else {
          var resp = JSON.parse(response._body);
          alert(resp.message);
        }
        return new AuthResetPasswordSuccessAction(response);
      })),);


  constructor(private actions$: Actions, private _api: ApiService) { }

}
