import {
  Component,
  OnInit
} from '@angular/core';
import * as shopifyClient from 'shopify-buy';


@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css']
})
export class NewOrderComponent implements OnInit {

  products: any;
  client: any;
  checkout: any;
  order: number[] = [1, 3, 1, 3];

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.client = shopifyClient.buildClient({
      domain: 'hero-braces.myshopify.com',
      storefrontAccessToken: 'eb35a7ef97e32e3a28c35785c31af609'
    });
    this.client.product.fetchAll().then((products) => {
      // Do something with the products
      this.products = products.map(singleProduct => {
        singleProduct.quantity = 1;
        return singleProduct;
      });
    });
  }

  purchase(id: String, quantity: number = 1) {
    if (id != '' && typeof(id) != 'undefined') {
      this.client.checkout.create().then((checkout) => {
        // Do something with the checkout
        this.checkout = checkout;
        var items = [{
          variantId: id,
          quantity
        }];
        this.client.checkout.addLineItems(checkout.id, items).then((checkout) => {
          window.location.href = checkout.webUrl;
        });
      });
    }
  }
}
