import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {Vet} from '../../../../services/api/model/VetResponse';
import {QuestionControlService, QuestionBase} from 'app/services/api/model/Question-Control-Service';
import {FormGroup} from '@angular/forms';
import {ActionTypes, OrderSaveVetInfoAction} from 'app/actions/order';
import {Actions} from '@ngrx/effects';
import {getReadableErrorMessage} from '../../../../utilities/utilities';
import {ApiService} from '../../../../services/api/api-service';
import {HasSubscriptions} from '../../../../directives/HasSubscriptions';
import {FeedsFetchOrderAction, FeedsFetchVetAction} from '../../../../actions/feeds';
import {Order} from '../../../../services/api/model/Order';
import { InteractionService } from '../../../../services/interaction.service';
import { MdDialog } from "@angular/material";
import { SaveConfirmation } from 'app/components/save-confirmation/save-confirmation.component';

@Component({
  selector: 'app-feed-popup-vet',
  templateUrl: './feed-popup-vet.component.html',
  styleUrls: ['./feed-popup-vet.component.scss'],
  providers: [QuestionControlService]
})
export class FeedPopupVetComponent extends HasSubscriptions implements OnInit, OnDestroy {

  vet: Vet;
  editStateBool: boolean = false;
  values: QuestionBase<any>[] = [];
  form: FormGroup;
  success: boolean;
  errorMessage = '';
  failure: boolean;
  failureSubscription: any;
  successSubscription: any;
  order;
  baseOrder: any = {case : {}};

  constructor(
    private qcs: QuestionControlService,
    private store: Store<AppState>,
    private actions: Actions,
    private apiService: ApiService,
    private interactionService: InteractionService,
    private dialog: MdDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.interactionService.sharedEditedTab.pipe(this.getTakeUntil()).subscribe((data: any) => {
      if (data && data.section === 'vet') {
        if (data.currentAction === 'edit') {
          this.editState();
        }

        if (data.currentAction === 'edit_performed') {
          this.submitForm();
        }
      }
    });
    this.failureSubscription = this.actions.ofType(ActionTypes.SaveVetInfoFailure)
      .subscribe((failureAction) => this.handleSaveFeedFailure(failureAction));
    this.successSubscription = this.actions.ofType(ActionTypes.SaveVetInfoSuccess)
      .subscribe((result) => this.handleSaveFeedSuccess());
    this.store.select(x => x.mainState.feeds.currentOrder).pipe(this.getTakeUntil()).subscribe((data: Order) => {
      this.baseOrder = data;
      this.order = data.order ? data.order : null;
    });
    this.store.select(x => x.mainState.feeds.currentVet).pipe(this.getTakeUntil()).subscribe((vet: Vet) => this.updateVet(vet));
  }

  initializeForm() {
    this.values = [];
    if (this.vet.id != undefined || this.vet != undefined) {
      this.values.push(new QuestionBase({value: this.vet.doctor_name, label: 'Doctor', key: 'doctor_name'}));
      this.values.push(new QuestionBase({value: this.vet.email, label: 'Email', type: 'email'}));
      this.values.push(new QuestionBase({value: this.vet.name, label: 'clinic', key: 'name'}));
      this.values.push(new QuestionBase({value: this.vet.phone, label: 'phone', type: 'tel'}));
      this.values.push(new QuestionBase({value: this.vet.address, label: 'address'}));
      this.values.push(new QuestionBase({value: this.vet.city, label: 'city'}));
      this.values.push(new QuestionBase({value: this.vet.state, label: 'state'}));
      this.values.push(new QuestionBase({value: this.vet.zipcode, label: 'zipcode'}));
      this.values.push(new QuestionBase({value: this.vet.country, label: 'Country'}));
      this.values.push(new QuestionBase({value: this.vet.url, label: 'URL'}));
      this.form = this.qcs.toFormGroup(this.values);
    }
  }

  handleSaveFeedSuccess(): any {
    this.failure = false;
    this.success = true;
    if (this.baseOrder.case.id) {
      this.store.dispatch(new FeedsFetchOrderAction(this.baseOrder.case.id));
    }
  }

  handleSaveFeedFailure(failureAction): any {
    this.errorMessage = getReadableErrorMessage(failureAction);
    this.success = false;
    this.failure = true;
  }

  updateVet(vet: Vet) {
    this.vet = vet || <any>{};
    this.initializeForm();
  }

  editState() {
    this.editStateBool = !this.editStateBool;
  }

  submitForm() {
    this.editStateBool = false;
    if (this.vet && this.vet.hasOwnProperty('id')) {
      for (const key of Object.keys(this.form.controls)) {
        this.vet[key] = this.form.get(key).value;
      }
      this.initializeForm();
      if (this.form.valid) {
        this.store.dispatch(new OrderSaveVetInfoAction(this.vet.id, this.form.value));
      }
    } else {
      if (this.form && this.form.valid) {
        const formattedVetPayload = this.form.value;
        formattedVetPayload.order_id = this.order ? this.order.id : null;
        this.apiService.createVet(this.form.value)
          .pipe(this.getTakeUntil())
          .subscribe((vetResponse: any) => {
            if (vetResponse.status === 1) {
              this.handleSaveFeedSuccess();
              this.store.dispatch(new FeedsFetchVetAction(vetResponse.data.id));
            } else {
              this.handleSaveFeedFailure(vetResponse);
            }
          });
      }
    }
  }

  ngOnDestroy() {
    //show confirmation dialog if form input field value are changed
    if (this.form.dirty) {
      this.submitForm();
    } else {
      this.interactionService.setEditedTab(false);
    }
    super.ngOnDestroy();
  }



  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (this.form.dirty) {
      this.submitForm();
      return false;
    }
  }
}
