
import { ReducerBase } from "ngrx-cli/reducer-base";
import * as order from '../actions/order';
import { IClinic } from "../services/api/model/IClinic";
import { Order, OrderInfo } from "../services/api/model/Order";

export interface State {
	clinics: IClinic[]
	order: Order;
	cachedOrder: Order;
	orderInfo: OrderInfo;
}

export class OrderReducer extends ReducerBase {
	static initialState: State = {
		clinics: [],
		cachedOrder: null,
		order: null,
		orderInfo: null,
	};

	static reducer(state = OrderReducer.initialState, action: order.Actions): State {
		switch (action.type) {
			case order.ActionTypes.LoadClinics:
				return OrderReducer.handleLoadClinics(state, <order.OrderLoadClinicsAction>action);
			case order.ActionTypes.LoadClinicsSuccess:
				return OrderReducer.handleLoadClinicsSuccess(state, <order.OrderLoadClinicsSuccessAction>action);
			case order.ActionTypes.LoadClinicsFailure:
				return OrderReducer.handleLoadClinicsFailure(state, <order.OrderLoadClinicsFailureAction>action);
			case order.ActionTypes.Submit:
				return OrderReducer.handleSubmit(state, <order.OrderSubmitAction>action);
			case order.ActionTypes.SubmitSuccess:
				return OrderReducer.handleSubmitSuccess(state, <order.OrderSubmitSuccessAction>action);
			case order.ActionTypes.SubmitFailure:
				return OrderReducer.handleSubmitFailure(state, <order.OrderSubmitFailureAction>action);
			case order.ActionTypes.Finalize:
				return OrderReducer.handleFinalize(state, <order.OrderFinalizeAction>action);
			case order.ActionTypes.FinalizeSuccess:
				return OrderReducer.handleFinalizeSuccess(state, <order.OrderFinalizeSuccessAction>action);
			case order.ActionTypes.FinalizeFailure:
				return OrderReducer.handleFinalizeFailure(state, <order.OrderFinalizeFailureAction>action);
			case order.ActionTypes.GetInfo:
				return OrderReducer.handleGetInfo(state, <order.OrderGetInfoAction>action);
			case order.ActionTypes.GetInfoSuccess:
				return OrderReducer.handleGetInfoSuccess(state, <order.OrderGetInfoSuccessAction>action);
			case order.ActionTypes.GetInfoFailure:
				return OrderReducer.handleGetInfoFailure(state, <order.OrderGetInfoFailureAction>action);
			case order.ActionTypes.SaveOwnerInfo:
				return OrderReducer.handleSaveOwnerInfo(state, <order.OrderSaveOwnerInfoAction>action);
			case order.ActionTypes.SaveOwnerInfoSuccess:
				return OrderReducer.handleSaveOwnerInfoSuccess(state, <order.OrderSaveOwnerInfoSuccessAction>action);
			case order.ActionTypes.SaveOwnerInfoFailure:
				return OrderReducer.handleSaveOwnerInfoFailure(state, <order.OrderSaveOwnerInfoFailureAction>action);
			case order.ActionTypes.SaveDogInfo:
				return OrderReducer.handleSaveDogInfo(state, <order.OrderSaveDogInfoAction>action);
			case order.ActionTypes.SaveDogInfoSuccess:
				return OrderReducer.handleSaveDogInfoSuccess(state, <order.OrderSaveDogInfoSuccessAction>action);
			case order.ActionTypes.SaveDogInfoFailure:
				return OrderReducer.handleSaveDogInfoFailure(state, <order.OrderSaveDogInfoFailureAction>action);
			case order.ActionTypes.SaveLabInfo:
				return OrderReducer.handleSaveLabInfo(state, <order.OrderSaveLabInfoAction>action);
			case order.ActionTypes.SaveLabInfoSuccess:
				return OrderReducer.handleSaveLabInfoSuccess(state, <order.OrderSaveLabInfoSuccessAction>action);
			case order.ActionTypes.SaveLabInfoFailure:
				return OrderReducer.handleSaveLabInfoFailure(state, <order.OrderSaveLabInfoFailureAction>action);
			case order.ActionTypes.SaveVetInfo:
				return OrderReducer.handleSaveVetInfo(state, <order.OrderSaveVetInfoAction>action);
			case order.ActionTypes.SaveVetInfoSuccess:
				return OrderReducer.handleSaveVetInfoSuccess(state, <order.OrderSaveVetInfoSuccessAction>action);
			case order.ActionTypes.SaveVetInfoFailure:
				return OrderReducer.handleSaveVetInfoFailure(state, <order.OrderSaveVetInfoFailureAction>action);
		}
		return state
	}
	static handleSaveVetInfoFailure(state: State, action: order.OrderSaveVetInfoFailureAction): State {
		return state;
	}
	static handleSaveVetInfoSuccess(state: State, action: order.OrderSaveVetInfoSuccessAction): State {
		return Object.assign({}, state, { vetInfo: action.payload });
	}
	static handleSaveVetInfo(state: State, action: order.OrderSaveVetInfoAction): State {
		return state;
	}
	static handleSaveLabInfoFailure(state: State, action: order.OrderSaveLabInfoFailureAction): State {
		return state;
	}
	static handleSaveLabInfoSuccess(state: State, action: order.OrderSaveLabInfoSuccessAction): State {
		return Object.assign({}, state, { labInfo: action.payload });
	}
	static handleSaveLabInfo(state: State, action: order.OrderSaveLabInfoAction): State {
		return state;
	}
	static handleSaveDogInfoFailure(state: State, action: order.OrderSaveDogInfoFailureAction): State {
		return state;
	}
	static handleSaveDogInfoSuccess(state: State, action: order.OrderSaveDogInfoSuccessAction): State {
		return Object.assign({}, state, { dogInfo: action.payload });
	}
	static handleSaveDogInfo(state: State, action: order.OrderSaveDogInfoAction): State {
		return state;
	}
	static handleSaveOwnerInfoFailure(state: State, action: order.OrderSaveOwnerInfoFailureAction): State {
		return state;
	}
	static handleSaveOwnerInfoSuccess(state: State, action: order.OrderSaveOwnerInfoSuccessAction): State {
		return Object.assign({}, state, { ownerInfo: action.payload });
	}
	static handleSaveOwnerInfo(state: State, action: order.OrderSaveOwnerInfoAction): State {
		return state;
	}

	static handleLoadClinics(state: State, action: order.OrderLoadClinicsAction): State {
		return state;
	}
	static handleLoadClinicsSuccess(state: State, action: order.OrderLoadClinicsSuccessAction): State {
		return Object.assign({}, state, { clinics: action.payload });
	}
	static handleLoadClinicsFailure(state: State, action: order.OrderLoadClinicsFailureAction): State {
		return state;
	}
	static handleSubmit(state: State, action: order.OrderSubmitAction): State {
		return state;
	}
	static handleSubmitSuccess(state: State, action: order.OrderSubmitSuccessAction): State {
		return Object.assign({}, state, { order: action.payload });
	}
	static handleSubmitFailure(state: State, action: order.OrderSubmitFailureAction): State {
		return state;
	}
	static handleFinalize(state: State, action: order.OrderFinalizeAction): State {
		return state;
	}
	static handleFinalizeSuccess(state: State, action: order.OrderFinalizeSuccessAction): State {
		return Object.assign({}, state, { cachedOrder: action.payload });
	}
	static handleFinalizeFailure(state: State, action: order.OrderFinalizeFailureAction): State {
		return state;
	}

	static handleGetInfo(state: State, action: order.OrderGetInfoAction): State {
		return state;
	}

	static handleGetInfoSuccess(state: State, action: order.OrderGetInfoSuccessAction): State {
		return Object.assign({}, state, { orderInfo: action.payload });
	}

	static handleGetInfoFailure(state: State, action: order.OrderGetInfoFailureAction): State {
		return state;
	}
}
