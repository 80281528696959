import { combineReducers, ActionReducer } from '@ngrx/store'
import { storeFreeze } from 'ngrx-store-freeze';
import { compose } from '@ngrx/store';
import { ReducerState } from 'ngrx-cli';

import * as fromApp from './app';
import { environment } from '../../environments/environment';
import * as fromAuth from './auth';
import * as fromFeeds from './feeds';
import * as fromOrder from './order';

export interface AppState extends ReducerState {
  app: fromApp.State;
  auth: fromAuth.State;
  feeds: fromFeeds.State;
  order: fromOrder.State;
  mainState?: any;
}

const reducers = {
  app: fromApp.AppReducer.reducer,
  auth: fromAuth.AuthReducer.reducer,
  feeds: fromFeeds.FeedsReducer.reducer,
  order: fromOrder.OrderReducer.reducer,
};

const developmentReducer: ActionReducer<AppState> = compose(storeFreeze, combineReducers)(reducers);
const productionReducer: ActionReducer<AppState> = combineReducers(reducers);

export function reducer(state: any, action: any) {
  if (environment.production) {
    return productionReducer(state, action);
  } else {
    return developmentReducer(state, action);
  }
}
