import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { addBusinessDays } from '../utilities/utilities';
import { WebsocketService } from "./../services/websocket.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-wip-table',
  templateUrl: './wip-table.component.html',
  styleUrls: ['./wip-table.component.scss']
})
export class WipTableComponent implements OnInit {
  @Input() wipCases = [];
  @Output() caseClicked: EventEmitter<object> = new EventEmitter<object>();
  element;
  fullScreenMode:Boolean;
  constructor(private websocketService: WebsocketService,private datePipe :DatePipe){

  }
  ngOnInit() {
    this.element = document;
    this.fullScreenMode = false;
    this.websocketService.getData('dogupdated').subscribe((response) => {
      if (response ) {
        let caseKey = this.wipCases.findIndex((x) => x.id == response.id);
        this.wipCases[caseKey].dog = {
          name: response.name,
          weight: response.weight
        }
        this.wipCases[caseKey].side = response.side;
        this.wipCases[caseKey].joint_size = response.joint_size;
        this.wipCases[caseKey].plastic_thickness = response.plastic_thickness;
        this.wipCases[caseKey].plastic_size = response.plastic_size;
        this.wipCases[caseKey].plastic_cut = response.plastic_cut;
        this.wipCases[caseKey].plastic_pulled = response.plastic_pulled;
      }
    });
    this.websocketService.getData('ownerupdated').subscribe((response) => {
      if (response) {
        let caseKey = this.wipCases.findIndex((x) => x.owner.id == response.id);
        this.wipCases[caseKey].owner.last_name = response.last_name;
      }
    });
    this.websocketService.getData('orderupdated').subscribe((response) => {
      if (response) {
        let caseKey = this.wipCases.findIndex((x) => x.order_id == response.id);
        this.wipCases[caseKey].order.order_type = response.order_type;
      }
    });

  }
  

  addBusinessDays = (date, numberOfDays) => addBusinessDays(date, numberOfDays);

  caseRowClicked = (caseData) => this.caseClicked.emit(caseData);

  openFullscreen = ()=>{
    this.fullScreenMode = true; 
    if (this.element.documentElement.requestFullscreen) {
      this.element.documentElement.requestFullscreen();
    } else if (this.element.documentElement.mozRequestFullScreen) {
      /* Firefox */
      this.element.documentElement.mozRequestFullScreen();
    } else if (this.element.documentElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.element.documentElement.webkitRequestFullscreen();
    } else if (this.element.msRequestFullscreen) {
      /* IE/Edge */
      this.element.documentElement.msRequestFullscreen();
    }
  }

  exitFullscreen = ()=>{
    if (this.element.exitFullscreen) {
      this.element.exitFullscreen();
    } else if (this.element.webkitExitFullscreen) { /* Safari */
      this.element.webkitExitFullscreen();
    } else if (this.element.msExitFullscreen) { /* IE11 */
      this.element.msExitFullscreen();
    }
    this.fullScreenMode = false;
  }

  isDateInThisWeek(data) {
    if(data.order.order_type.includes('Hock') || data.order.order_type.includes('Carpal')){
      return '#FFFF99';
    }
    const caseDueDate :any = this.datePipe.transform(addBusinessDays(data.date_received,9),'yyyy-MM-dd','UTC');
    const todayObj = new Date();
    // get first date of week
    const firstDayOfWeek =  this.datePipe.transform(this.getFirstDayOfWeek(todayObj),'yyyy-MM-dd','UTC');
    // get last date of week
    const last  =  this.getFirstDayOfWeek(todayObj).getDate() + 6;
    const lastday = new Date(todayObj.setDate(last));
    const lastDayOfWeek = this.datePipe.transform(lastday,'yyyy-MM-dd','UTC');
    // if date is equal or within the first and last dates of the week
    const datematch = caseDueDate  >= firstDayOfWeek && caseDueDate <= lastDayOfWeek;
    if (datematch) {
      // return '#D3D3D3';
      return '#848884'
    } else {
      return 'rgb(71, 175, 77,40%)';
    }

  }
  getFirstDayOfWeek(d) {
    // 👇️ clone date object
    const date = new Date(d);
    const day = date.getDay(); // 👉️ get day of week
    // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }
  @HostListener("document:fullscreenchange", []) 
  fullScreen() {
    if (!this.element.fullscreenElement) {
      this.fullScreenMode = false;
    }
  }

   
}
