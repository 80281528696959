import { AfterViewInit, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {Dog, Feed} from '../../../../services/api/model/FeedResponse';
import {FormGroup} from '@angular/forms';
import {ActionTypes, OrderSaveDogInfoAction} from 'app/actions/order';
import {Actions} from '@ngrx/effects';
import {QuestionControlService, QuestionBase} from 'app/services/api/model/Question-Control-Service';
import {HasSubscriptions} from '../../../../directives/HasSubscriptions';
import {getReadableErrorMessage} from '../../../../utilities/utilities';
import {ApiService} from '../../../../services/api/api-service';
import {Order} from '../../../../services/api/model/Order';
import {FeedsFetchOrderAction} from '../../../../actions/feeds';
import { InteractionService } from '../../../../services/interaction.service';
import { MdDialog } from "@angular/material";

@Component({
  selector: 'app-feed-popup-dogs',
  templateUrl: './feed-popup-dogs.component.html',
  styleUrls: ['./feed-popup-dogs.component.scss'],
  providers: [QuestionControlService]
})
export class FeedPopupDogsComponent extends HasSubscriptions implements OnInit, AfterViewInit, OnDestroy {
  dog: Dog;
  feed: Feed;
  form: FormGroup;
  order: Order = <any>{};
  success: boolean;
  failure: boolean;
  errorMessage = null;
  editStateBool: boolean;
  failureSubscription: any;
  successSubscription: any;
  dogNameUpdated = false;
  dogWeightUpdated = false;
  values: QuestionBase<any>[] = [];
  @Output() dogInfoAddedOrUpdated: EventEmitter<object> = new EventEmitter<object>();

  constructor(
    private qcs: QuestionControlService,
    private store: Store<AppState>,
    private actions: Actions,
    private apiService: ApiService,
    private interactionService: InteractionService,
    private dialog:MdDialog,
  ) {
    super();
    this.failureSubscription = this.actions.ofType(ActionTypes.SaveDogInfoFailure)
      .pipe(this.getTakeUntil())
      .subscribe((failureAction) => this.handleSaveFeedFailure(failureAction));
    this.successSubscription = this.actions.ofType(ActionTypes.SaveDogInfoSuccess)
      .pipe(this.getTakeUntil())
      .subscribe((result) => this.handleSaveFeedSuccess());

    this.store.select(x => x.mainState.feeds.currentDog).pipe(this.getTakeUntil()).subscribe((dog: Dog) => {
      this.updateDog(dog);
    });
    this.store.select(x => x.mainState.feeds.currentFeed).pipe(this.getTakeUntil()).subscribe((feed: Feed) => {
      this.updateFeed(feed);
    });
    this.store.select(x => x.mainState.feeds).pipe(this.getTakeUntil()).subscribe((feeds: any) => {
      if (feeds.currentOrder.order) {
        this.order = feeds.currentOrder.order;
        this.initializeForm();
      }
      // todo prevent duplicated dog value as it is currently inside feeds.currentDog and feeds.currentOrder.dog as well.
      if (feeds.currentOrder.dog) {
        this.updateDog(feeds.currentOrder.dog);
        this.initializeForm();
      }
    });
    
  }

  ngOnInit() {
    this.interactionService.sharedEditedTab.pipe(this.getTakeUntil()).subscribe((data: any) => {
      if (data.section === 'dog') {
        if (data.currentAction === 'edit') {
          this.editState();
        }

        if (data.currentAction === 'edit_performed') {
          this.submitForm();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.form.get('ccl_tear').valueChanges.pipe(this.getTakeUntil()).subscribe(cclTearValue => {
      if (cclTearValue === 'No') {
        this.form.get('complete_tear').setValue('NA');
      }
    });
  }

  initializeForm() {
    this.values = [];
    if ((this.dog != undefined || this.dog.id != undefined) && (this.feed != undefined || this.feed.id != undefined)) {
      this.values.push(new QuestionBase({value: this.dog.name, label: 'Dog Name'}));
      this.values.push(new QuestionBase({value: this.dog.breed, label: 'Breed'}));
      this.values.push(new QuestionBase({value: this.dog.age, label: 'Age', type: 'number'}));
      this.values.push(new QuestionBase({value: this.dog.weight, label: 'Weight', type: 'number'}));
      this.values.push(new QuestionBase({value: this.feed.cranial_length, label: 'Cranial Length', type: 'number'}));
      this.values.push(new QuestionBase({value: this.feed.caudal_length, label: 'Caudal Length', type: 'number'}));
      this.values.push(new QuestionBase({value: this.feed.diagnosis, label: 'diagnosis'}));

      this.values.push(new QuestionBase({
        value: this.feed.side, label: 'Side', type: 'select', options: ['Left', 'Right', 'Bilateral']
      }));
      this.values.push(new QuestionBase({
        value: this.feed.ccl_tear,
        label: 'ccl tear', type: 'select', options: ['Yes', 'No'], key: 'ccl_tear'
      }));
      this.values.push(new QuestionBase({
        value: this.getComputedCompleteTearValue(), label: 'complete tear', type: 'select',
        options: ['Yes', 'No', 'NA'], key: 'complete_tear'
      }));
      this.values.push(new QuestionBase({
        value: this.order.order_notes, label: 'order notes', class: 'long-text', type: 'textarea'
      }));
      this.values.push(new QuestionBase({value: this.feed.medical_notes, label: 'medical notes', class: 'long-text', type: 'textarea'}));
      this.values.push(new QuestionBase({value: this.feed.bracing_goal, label: 'bracing goal', class: 'long-text', type: 'textarea'}));
      this.values.push(new QuestionBase({value: this.feed.description, label: 'description', class: 'long-text', type: 'textarea'}));

      this.form = this.qcs.toFormGroup(this.values);
    }
  }

  getComputedCompleteTearValue = (): string => {
    if (this.feed.complete_tear) {
      if (this.feed.complete_tear === 'NA') {
        return 'NA';
      }
      return 'Yes';
    }
    return 'No';
  }

  handleSaveFeedSuccess(): any {
    if (this.dogNameUpdated) {
      this.dogInfoAddedOrUpdated.emit();
      this.dogNameUpdated = false;
    }

    if (this.dogNameUpdated || this.dogWeightUpdated) {
      this.interactionService.setShouldUpdateWipListing(true);
      this.dogNameUpdated = false;
      this.dogWeightUpdated = false;
    }
    this.failure = false;
    this.success = true;
    if (this.feed) {
      this.store.dispatch(new FeedsFetchOrderAction(this.feed.id));
    }
  }

  handleSaveFeedFailure(failureAction): any {
    this.errorMessage = getReadableErrorMessage(failureAction);
    this.success = false;
    this.failure = true;
  }

  updateDog(dog: Dog) {
    this.dog = dog || <any>{};
  }

  updateFeed(feed: Feed) {
    this.feed = feed || <any>{};
    this.initializeForm();
  }

  editState() {
    this.editStateBool = !this.editStateBool;
  }

  checkboxChange(key: string, value: boolean) {
    this.form.get(key).setValue(!value);
    this.values[key] = !value;
  }

  submitForm() {
    this.errorMessage = '';
    this.editStateBool = false;
    this.failure = false;
    const formattedPayload = this.form.value;
    this.dogWeightUpdated = this.dog && (this.dog.weight !== formattedPayload.weight);
    if (this.form.valid) {
      for (const key of Object.keys(this.form.controls)) {
        if (this.feed[key] != undefined) {
          this.feed[key] = this.form.get(key).value;
        }
        if (this.dog[key] != undefined) {
          this.dog[key] = this.form.get(key).value;
        }
      }
      formattedPayload.name = formattedPayload.dog_name;
      this.dogNameUpdated = this.dog && (this.dog.name !== formattedPayload.name);
      formattedPayload.age = formattedPayload.age === '' ? null : formattedPayload.age;
      if (this.dog.id) {
        this.store.dispatch(new OrderSaveDogInfoAction(this.dog.id, this.feed.id, formattedPayload));
      } else {
        const dogPayload: any = {};
        dogPayload.name = formattedPayload.dog_name;
        dogPayload.breed = formattedPayload.breed;
        dogPayload.age = formattedPayload.age;
        dogPayload.weight = formattedPayload.weight;
        if (this.feed && this.feed.id) {
          dogPayload.case_id = this.feed.id;
          this.apiService.createDog(dogPayload).pipe(this.getTakeUntil())
            .subscribe(
              (dogResponse: any) => {
                if (dogResponse.status === 1) {
                  this.store.dispatch(new OrderSaveDogInfoAction(dogResponse.data.id, this.feed.id, formattedPayload));
                  this.dogInfoAddedOrUpdated.emit();
                  this.dogWeightUpdated = true;
                } else {
                  this.handleSaveFeedFailure(dogResponse);
                }
              });
        } else {
          this.errorMessage = 'Case is required to create a dog.';
        }
      }
      if (this.order) {
        this.apiService.updateOrder(this.order.id, {order_notes: this.form.value.order_notes}).pipe(this.getTakeUntil())
          .subscribe(() => {
          });
      }
      this.resetDogValue(formattedPayload);
      this.initializeForm();
    }
  }

  resetDogValue = (updatedDog): void => {
    this.dog.age = updatedDog.age;
    this.dog.breed = updatedDog.breed;
    this.dog.name = updatedDog.dog_name;
    this.dog.weight = updatedDog.weight;
  }

  ngOnDestroy() {
    //submit form if form input field value are changed
    if (this.form.dirty) {
     this.submitForm();
    } else {
      this.interactionService.setEditedTab(false);
    }
      
    super.ngOnDestroy();
  }



  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (this.form.dirty) {
      this.submitForm();
    return false;
    }
  }

}
