import {Component, Input, OnInit} from '@angular/core';
import * as $ from "jquery";


declare var DocumentTouch;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent {

  constructor()
  {
    this._window = window;
  }

  @Input() validator: any;

  element: any;
  slides: any;
  slidePos: any;
  width: any;
  length: any;
  container: any;
  _window: any;

  options: any = {
    continuous: false,
    speed: 700,
    group: true,
    bootstrap: true,
    draggable: true,
  };

  index: number;
  speed: number;

  currentCard = 1;
  selector = '.cards-container';
  showErrors = false;
  showBack = false;
  showNext = true;
  initSlider: any;

  ngAfterViewInit() {
    this.init();
    this.setup();
  }

  init() {
    this.container = $(this.selector)[0];
    this.element = this.container.children[0];
    this.index = parseInt(this.options.startSlide, 10) || 0;
    this.speed = this.options.speed;

    $(document).on('click touch', function (e) {
      var searchAlert = document.getElementById('search-alert');
      if (searchAlert && searchAlert.getAttribute('data-mlt') !== e.target.getAttribute('id')) {
        searchAlert.style.display = 'none';
      }
    });
  }



  setup() {

    this.slides = this.element.children;
    this.length = this.slides.length;

    if (this.slides.length < 2) {
      this.options.continuous = false;
    }

    if (this.options.continuous && this.slides.length < 3) {
      this.element.appendChild(this.slides[0].cloneNode(true));
      this.element.appendChild(this.element.children[1].cloneNode(true));
      this.slides = this.element.children;
    }

    this.slidePos = new Array(this.slides.length);
    this.width = this.container.getBoundingClientRect().width || this.container.offsetWidth;
    this.element.style.width = (this.slides.length * this.width * 2) + 'px';

    var pos = this.slides.length;
    while (pos--) {
      var _slide = this.slides[pos];
      _slide.style.width = this.width + 'px';
      _slide.classList.remove('hidden');
      _slide.style.left = (pos * -this.width) + 'px';
      this.move(pos, this.index > pos ? -this.width : (this.index < pos ? this.width : 0), 0);
    }

    if (this.options.continuous) {
      this.move(this.circle(this.index - 1), -this.width, 0);
      this.move(this.circle(this.index + 1), this.width, 0);
    }

    this.container.style.visibility = 'visible';
    this.container.style.overflow = 'hidden';

    this.addEventListener();

  }

  prev() {
  if (this.index) {
    this.slide(this.index - 1);
  }
}

  next() {
    if (this.validateCurrentStep()) {
      if (this.options.continuous) {
        this.slide(this.index + 1);
      } else if (this.index < this.slides.length - 1) {
        this.slide(this.index + 1);
      }
    }
  }

  circle(index) {
    return (this.slides.length + (index % this.slides.length)) % this.slides.length;
  }

  getPos = () => {
    var currentIndex = this.index;
    if (currentIndex >= length) {
      currentIndex = currentIndex - length;
    }
    return currentIndex;
  }

  slide(to, slideSpeed?: any) {

    if (this.index === to) {
      return;
    }

    $('.blockBtn').removeClass('active');

    var direction = Math.abs(this.index - to) / (this.index - to);
    if (this.options.continuous) {
      var natural_direction = direction;
      direction = -this.slidePos[this.circle(to)] / this.width;
      if (direction !== natural_direction) {
        to = -direction * this.slides.length + to;
      }
    }
    var diff = Math.abs(this.index - to) - 1;
    var e = 0;
    while (diff--) {
      this.move(this.circle((to > this.index ? to : this.index) - diff - 1), this.width * direction, 0);
      if (e > 1000) {
        console.error('crash cycle is completed');
        break;
      }
      e++;
    }
    to = this.circle(to);
    this.move(this.index, this.width * direction, slideSpeed || this.speed);
    this.move(to, 0, slideSpeed || this.speed);
    if (this.options.continuous) {
      this.move(this.circle(to - direction), -(this.width * direction), 0);
    }

    this.index = to;
  }

  move(index, dist, speed) {
    this.translate(index, dist || 0, speed);
    this.slidePos[index || 0] = dist || 0;
  }

  translate(index, dist, speed) {
    var slide = this.slides[index];
    var style = slide && slide.style;
    if (!style) {
      return;
    }
    style.webkitTransitionDuration = style.MozTransitionDuration = style.msTransitionDuration = style.OTransitionDuration = style.transitionDuration = speed + 'ms';
    style.webkitTransform = 'translate(' + dist + 'px,0)' + 'translateZ(0)';
    style.msTransform = style.MozTransform = style.OTransform = 'translateX(' + dist + 'px)';
  }

  isMouseEvent(e) {
    return /^mouse/.test(e.type);
  }



  addEventListener() {
    // @ts-ignore
    if (('ontouchstart' in window) || this._window.DocumentTouch && window.document instanceof DocumentTouch) {
      this.element.addEventListener('touchstart', this.handleEvent, false);
    }
    if (this.options.draggable) {
      this.element.addEventListener('mousedown', this.handleEvent, false);
    }

    this.element.addEventListener('webkitTransitionEnd', this.handleEvent, false);
    this.element.addEventListener('msTransitionEnd', this.handleEvent, false);
    this.element.addEventListener('oTransitionEnd', this.handleEvent, false);
    this.element.addEventListener('otransitionend', this.handleEvent, false);
    this.element.addEventListener('transitionend', this.handleEvent, false);

    window.addEventListener('resize', this.handleEvent, false);
  }


  start: any = {};
  delta: any = {};
  isScrolling: any;

  handleEvent = (event) => {
    switch (event.type) {
      case 'touchstart':
      case 'mousedown':
        this._start(event);
        break;
      case 'mousemove':
      case 'touchmove':
        this.handleMove(event);
        break;
      case 'mouseup':
      case 'mouseleave':
      case 'touchend':
        setTimeout(() => this.end(event));
        break;
      case 'webkitTransitionEnd':
      case 'msTransitionEnd':
      case 'oTransitionEnd':
      case 'otransitionend':
      case 'transitionend':
        setTimeout(() => {
          this.transitionEnd(event)
        }, 1);
        break;
      case 'resize':
        this.setup();
        break;
    }
  }

  _start = (event) => {
    var touches;
    if (this.isMouseEvent(event)) {
      touches = event;
//                        event.preventDefault();
    } else {
      touches = event.touches[0];
    }
    this.start = {
      x: touches.pageX,
      y: touches.pageY,
      time: +new Date()
    };

    this.isScrolling = undefined;
    this.delta = {};

    if (this.isMouseEvent(event)) {
      this.element.addEventListener('mousemove', this.handleEvent, false);
      this.element.addEventListener('mouseup', this.handleEvent, false);
      this.element.addEventListener('mouseleave', this.handleEvent, false);
    } else {
      this.element.addEventListener('touchmove', this.handleEvent, false);
      this.element.addEventListener('touchend', this.handleEvent, false);
    }
  }

  handleMove = (event) => {
    console.log("move");
    var touches;
    if (this.isMouseEvent(event)) {
      touches = event;
    } else {
      if (event.touches.length > 1 || event.scale && event.scale !== 1) {
        return;
      }
      touches = event.touches[0];
    }
    this.delta = {
      x: touches.pageX - this.start.x,
      y: touches.pageY - this.start.y
    };
    if (typeof this.isScrolling === 'undefined') {
      this.isScrolling = !!(this.isScrolling || Math.abs(this.delta.x) < Math.abs(this.delta.y));
    }
    if (!this.isScrolling) {

      if (Math.abs(this.delta.x) > 15) {
        $('.blockBtn').removeClass('active');
      }
      event.preventDefault();
      if (this.options.continuous) {
        this.translate(this.circle(this.index - 1), this.delta.x + this.slidePos[this.circle(this.index - 1)], 0);
        this.translate(this.index, this.delta.x + this.slidePos[this.index], 0);
        this.translate(this.circle(this.index + 1), this.delta.x + this.slidePos[this.circle(this.index + 1)], 0);
      } else {
        this.delta.x = this.delta.x / ((!this.index && this.delta.x > 0 || this.index === this.slides.length - 1 && this.delta.x < 0) ? (Math.abs(this.delta.x) / this.width + 1) : 1);
        this.translate(this.index - 1, this.delta.x + this.slidePos[this.index - 1], 0);
        this.translate(this.index, this.delta.x + this.slidePos[this.index], 0);
        this.translate(this.index + 1, this.delta.x + this.slidePos[this.index + 1], 0);
      }
    }

  }

  end = (event) => {

    var duration = +new Date() - this.start.time;
    var isValidSlide = Number(duration) < 250 && Math.abs(this.delta.x) > 20 || Math.abs(this.delta.x) > this.width / 2;
    var isPastBounds = !this.index && this.delta.x > 0 || this.index === this.slides.length - 1 && this.delta.x < 0;
    if (this.options.continuous) {
      isPastBounds = false;
    }

    var direction = this.delta.x < 0;
    if (!this.isScrolling) {
      if (direction && !isPastBounds) {
        isPastBounds = !this.validateCurrentStep();
      }

      if (isValidSlide && !isPastBounds) {
        if (direction) {
          if (this.options.continuous) {
            this.move(this.circle(this.index - 1), -this.width, 0);
            this.move(this.circle(this.index + 2), this.width, 0);
          } else {
            this.move(this.index - 1, -this.width, 0);
          }
          this.move(this.index, this.slidePos[this.index] - this.width, this.speed);
          this.move(this.circle(this.index + 1), this.slidePos[this.circle(this.index + 1)] - this.width, this.speed);
          this.index = this.circle(this.index + 1);
        } else {
          if (this.options.continuous) {
            this.move(this.circle(this.index + 1), this.width, 0);
            this.move(this.circle(this.index - 2), -this.width, 0);
          } else {
            this.move(this.index + 1, this.width, 0);
          }
          this.move(this.index, this.slidePos[this.index] + this.width, this.speed);
          this.move(this.circle(this.index - 1), this.slidePos[this.circle(this.index - 1)] + this.width, this.speed);
          this.index = this.circle(this.index - 1);
        }

      } else {
        if (this.options.continuous) {
          this.move(this.circle(this.index - 1), -this.width, this.speed);
          this.move(this.index, 0, this.speed);
          this.move(this.circle(this.index + 1), this.width, this.speed);
        } else {
          this.move(this.index - 1, -this.width, this.speed);
          this.move(this.index, 0,this. speed);
          this.move(this.index + 1, this.width, this.speed);
        }
      }
    }
    if (this.isMouseEvent(event)) {
      this.element.removeEventListener('mousemove', this.handleEvent, false);
      this.element.removeEventListener('mouseup', this.handleEvent, false);
      this.element.removeEventListener('mouseleave', this.handleEvent, false);
    } else {
      this.element.removeEventListener('touchmove', this.handleEvent, false);
      this.element.removeEventListener('touchend', this.handleEvent, false);
    }

  }

  transitionEnd = (event) => {
    this.validateCurrentStep(false);
    $('.blockBtn').eq(this.getPos()).addClass('active');
    this.showBack = !(this.getPos() == 0);
    this.showNext = !(this.getPos() == (length - 1));
  }


  validateCurrentStep(setDirty = true) {
    var valid = this.validator(this.getPos());
    this.showNext = valid;

    //var formContro = $(this.selector).find('.clearfix > div').eq().find('.form-control');



/*    for (var input of formContro)
    {
      var field = input.name;

      if (this.createOrder[field] && !$.isEmptyObject(this.createOrder[field].$error)) {
        if (setDirty) {
          this.createOrder[field].$setDirty();
        }
        valid = false;
        this.showNext = false;
      }
    }*/

    return valid;
  };

}

