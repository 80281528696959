import { Component, OnInit } from '@angular/core';
import {MdDialog} from "@angular/material";
import {ContactFormComponent} from "../../components/contact-form/contact-form.component";

@Component({
  selector: 'app-medical-forms',
  templateUrl: './medical-forms.component.html',
  styleUrls: ['./medical-forms.component.css']
})
export class MedicalFormsComponent implements OnInit {

  constructor(private dialog: MdDialog) { }

  ngOnInit() {
  }

  openContact(event: any)
  {
    event.stopPropagation();

    this.dialog.open(ContactFormComponent);
  }

}
