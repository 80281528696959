
import { type } from "ngrx-cli";
import { Action } from "@ngrx/store";

export const ActionTypes = {
	ChangeHeaderState: type('[App] ChangeHeaderState'),
	InitializeMediaService: type('[App] InitializeMediaService'),
	SendContact: type('[App] SendContact'),
	SendContactSuccess: type('[App] SendContactSuccess'),
	SendContactFailure: type('[App] SendContactFailure'),
	ContactButton: type('[App] ContactButton'),
	ContactPage: type('[App] ContactPage')
}

export class AppChangeHeaderStateAction implements Action {
	type = ActionTypes.ChangeHeaderState;
	constructor(public payload: any) { }
}

export class AppInitializeMediaServiceAction implements Action {
	type = ActionTypes.InitializeMediaService;
	constructor(public payload: any) { }
}

export class AppSendContactAction implements Action {
	type = ActionTypes.SendContact;
	constructor(public payload: any) { }
}

export class AppSendContactSuccessAction implements Action {
	type = ActionTypes.SendContactSuccess;
	constructor(public payload: any) { }
}

export class AppSendContactFailureAction implements Action {
	type = ActionTypes.SendContactFailure;
	constructor(public payload: any) { }
}

export class AppContactButtonAction implements Action {
	type = ActionTypes.ContactButton;
	constructor(public payload: any) { }
}

export class AppContactPageAction implements Action {
	type = ActionTypes.ContactPage;
	constructor(public payload: any) { }
}

export type Actions = AppChangeHeaderStateAction | AppInitializeMediaServiceAction | AppSendContactAction | AppSendContactSuccessAction | AppSendContactFailureAction | AppContactButtonAction | AppContactPageAction;
