import { Component, OnInit } from '@angular/core';
import { LoginFormComponent } from "../login-form/login-form.component";
import { MdDialogRef } from "@angular/material";
import { AppState } from "../../reducers/index";
import { Store } from "@ngrx/store";
import { ActionTypes, AppSendContactAction } from "../../actions/app";
import { Subject } from "rxjs";
import { Actions } from "@ngrx/effects";
import { Router } from "@angular/router";
import { QuestionBase, QuestionControlService } from 'app/services/api/model/Question-Control-Service';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
  providers: [QuestionControlService]
})
export class ContactFormComponent implements OnInit {

  destroyed$ = new Subject<boolean>();
  contact: string = "";
  failureSubscription: any;
  failed: boolean;

  values: QuestionBase<any>[] = [];
  form: FormGroup;


  constructor(private qcs: QuestionControlService, private dialogRef: MdDialogRef<LoginFormComponent>, private store: Store<AppState>, private actions: Actions, private router: Router) {
    this.store.select(x => x.mainState.app.contact).subscribe(x => this.contact = x);
    this.failureSubscription = this.actions.ofType(ActionTypes.SendContactFailure).subscribe((failure) => this.handleContactFailure());
    this.initializeForm();
  }

  handleContactFailure(): void {
    this.failed = true;
  }

  initializeForm() {
    this.values = [];
    this.values.push(new QuestionBase({ key: "name", label: "Your Name", validators: [Validators.required] }));
    this.values.push(new QuestionBase({ key: "email", label: "Email Address", type: "email", validators: [Validators.required] }));
    this.values.push(new QuestionBase({ key: "message", label: "Your Question For Us", type: "textarea", validators: [Validators.required] }));
    this.form = this.qcs.toFormGroup(this.values);
    this.form.controls['email'].setValidators([Validators.email]);
  }

  ngOnInit() {
  }

  closeModal() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.dialogRef.close();
  }

  submitForm() {
    if (this.form.valid) {
      this.store.dispatch(new AppSendContactAction(this.form.value));
      this.dialogRef.close();
      this.router.navigate(['/thank-you-button']);
    }
    this.failed = true;
  }
}
