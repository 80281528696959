import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css']
})
export class ResearchComponent implements OnInit {

  navs: string[] = ['Surgery and Management', 'Cruciate Braces', 'TPLO Comparison', 'Computer Modeling'];

  constructor() { }

  ngOnInit() {
  }

}
