import { Routes } from "@angular/router";
import { AuthGuard } from "./services/auth-guard";
import { MetaGuard } from "@ngx-meta/core";
import { CasesComponent } from "./containers/cases/cases.component";
import { FeedComponent } from "./containers/cases/feed/feed.component";
import { FeedsComponent } from "./containers/cases/feeds/feeds.component";
import { VetsComponent } from "./containers/cases/vets/vets.component";
import { VetComponent } from "./containers/cases/vet/vet.component";
import { OrderComponent } from "./containers/order/order.component";
import { ThankYouComponent } from "./containers/thank-you/thank-you.component";
import { NewOrderComponent } from "./containers/new-order/new-order.component";
import { ResetPasswordComponent } from "./containers/reset-password/reset-password.component";
import { CasesNewComponent } from "./containers/cases-new/cases-new.component";
import { FeedsNewComponent } from "./containers/cases-new/feeds-new/feeds-new.component";
import { LoginFormComponent } from "./components/login-form/login-form.component";
import { ClientPostOrderComponent } from './client-post-order/client-post-order.component';

export const routes: Routes = [
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'login',
    component: LoginFormComponent,
    canActivate: [MetaGuard],
  },
  {
    path: 'cases',
    component: CasesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: FeedsComponent, outlet: 'feed-view' },
      { path: 'vets', component: VetsComponent, outlet: 'feed-view' },
      { path: 'vets/:id', component: VetComponent, outlet: 'feed-view' },
      { path: ':id', component: FeedComponent, outlet: 'feed-view' }
    ]
  },
  {
    path: 'case-manager',
    component: CasesNewComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: FeedsNewComponent, outlet: 'feed-view' },
    ]
  },
  {
    path: 'case-feed/:id',
    component: FeedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order',
    component: NewOrderComponent
  },
  {
    path: 'post-order',
    component: ClientPostOrderComponent
  },
  // {
  //   path: 'post-order-second',
  //   component: ClientPostOrderComponent,
  // },
  {
    path: 'thank-you-order',
    component: ThankYouComponent
  },
  // {
  //   path: 'thank-you-button',
  //   component: ThankYouButtonComponent
  // },
  // {
  //   path: 'thank-you-contact',
  //   component: ThankYouContactComponent
  // },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];

