
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { AppModule } from '../../app.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ApiService {

  baseUrl: string;
  static _headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.baseUrl = AppModule.domain + '/api/';
  }

  login(model: any) {
    return this.post('login', model);
  }

  requestReset(model: any) {
    return this.get('accounts/reset-password?email=' + model.email);
  }

  resetPassword(model: any, token: string) {
    return this.post('accounts/reset-password/' + token, model);
  }

  fetchFeeds(page: number, pageSize: number) {
    return this.get('case-feed?page_nr=' + page + '&page_size=' + pageSize);
  }

  fetchActiveFeeds(page: number, pageSize: number) {
    return this.get('case-feed?page_nr=' + page + '&page_size=' + pageSize + '&status=Active');
  }

  fetchFeedsWithoutPagination() {
    return this.get('case-feed');
  }

  fetchFeed(id) {
    return this.get('new-case-feed/' + id);
  }

  saveFeed(id: number, feed: any) {
    return this.post('case-feed/' + id, feed, true);
  }

  fetchDog(id: number) {
    return this.get('dog/' + id);
  }

  updateDogOnly = (dogId: number, payload: any): Observable<object> => this.put('dog/' + dogId, payload);

  updateDog(dogid: number, feedid: number, feed: any) {
    return this.put('dog/' + dogid + '/case/' + feedid, feed);
  }

  fetchOrder(id: number) {
    return this.get('new-case-feed/' + id);
  }

  fetchVetWithQueryParams = (queryParams) => this.get(`vet?${queryParams}`);

  mergeVets = (mergeVetPayload) => this.put('merge/vet', mergeVetPayload);

  fetchVets(page: number = 1, pageSize: number = 50, filterParam = null) {
    if (filterParam) {
      return this.get(`vet?page_nr=${page}&page_size=${pageSize}&q=${filterParam}`);
    }
    return this.get(`vet?page_nr=${page}&page_size=${pageSize}`);
  }

  fetchVetSearch(queryParam){
    return this.get(`vet?q=${queryParam}`);

  }
  fetchVetSummary = (vetId): Observable<object> => this.get(`vet-summary/${vetId}`);

  fetchVet(id: number) {
    return this.get('vet/' + id);
  }

  /**
   * Search vet by name and zipcode
   * 
   * @param {String} name [vet name]
   * @param {String} zipcode [zipcode of vet address] 
   * 
   * @returns {Array} [list of vet]
   */
  searchVets(name) {
    return this.get(`public/vet/search?query=${name}`);
  }

  saveVet = (vet: any): Observable<object> => this.multiPartPut('vet/' + vet.get('id'), vet);

  fetchClinics(code: number, name: string) {
    return this.get('clinic?code=' + code + '&name=' + name + '&max_returned=5');
  }

  fetchOwner(id: number) {
    // todo This should have a dedicated API.
    // Right now we are getting user detail from case API.
    return this.get('new-case-feed/' + id);
  }

  fetchActivityCategories = (): Observable<object> => this.get('activity/category');

  addCategory = (categoryPayload): Observable<object> => this.post('activity/category', categoryPayload);

  updateOwner(id: number, feed: any) {
    return this.put('order/customer/' + id, feed);
  }

  fetchTasks = () => this.get('tasks');

  fetchPaginatedTasks = (pageNumber, pageSize) => this.get(`tasks?page_nr=${pageNumber}&page_size=${pageSize}`);

  fetchPaginatedFeedDynamically = (pageNumber, pageSize, status) =>
    this.get(`case-feed?page_nr=${pageNumber}&page_size=${pageSize}&status=${status}`);

  saveTask = (taskPayload) => this.post('tasks', taskPayload);

  updateTask = (taskId, taskPayload) => this.put(`tasks/${taskId}`, taskPayload);

  getTask = (taskId) => this.get(`tasks/${taskId}`);

  feedSearch(payLoad: any) {
    let searchQ: String = '';
    if (payLoad.object_type) {
      searchQ += '&object_type=' + payLoad.object_type.toLowerCase();
    }
    if (payLoad.start_date) {
      searchQ += '&start_date=' + payLoad.start_date;
    }
    if (payLoad.end_date) {
      searchQ += '&end_date=' + payLoad.end_date;
    }
    if (payLoad.order) {
      searchQ += '&order=' + payLoad.order;
    }
    if (payLoad.object_type == 'Vet') {
      return this.fetchVetSearch(payLoad.q);
    } else {
      return this.get('search?q=' + payLoad.q + searchQ);
    }
  }

  addActivity(taskId: number, description: string) {
    return this.post('tasks/' + taskId + '/activities', { description: description });
  }

  completeTask(taskId: number, conclusion: string) {
    return this.put('tasks/' + taskId, { status: conclusion });
  }

  contactSite(contactForm: any) {
    return this.post('contact-site', contactForm);
  }

  submitOrder(order: any) {
    return this.post('order', order);
  }

  getOrderInfo(orderId: string, checkoutId: string) {
    return this.get('order/check/' + orderId + '/' + checkoutId);
  }

  fetchActivities(id: number) {
    return this.get('activity/?order_id=' + id);
  }

  saveActivity(payload: any) {
    return this.post('activity', payload);
  }

  updatecasefeed(id: number, payload) {
    return this.put('case-feed/' + id, payload);
  }

  deletecase = (id: number) => {
    return this.patch('case-feed/' + id);
  }

  updateVet(id: number, payload) {
    return this.put('vet/' + id, payload);
  }

  addVetManagers = (payload) => this.post('vet-manager', payload);

  disAssociateVetManager = (vetId, caseManagerId) => this.http.patch(this.baseUrl + `vet-manager/unassign/${vetId}/${caseManagerId}`, {});

  checkToken() {
    return this.get('/user-profile/');
  }

  get(endPoint: string, headers: HttpHeaders = new HttpHeaders()): any {
    return this.http.get(this.baseUrl + endPoint, { headers: headers, responseType: 'json' }).pipe(catchError(this.handleError));
  }

  post(endPoint: string, object: any, isUpload: boolean = false, headers: HttpHeaders = ApiService._headers): any {
    if (!isUpload) {
      return this.http.post(this.baseUrl + endPoint, JSON.stringify(object), {
        headers: headers,
        responseType: 'json'
      }).pipe(catchError(this.handleError));
    } else {
      return this.http.post(this.baseUrl + endPoint, object, {
        headers: headers,
        responseType: 'json',
        reportProgress: true
      }).pipe(catchError(this.handleError));
    }
  }

  multiPartPut = (endPoint, payload): Observable<object> => {
    return this.http.put(this.baseUrl + endPoint, payload).pipe(catchError(this.handleError));
  }

  put(endPoint: string, object: any, headers: HttpHeaders = ApiService._headers): any {
    return this.http.put(this.baseUrl + endPoint, JSON.stringify(object), { headers: headers, responseType: 'json' }).pipe(catchError(this.handleError));
  }

  delete(endPoint: string, headers: HttpHeaders = new HttpHeaders()): any {
    return this.http.delete(this.baseUrl + endPoint, { headers: headers, responseType: 'json' }).pipe(catchError(this.handleError));
  }

  patch(endPoint: string, headers: HttpHeaders = new HttpHeaders()): any {
    return this.http.patch(this.baseUrl + endPoint, { headers: headers, responseType: 'json' }).pipe(catchError(this.handleError));
  }

  updateOrder = (orderId, payload) => this.put(`order/${orderId}`, payload);

  createDog = (dogPayload): Observable<object> => this.post('dog', dogPayload);

  createOwner = (payload): Observable<object> => this.post(`order/customer`, payload);

  createVet = (vet): Observable<object> => this.post('vet/', vet, true);

  private handleError(error: Response | any) {
    return Observable.create((observer) => {
      observer.next(error);
    });
  }

  getUsers = (role = null) => {
    if (role) {
      this.get('users');
    }
    return this.get(`users?type=${role}`);
  }

  updateActivity = (activityId, activityPayload) => this.put(`activity/${activityId}`, activityPayload);

  assignTags = (tagAssignPayload) => this.post('assign/tags', tagAssignPayload);

  unAssignTags = (tagUnAssignPayload) => this.put('unassign/tags', tagUnAssignPayload);

  getCompactCases = () => this.get('case-feed?compact=true');

  getNearByVets = (latitude, longitude,city = null) => this.get(`nearby/vet?latitude=${latitude}&longitude=${longitude}&city=${city}`);
  
  mergeCases = (mergeCasePayload) => this.put('cases/merge', mergeCasePayload);

  fetchMergedCases = (case_id) => this.get(`cases/${case_id}/merged`);


  saveFedexShopping = (casePayload) => this.post('vendor/fedex/shipping', casePayload);


  // Leads

  createLeads = (leadPayload) => this.post('leads', leadPayload);

  getLead = (leadId) => this.get(`leads/detail?leadsId=${leadId}`);

  updatedLead = (leadPayload) => this.put(`leads/${leadPayload.id}`, leadPayload);

  fetchPaginatedLeads = (pageNumber, pageSize) => this.get(`leads?page_nr=${pageNumber}&page_size=${pageSize}`);

  deleteLead =(leadID) =>this.delete(`leads/${leadID}`);

  getLeadByCase = (caseId) => this.get(`leads/detail?caseId=${caseId}`);


}
