import { Component, HostListener, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent {
  onChange: Function;

  @ViewChild('input') el: ElementRef;
  // todo have a better name.
  @Output() selectedFileList = new EventEmitter();
  @Input() allowMultiple = false;
  public files = new Map();
  public uploadHasError = false;


  @HostListener('change', ['$event.target.files'])
  emitFiles(fileList: FileList) {
    this.uploadHasError = false;
    Array.from(fileList).forEach(e => {
      const fileSize = Math.ceil((e.size / (1024 * 1024)));
      if (fileSize > 10) {
        this.uploadHasError = true;
        return;
      }
    });
    if (this.uploadHasError) {
      return;
    }

    this.files.clear();
    Array.from(fileList).forEach(e => {
      const reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = (_event: any) => {
        this.files.set(e.name, _event.target.result);
      };
      this.selectedFileList.emit(fileList);
    });
    this.onChange(event);
  }

  constructor() {
  }

  writeValue(value: null) {
    this.el.nativeElement.value = '';
    this.files.clear();
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
  }
}
