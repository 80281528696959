import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-research-study',
  templateUrl: './research-study.component.html',
  styleUrls: ['./research-study.component.scss']
})
export class ResearchStudyComponent implements OnInit {

  navs: string[] = ['Surgery and Management', 'Cruciate Braces', 'TPLO Comparison', 'Computer Modeling'];

  constructor() { }

  ngOnInit() {
  }

}
