import { Component, OnInit } from '@angular/core';
import { Viewport, MediaEvent } from 'app/directives/media-service';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  _viewPort: Viewport;

  _isMobile: boolean;

  constructor(private _store: Store<AppState>) {
    this._store.select(x => x.app.mediaService).subscribe((service) => {

      this._viewPort = service.currentViewport;

      service.mediaEvent.subscribe((event: MediaEvent) => {
        this._viewPort = event.new;

        this.initialize();
      })
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initialize();
  }

  initialize() {
    if (this._viewPort.width <= 799)
      this._isMobile = true;
    else
      this._isMobile = false;
  }
}
