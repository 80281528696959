
import { ReducerBase } from "ngrx-cli/reducer-base";
import * as auth from '../actions/auth';

export interface State {
  userToken: string;
  name: string;
  privilege: { is_admin: boolean, is_case_manager: boolean, is_order_manager: boolean, is_vet: boolean };
  dogs_helped: { count: string, time_frame: string };
  title: string;
  profile_image: string;
  id:number,
}

export class AuthReducer extends ReducerBase {
  static initialState: State = { userToken: null, name: null, privilege: null, title: "", dogs_helped: null, profile_image: "" ,id:null};

  static reducer(state = AuthReducer.initialState, action: auth.Actions): State {

    if (state == AuthReducer.initialState) {
      var token = localStorage.getItem("user_token");
      var name = localStorage.getItem("name");
      var dogsHelped = localStorage.getItem("dogs_helped");
      dogsHelped = dogsHelped && dogsHelped.length > 0 ? JSON.parse(dogsHelped) : { count: 0, time_frame: "NA" };
      var title = localStorage.getItem("title");
      var id = localStorage.getItem("id");
      state = Object.assign({}, state, { userToken: token, name: name, privilege: JSON.parse(localStorage.getItem("privilege")), dogs_helped: dogsHelped, title: title && title.length > 0 ? title : "", profile_image: localStorage.getItem("profile_image"),id:id });
    }

    switch (action.type) {
      case auth.ActionTypes.Login:
        return AuthReducer.handleLogin(state, <auth.AuthLoginAction>action);
      case auth.ActionTypes.LoginSuccess:
        return AuthReducer.handleLoginSuccess(state, <auth.AuthLoginSuccessAction>action);
      case auth.ActionTypes.LoginFailure:
        return AuthReducer.handleLoginFailure(state, <auth.AuthLoginFailureAction>action);
      case auth.ActionTypes.Logout:
        return AuthReducer.handleLogout(state, <auth.AuthLogoutAction>action);
      case auth.ActionTypes.ResetPassword:
        return AuthReducer.handleResetPassword(state, <auth.AuthResetPasswordAction>action);
      case auth.ActionTypes.ResetPasswordSuccess:
        return AuthReducer.handleResetPasswordSuccess(state, <auth.AuthResetPasswordSuccessAction>action);
      case auth.ActionTypes.ResetPasswordFailure:
        return AuthReducer.handleResetPasswordFailure(state, <auth.AuthResetPasswordFailureAction>action);
    }
    return state
  }

  static handleLogin(state: State, action: auth.AuthLoginAction): State {
    return state;
  }

  static handleLoginSuccess(state: State, action: auth.AuthLoginSuccessAction): State {
    localStorage.setItem("user_token", action.payload.token);
    localStorage.setItem("name", action.payload.full_name);
    localStorage.setItem("privilege", JSON.stringify(action.payload.privilege));
    localStorage.setItem("title", action.payload.title);
    localStorage.setItem("profile_image", action.payload.profile_image);
    localStorage.setItem("id", action.payload.id);
    if (action.payload.dogs_helped)
      localStorage.setItem("dogs_helped", JSON.stringify(action.payload.dogs_helped));

    return Object.assign({}, state, { userToken: action.payload.token, name: action.payload.full_name, profile_image: action.payload.profile_image, privilege: action.payload.privilege, title: action.payload.title == null ? "" : action.payload.title, dogs_helped: action.payload.dogs_helped,id:action.payload.id });
  }

  static handleLoginFailure(state: State, action: auth.AuthLoginFailureAction): State {
    return state;
  }

  static handleLogout(state: State, action: auth.AuthLogoutAction): State {
    localStorage.removeItem("user_token");
    localStorage.removeItem("name");
    localStorage.removeItem("privilege");
    localStorage.removeItem("title");
    localStorage.removeItem("profile_image");
    localStorage.removeItem("dogs_helped");
    return Object.assign({}, state, { userToken: null });
  }

  static handleResetPassword(state: State, action: auth.AuthResetPasswordAction): State {
    return state;
  }

  static handleResetPasswordSuccess(state: State, action: auth.AuthResetPasswordSuccessAction): State {
    return state;
  }

  static handleResetPasswordFailure(state: State, action: auth.AuthResetPasswordFailureAction): State {
    return state;
  }
}
