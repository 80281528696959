// @ts-ignore
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import {takeUntil} from 'rxjs/operators';

export class HasSubscriptions implements OnDestroy {
    constructor(public destroy$: Subject<boolean> = new Subject<boolean>()) {
    }

    getTakeUntil = () => takeUntil(this.destroy$);

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
