
import { ReducerBase } from "ngrx-cli/reducer-base";
import * as feeds from '../actions/feeds';
import { Dog, Feed, FeedResponse } from "../services/api/model/FeedResponse";
import { Vet } from "../services/api/model/VetResponse";
import { Task } from "../services/api/model/Task";
import { SearchResponse } from "../services/api/model/SearchResponse";
import { Owner } from "../services/api/model/Owner";
import { ActivityResponse } from "../services/api/model/Activity";
import { uniqBy } from 'lodash';


export interface State {
  fetching: boolean;
  feeds: Feed[];
  currentFeed: Feed;
  currentDog: Dog;
  currentOwner: Owner;
  vets: Vet[];
  currentVet: Vet;
  internalTasks: Task[];
  tasks: Task[];
  taskFilter: string;
  thenBy: string;
  searchFilter: string;
  searchCriteria: string;
  searchType: string;
  searchResults: SearchResponse[];
  cases?;
  filteredCases?;
  internalSearchResults: SearchResponse[];
  mode: string;
  currentOrder: any;
  activities: ActivityResponse[];
  activeFeedTotalCount?: number;
  feedPaginationData?: { next_page, prev_page };
  activeFeedPaginationData?: { next_page, prev_page };
  activeCaseFeeds?: Feed[];
}

export class FeedsReducer extends ReducerBase {
  static initialState: State = { activities: [], tasks: [], internalTasks: [], feeds: [], fetching: false, currentFeed: null, currentDog: null, vets: [], currentVet: null, taskFilter: null, thenBy: "active", searchFilter: "name", searchCriteria: "", searchType: "dog", searchResults: [], mode: "feeds", internalSearchResults: [], currentOrder: {}, currentOwner: <Owner>{} };

  static reducer(state = FeedsReducer.initialState, action: feeds.Actions): State {
    switch (action.type) {

      case feeds.ActionTypes.Fetch:
        return FeedsReducer.handleFetch(state, <feeds.FeedsFetchAction>action);
      case feeds.ActionTypes.FetchSuccess:
        return FeedsReducer.handleFetchSuccess(state, <feeds.FeedsFetchSuccessAction>action);
      case feeds.ActionTypes.FetchFailure:
        return FeedsReducer.handleFetchFailure(state, <feeds.FeedsFetchFailureAction>action);

      case feeds.ActionTypes.FetchActive:
        return FeedsReducer.handleFetchActive(state, <feeds.ActiveFeedsFetchAction>action);
      case feeds.ActionTypes.FetchActiveSuccess:
        return FeedsReducer.handleFetchActiveSuccess(state, <feeds.ActiveFeedsFetchSuccessAction>action);
      case feeds.ActionTypes.FetchActiveFailure:
        return FeedsReducer.handleFetchActiveFailure(state, <feeds.ActiveFeedsFetchFailureAction>action);

      case feeds.ActionTypes.FetchFeed:
        return FeedsReducer.handleFetchFeed(state, <feeds.FeedsFetchFeedAction>action);
      case feeds.ActionTypes.FetchFeedSuccess:
        return FeedsReducer.handleFetchFeedSuccess(state, <feeds.FeedsFetchFeedSuccessAction>action);
      case feeds.ActionTypes.FetchFeedFailure:
        return FeedsReducer.handleFetchFeedFailure(state, <feeds.FeedsFetchFeedFailureAction>action);
      case feeds.ActionTypes.FetchDog:
        return FeedsReducer.handleFetchDog(state, <feeds.FeedsFetchDogAction>action);
      case feeds.ActionTypes.FetchDogSuccess:
        return FeedsReducer.handleFetchDogSuccess(state, <feeds.FeedsFetchDogSuccessAction>action);
      case feeds.ActionTypes.FetchDogFailure:
        return FeedsReducer.handleFetchDogFailure(state, <feeds.FeedsFetchDogFailureAction>action);
      case feeds.ActionTypes.FetchVets:
        return FeedsReducer.handleFetchVets(state, <feeds.FeedsFetchVetsAction>action);
      case feeds.ActionTypes.FetchVetsSuccess:
        return FeedsReducer.handleFetchVetsSuccess(state, <feeds.FeedsFetchVetsSuccessAction>action);
      case feeds.ActionTypes.FetchVetsFailure:
        return FeedsReducer.handleFetchVetsFailure(state, <feeds.FeedsFetchVetsFailureAction>action);
      case feeds.ActionTypes.FetchVet:
        return FeedsReducer.handleFetchVet(state, <feeds.FeedsFetchVetAction>action);
      case feeds.ActionTypes.FetchVetSuccess:
        return FeedsReducer.handleFetchVetSuccess(state, <feeds.FeedsFetchVetSuccessAction>action);
      case feeds.ActionTypes.FetchVetFailure:
        return FeedsReducer.handleFetchVetFailure(state, <feeds.FeedsFetchVetFailureAction>action);
      case feeds.ActionTypes.ClearFeed:
        return FeedsReducer.handleClearFeed(state, <feeds.FeedsClearFeedAction>action);
      case feeds.ActionTypes.SaveFeed:
        return FeedsReducer.handleSaveFeed(state, <feeds.FeedsSaveFeedAction>action);
      case feeds.ActionTypes.SaveFeedSuccess:
        return FeedsReducer.handleSaveFeedSuccess(state, <feeds.FeedsSaveFeedSuccessAction>action);
      case feeds.ActionTypes.SaveFeedFailure:
        return FeedsReducer.handleSaveFeedFailure(state, <feeds.FeedsSaveFeedFailureAction>action);

      case feeds.ActionTypes.SaveDog:
        return FeedsReducer.handleSaveDog(state, <feeds.FeedsSaveDogAction>action);
      case feeds.ActionTypes.SaveFeedSuccess:
        return FeedsReducer.handleSaveDogSuccess(state, <feeds.FeedsSaveDogSuccessAction>action);
      case feeds.ActionTypes.SaveFeedFailure:
        return FeedsReducer.handleSaveDogFailure(state, <feeds.FeedsSaveDogFailureAction>action);

      case feeds.ActionTypes.SaveVet:
        return FeedsReducer.handleSaveVet(state, <feeds.FeedsSaveVetAction>action);
      case feeds.ActionTypes.SaveVetSuccess:
        return FeedsReducer.handleSaveVetSuccess(state, <feeds.FeedsSaveVetSuccessAction>action);
      case feeds.ActionTypes.SaveVetFailure:
        return FeedsReducer.handleSaveVetFailure(state, <feeds.FeedsSaveVetFailureAction>action);
      case feeds.ActionTypes.FetchTasks:
        return FeedsReducer.handleFetchTasks(state, <feeds.FeedsFetchTasksAction>action);
      case feeds.ActionTypes.FetchTasksSuccess:
        return FeedsReducer.handleFetchTasksSuccess(state, <feeds.FeedsFetchTasksSuccessAction>action);
      case feeds.ActionTypes.FetchCasesSuccess:
        return FeedsReducer.handleFetchCasesSuccess(state, <feeds.FeedsFetchTasksSuccessAction>action);
      case feeds.ActionTypes.FetchTasksFailure:
        return FeedsReducer.handleFetchTasksFailure(state, <feeds.FeedsFetchTasksFailureAction>action);
      case feeds.ActionTypes.AddTaskActivity:
        return FeedsReducer.handleAddTaskActivity(state, <feeds.FeedsAddTaskActivityAction>action);
      case feeds.ActionTypes.AddTaskActivitySuccess:
        return FeedsReducer.handleAddTaskActivitySuccess(state, <feeds.FeedsAddTaskActivitySuccessAction>action);
      case feeds.ActionTypes.AddTaskActivityFailure:
        return FeedsReducer.handleAddTaskActivityFailure(state, <feeds.FeedsAddTaskActivityFailureAction>action);
      case feeds.ActionTypes.CompleteTask:
        return FeedsReducer.handleCompleteTask(state, <feeds.FeedsCompleteTaskAction>action);
      case feeds.ActionTypes.CompleteTaskSuccess:
        return FeedsReducer.handleCompleteTaskSuccess(state, <feeds.FeedsCompleteTaskSuccessAction>action);
      case feeds.ActionTypes.CompleteTaskFailure:
        return FeedsReducer.handleCompleteTaskFailure(state, <feeds.FeedsCompleteTaskFailureAction>action);
      case feeds.ActionTypes.SetFilter:
        return FeedsReducer.handleSetFilter(state, <feeds.FeedsSetFilterAction>action);
      case feeds.ActionTypes.Search:
        return FeedsReducer.handleSearch(state, <feeds.FeedsSearchAction>action);
      case feeds.ActionTypes.SearchSuccess:
        return FeedsReducer.handleSearchSuccess(state, <feeds.FeedsSearchSuccessAction>action);
      case feeds.ActionTypes.ClearSearch:
        return FeedsReducer.handleClearSearch(state, <feeds.FeedsClearSearchAction>action);
      case feeds.ActionTypes.SetSearchFilter:
        return FeedsReducer.handleSetSearchFilter(state, <feeds.FeedsSetSearchFilterAction>action);
      case feeds.ActionTypes.FetchOrder:
        return FeedsReducer.handleFetchOrder(state, <feeds.FeedsFetchOrderAction>action);
      case feeds.ActionTypes.FetchOrderSuccess:
        return FeedsReducer.handleFetchOrderSuccess(state, <feeds.FeedsFetchOrderSuccessAction>action);
      case feeds.ActionTypes.FetchOrderFailure:
        return FeedsReducer.handleFetchOrderFailure(state, <feeds.FeedsFetchOrderFailureAction>action);

      case feeds.ActionTypes.FetchOwner:
        return FeedsReducer.handleFetchOwner(state, <feeds.FeedsFetchOwnerAction>action);
      case feeds.ActionTypes.FetchOwnerSuccess:
        return FeedsReducer.handleFetchOwnerSuccess(state, <feeds.FeedsFetchOwnerSuccessAction>action);
      case feeds.ActionTypes.FetchOwnerFailure:
        return FeedsReducer.handleFetchOwnerFailure(state, <feeds.FeedsFetchOwnerFailureAction>action);

      case feeds.ActionTypes.FetchActivityCategory:
        return FeedsReducer.handleFetchActivityCategory(state, <feeds.FeedsFetchActivityCategoryAction>action);
      case feeds.ActionTypes.FetchActivityCategorySuccess:
        return FeedsReducer.handleFetchActivityCategorySuccess(state, <feeds.FeedsFetchActivityCategorySuccessAction>action);
      case feeds.ActionTypes.FetchActivityCategoryFailure:
        return FeedsReducer.handleFetchActivityCategoryFailure(state, <feeds.FeedsFetchActivityCategoryFailureAction>action);

      case feeds.ActionTypes.FetchActivities:
        return FeedsReducer.handleFetchActivities(state, <feeds.FeedsFetchActivitiesAction>action);
      case feeds.ActionTypes.FetchActivitiesSuccess:
        return FeedsReducer.handleFetchActivitiesSuccess(state, <feeds.FeedsFetchActivitiesSuccessAction>action);
      case feeds.ActionTypes.SaveActivity:
        return FeedsReducer.handleSaveActivity(state, <feeds.FeedsSaveActivityAction>action);
      case feeds.ActionTypes.SaveActivitySuccess:
        return FeedsReducer.handleSaveActivitySuccess(state, <feeds.FeedsSaveActivitySuccessAction>action);
      case feeds.ActionTypes.SaveActivityFailure:
        return FeedsReducer.handleSaveActivityFailure(state, <feeds.FeedsSaveActivityFailureAction>action);
    }
    return state;
  }

  static handleFetch(state: State, action: feeds.FeedsFetchAction): State {
    if (action.payload.init)
      return Object.assign({}, state, { feeds: [], fetching: true });

    return Object.assign({}, state, { fetching: true });
  }

  static handleFetchSuccess(state: State, action: feeds.FeedsFetchSuccessAction): State {
    const response = action.payload as FeedResponse;
    const concatenatedData = [...state.feeds, ...response.data];
    return Object.assign({}, state,
      {
        feeds: uniqBy(concatenatedData, 'id'),
        fetching: false,
        feedPaginationData: {
          next_page: response.next_page,
          prev_page: response.prev_page,
        }
      }
    );
  }

  static handleFetchFailure(state: State, action: feeds.FeedsFetchFailureAction): State {
    return Object.assign({}, state, { fetching: false });
  }

  static handleFetchActive(state: State, action: feeds.FeedsFetchAction): State {
    if (action.payload.init) {
      return Object.assign({}, state, {feeds: [], fetching: true});
    }

    return Object.assign({}, state, {fetching: true});
  }

  static handleFetchActiveSuccess(state: State, action: feeds.FeedsFetchSuccessAction): State {
    const response = action.payload as FeedResponse;
    return Object.assign(
      {},
      state,
      {
        activeCaseFeeds: response.data,
        fetching: false,
        activeFeedPaginationData: {
          next_page: response.next_page,
          prev_page: response.prev_page,
        }
      });
  }

  static handleFetchActiveFailure(state: State, action: feeds.FeedsFetchFailureAction): State {
    return Object.assign({}, state, {fetching: false});
  }

  static handleFetchFeed(state: State, action: feeds.FeedsFetchFeedAction): State {
    return state;
  }

  static handleFetchFeedSuccess(state: State, action: feeds.FeedsFetchFeedSuccessAction): State {
    if (action.payload.data.case) {
      const formattedCaseObject = action.payload.data.case;
      formattedCaseObject.owner = action.payload.data.owner;
      return Object.assign({}, state, { currentFeed: formattedCaseObject });
    }

    return Object.assign({}, state, { currentFeed: null });
  }

  static handleFetchFeedFailure(state: State, action: feeds.FeedsFetchFeedFailureAction): State {
    return state;
  }

  static handleFetchDog(state: State, action: feeds.FeedsFetchDogAction): State {
    return state;
  }

  static handleFetchDogSuccess(state: State, action: feeds.FeedsFetchDogSuccessAction): State {
    if (action.payload.data && action.payload.data.length > 0)
      return Object.assign({}, state, { currentDog: action.payload.data[0] });

    return Object.assign({}, state, { currentDog: null });
  }

  static handleFetchDogFailure(state: State, action: feeds.FeedsFetchDogFailureAction): State {
    return state;
  }

  static handleFetchVets(state: State, action: feeds.FeedsFetchVetsAction): State {
    if (action.payload.init)
      return Object.assign({}, state, { vets: [], fetching: true });

    return Object.assign({}, state, { fetching: true });
  }

  static handleFetchVetsSuccess(state: State, action: feeds.FeedsFetchVetsSuccessAction): State {
    var response = action.payload as FeedResponse;

    return Object.assign({}, state, { vets: [...state.vets, ...response.data], fetching: false });
  }

  static handleFetchVetsFailure(state: State, action: feeds.FeedsFetchVetsFailureAction): State {
    return Object.assign({}, state, { fetching: false });
  }

  static handleFetchVet(state: State, action: feeds.FeedsFetchVetAction): State {
    return state;
  }

  static handleFetchVetSuccess(state: State, action: feeds.FeedsFetchVetSuccessAction): State {
    if (action.payload.data && action.payload.data.length > 0) {
      return Object.assign({}, state, {currentVet: action.payload.data[0]});
    }

    return Object.assign({}, state, {currentVet: null});
  }

  static handleFetchVetFailure(state: State, action: feeds.FeedsFetchVetFailureAction): State {
    return state;
  }

  static handleClearFeed(state: State, action: feeds.FeedsClearFeedAction): State {
    return Object.assign({}, state, { currentFeed: null, currentDog: null });
  }
  static handleSaveFeed(state: State, action: feeds.FeedsSaveFeedAction): State {
    return state;
  }
  static handleSaveFeedSuccess(state: State, action: feeds.FeedsSaveFeedSuccessAction): State {
    return state;
  }
  static handleSaveFeedFailure(state: State, action: feeds.FeedsSaveFeedFailureAction): State {
    return state;
  }
  static handleSaveDog(state: State, action: feeds.FeedsSaveDogAction): State {
    return state;
  }
  static handleSaveDogSuccess(state: State, action: feeds.FeedsSaveDogSuccessAction): State {
    return state;
  }
  static handleSaveDogFailure(state: State, action: feeds.FeedsSaveDogFailureAction): State {
    return state;
  }
  static handleSaveVet(state: State, action: feeds.FeedsSaveVetAction): State {
    return state;
  }
  static handleSaveVetSuccess(state: State, action: feeds.FeedsSaveVetSuccessAction): State {
    return state;
  }
  static handleSaveVetFailure(state: State, action: feeds.FeedsSaveVetFailureAction): State {
    return state;
  }

  static handleFetchTasks(state: State, action: feeds.FeedsFetchTasksAction): State {
    return state;
  }

  static handleFetchTasksSuccess(state: State, action: feeds.FeedsFetchTasksSuccessAction): State {
    return Object.assign({}, state, { tasks: action.payload.data.filter(x => x.status == 'Active'), internalTasks: action.payload.data });
  }

  static handleFetchCasesSuccess(state: State, action: feeds.FeedsFetchTasksSuccessAction): State {
    return Object.assign({}, state, { filteredCases: action.payload.data.filter(x => x.status == 'Active'), cases: action.payload.data });
  }

  static handleFetchTasksFailure(state: State, action: feeds.FeedsFetchTasksFailureAction): State {
    return state;
  }

  static handleAddTaskActivity(state: State, action: feeds.FeedsAddTaskActivityAction): State {
    return state;
  }

  static handleAddTaskActivitySuccess(state: State, action: feeds.FeedsAddTaskActivitySuccessAction): State {
    var task = state.tasks.find(x => x.id == action.payload.id);
    var nState = Object.assign({}, state, { internalTasks: [Object.assign({}, task, { activities: [...task.activities, action.results] }), ...state.internalTasks.filter(x => x.id != action.payload.id)] });

    return FeedsReducer.handleSetFilter(nState, <any>{ payload: state.taskFilter })
  }

  static handleAddTaskActivityFailure(state: State, action: feeds.FeedsAddTaskActivityFailureAction): State {
    return state;
  }

  static handleCompleteTask(state: State, action: feeds.FeedsCompleteTaskAction): State {
    return state;
  }

  static handleCompleteTaskSuccess(state: State, action: feeds.FeedsCompleteTaskSuccessAction): State {
    var nState = Object.assign({}, state, { internalTasks: state.internalTasks.filter(x => x.id != action.payload.id) });

    return FeedsReducer.handleSetFilter(nState, <any>{ payload: state.taskFilter, thenBy: state.thenBy });
  }

  static handleCompleteTaskFailure(state: State, action: feeds.FeedsCompleteTaskFailureAction): State {
    return state;
  }

  static handleSetFilter(state: State, action: feeds.FeedsSetFilterAction): State {
    if (action.payload) {
      return Object.assign(
        {},
        state,
        {
          tasks: state.internalTasks.filter(x => x.name.toLowerCase() == action.payload.toLowerCase()
            && (!action.thenBy || ((action.thenBy == 'all') ||
              (action.thenBy == 'lead' && x.status == 'Lead') || (action.thenBy == 'active' && x.status == 'Active')))
          ),
          taskFilter: action.payload,
          thenBy: action.thenBy
        }
      );
    }

    const formattedState = {
      tasks: state.internalTasks.filter(
        x => (!action.thenBy || ((action.thenBy == 'all') || (action.thenBy == 'lead' && x.status == 'Lead') ||
          (action.thenBy == 'active' && x.status == 'Active')))
      ),
      taskFilter: action.payload,
      thenBy: action.thenBy
    };

    const finalState = Object.assign(
      {},
      state,
      formattedState,
    );
    return finalState;
  }

  static handleSearch(state: State, action: feeds.FeedsSearchAction): State {
    return Object.assign({}, state, { searchCriteria: action.payload.q, searchType: action.payload.object_type, mode: 'search' });
  }

  static handleSearchSuccess(state: State, action: feeds.FeedsSearchSuccessAction): State {
    return Object.assign({}, state, { internalSearchResults: action.payload, searchResults: action.payload.filter(x => FeedsReducer.isMatch(x, state)) });
  }

  static isMatch(x: SearchResponse, state: State): boolean {
    switch (state.searchType) {
      case "order":
        return x.id.toString().indexOf(state.searchCriteria) !== -1;
      case "dog":
        return x.dog_name.indexOf(state.searchCriteria) !== -1;
      case "vet":
        return x.clinic_name.indexOf(state.searchCriteria) !== -1;
    }
    return true;
  }

  static isMatch2(x: SearchResponse, state: State): boolean {
    switch (state.searchType) {
      case "order":
        return x.id.toString().indexOf(state.searchCriteria) !== -1;
      case "dog":
        return x.dog_name.indexOf(state.searchCriteria) !== -1;
      case "vet":
        return x.clinic_name.indexOf(state.searchCriteria) !== -1;
    }
    return true;
  }

  static handleClearSearch(state: State, action: feeds.FeedsClearSearchAction): State {
    return Object.assign({}, state, { mode: 'feeds', searchCriteria: '', internalSearchResults: [], searchResults: [] });
  }

  static handleSetSearchFilter(state: State, action: feeds.FeedsSetSearchFilterAction): State {
    var results = state.internalSearchResults;
    switch (action.payload) {
      case "name":
        results = results.filter(x => x.name.toString().indexOf(state.searchCriteria) != -1);
        break;
      case "id":
        results = results.filter(x => x.id.toString().indexOf(state.searchCriteria) != -1);
        break;
      case "location":
        break;
    }
    return Object.assign({}, state, { searchFilter: action.payload, searchResults: results });
  }

  static isActive(task: Task) {
    return task.name.toLowerCase() != "lead";
  }

  static handleFetchOrder(state: State, action: feeds.FeedsFetchOrderAction): State {
    return state;
  }

  static handleFetchOrderSuccess(state: State, action: feeds.FeedsFetchOrderSuccessAction): State {
    return Object.assign({}, state, { currentOrder: action.payload.data });
  }

  static handleFetchOrderFailure(state: State, action: feeds.FeedsFetchOrderFailureAction): State {
    return state;
  }

  static handleFetchOwner(state: State, action: feeds.FeedsFetchOwnerAction): State {
    return state;
  }

  static handleFetchOwnerSuccess(state: State, action: feeds.FeedsFetchOwnerSuccessAction): State {
    if (action.payload.owner) {
      return Object.assign({}, state, { currentOwner: action.payload.owner });
    }

    return Object.assign({}, state, { currentOwner: null });
  }

  static handleFetchOwnerFailure(state: State, action: feeds.FeedsFetchOwnerFailureAction): State {
    return state;
  }


  static handleFetchActivityCategory(state: State, action: feeds.FeedsFetchActivityCategoryAction): State {
    return state;
  }

  static handleFetchActivityCategorySuccess(state: State, action: feeds.FeedsFetchActivityCategorySuccessAction): State {
    return Object.assign({}, state, {activityCategories: action.payload});
  }

  static handleFetchActivityCategoryFailure(state: State, action: feeds.FeedsFetchActivityCategoryFailureAction): State {
    return state;
  }



  static handleFetchActivities(state: State, action: feeds.FeedsFetchActivitiesAction): State {
    return state;
  }

  static handleFetchActivitiesSuccess(state: State, action: feeds.FeedsFetchActivitiesSuccessAction): State {
    return Object.assign({}, state, { activities: action.payload });
  }

  static handleSaveActivity(state: State, action: feeds.FeedsSaveActivityAction): State {
    return state;
  }

  static handleSaveActivitySuccess(state: State, action: feeds.FeedsSaveActivitySuccessAction): State {
    return state;
  }

  static handleSaveActivityFailure(state: State, action: feeds.FeedsSaveActivityFailureAction): State {
    return state;
  }
}
